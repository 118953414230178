<template>
	<v-container fluid align-start fill-height>
		<div style="width: 100%;">
			<v-row class="pb-4 mt-0 px-2" style="max-width: 1200px;">
				<v-col cols="3" class="py-0">
					<div class="mb-3 d-flex justify-space-between align-center">
						<p class="mb-0 caption font-weight-bold">Zona Proyecto</p>
						<v-btn
							depressed
							color="#959595"
							x-small
							outlined
							class="my-0"
							@click="startNewZoneIpt"
						>
							<v-icon small>mdi-plus</v-icon>
							<p class="mb-0 ml-1 caption">Nueva</p>
						</v-btn>
					</div>
					<div class="d-flex flex-column fill-height">

						<v-list dense class="py-0 transparent" :disabled="this.$store.state.projects.loadingZoneIpt">
							<v-list-item-group v-model="selectedZone">
								<v-list-item
									v-for="(zone, index) in zoneIpts"
									:key="index"
									dense
									:value="zone.id"
								>
									<v-list-item-title dense>
										<div class="d-flex align-center">
											<div class="d-flex justify-space-between align-center" style="width: 100%">
												<div class="d-flex justify-space-between align-center">
													<p class="caption mb-0 mr-2">{{ zone.name }}</p>
													<v-tooltip bottom v-if="zone.description">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-bind="attrs"
																v-on="on"
																x-small>mdi-information</v-icon>
														</template>
														<span>{{ zone.description }}</span>
													</v-tooltip>
												</div>
												<div class="d-flex align-center">
													<v-btn
														depressed
														color="warning"
														x-small
														icon
														@click.stop="startEditZoneIpt(zone)"
													>
														<v-icon small>mdi-note-edit-outline</v-icon>
													</v-btn>
													<v-btn
														depressed
														color="#FF120B"
														x-small
														icon
														@click.stop="deleteZoneIpt(zone.id)"
													>
														<v-icon small>mdi-delete-outline</v-icon>
													</v-btn>
												</div>
											</div>
										</div>
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
						<!-- <v-card
							class="square-card"
							:color="selectedZone === 1 ? '#959595':'white'"
							max-height="120px"
							:dark="selectedZone === 1"
							@click="selectZone(1)">
							<v-card-text class="fill-height d-flex align-center justify-center">
								<p class="font-weight-bold mb-0 text-center">Urbana</p>
							</v-card-text>
						</v-card> -->
					</div>
				</v-col>
				<v-col cols="3" class="py-0">
					<div class="mb-3 d-flex justify-space-between align-center">
						<p class="mb-0 caption font-weight-bold">Tipo Proyecto</p>
						<v-btn
							depressed
							color="#959595"
							x-small
							outlined
							class="my-0"
							v-if="$checkPermissions(['create_company_project_types'])"
							@click="$store.commit('companies/setNewProjectTypeModal', true)"
						>
							<v-icon small>mdi-plus</v-icon>
							<p class="mb-0 ml-1 caption">Nuevo</p>
						</v-btn>
					</div>
					<div class="d-flex flex-column fill-height">
						<v-select
							v-model="projectType"
							:items="$store.state.companies.companiesProjectTypes"
							:loading="$store.state.companies.loadingCompaniesProjectTypes"
							:menu-props="{ top: true, offsetY: true }"
							item-text="name"
							item-value="id"
							label="Tipo Proyecto"
							class="custom-height-search custom-height-calendar flex-grow-0"
							outlined
							dense
							required
							clearable
						></v-select>
					</div>
				</v-col>
				<v-col cols="3" class="py-0">
					<div class="mb-3 d-flex justify-space-between align-center">
						<p class="mb-0 caption font-weight-bold">Etapas</p>
						<v-btn
							depressed
							color="#959595"
							x-small
							outlined
							class="mt-0"
							:disabled="!selectedZone || !projectType"
							@click="startNewCategory"
						>
							<v-icon small>mdi-plus</v-icon>
							<p class="mb-0 ml-1 caption">Nuevo</p>
						</v-btn>
					</div>
					<div class="d-flex flex-column fill-height">

						<v-list dense class="py-0 transparent" :disabled="loadingCategoryOrder">
							<v-list-item-group v-model="selectedCategory" @change="handleSelectedCategory">
								<draggable v-model="categories" @end="startEndDragEvent">
									<v-list-item
										v-for="(category, index) in categories"
										:key="index"
										dense
										:value="category.id"
									>
										<v-list-item-title dense>
											<div class="d-flex align-center">
												<div class="d-flex justify-space-between align-center" style="width: 100%">
													<div class="d-flex justify-space-between align-center">
														<p class="caption mb-0 mr-2">{{ category.name }}</p>
														<v-tooltip bottom v-if="category.description">
															<template v-slot:activator="{ on, attrs }">
																<v-icon
																	v-bind="attrs"
																	v-on="on"
																	x-small>mdi-information</v-icon>
															</template>
															<span>{{ category.description }}</span>
														</v-tooltip>
													</div>
													<div class="d-flex align-center">
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	color="green"
																	x-small
																	icon
																	v-bind="attrs"
																	v-on="on"
																	@click.stop="copyCategoriesConfig(category.id)"
																>
																	<v-icon small>mdi-content-copy</v-icon>
																</v-btn>
															</template>
															<span>Copiar etapa y su configuración</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	color="warning"
																	x-small
																	icon
																	v-bind="attrs"
																	v-on="on"
																	@click.stop="startEditCategory(category)"
																>
																	<v-icon small>mdi-note-edit-outline</v-icon>
																</v-btn>
															</template>
															<span>Editar Etapa</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	color="#FF120B"
																	x-small
																	icon
																	v-bind="attrs"
																	v-on="on"
																	@click.stop="deleteCategory(category.id)"
																>
																	<v-icon small>mdi-delete-outline</v-icon>
																</v-btn>
															</template>
															<span>Eliminar etapa y su configuración</span>
														</v-tooltip>
													</div>
												</div>
											</div>
										</v-list-item-title>
									</v-list-item>
								</draggable>
							</v-list-item-group>
						</v-list>
					</div>
				</v-col>
			</v-row>
			<v-row class="py-2 my-1 px-2">
				<v-col cols="12">
					<v-divider class="my-2"></v-divider>
					<p class="mb-0 caption font-weight-bold">Configuración Etapas</p>
				</v-col>
				<v-col cols="12" class="py-0">
					<div class="d-flex justify-start align-center fill-height">
						<draggable
							class="d-flex flex-wrap align-center"
							v-model="selectedCategoryStages"
							handle=".handle_drag"
							@end="startEndDragEventStages"
							filter=".exclude"
							easing="cubic-bezier(1, 0, 0, 1)"
							animation="200"
							ghostClass="ghost-element-drag"
							:move="onMoveDragEvent">
							<div
								class="my-2"
								v-for="(stage, index) in selectedCategoryStages"
								:key="index">
								<v-card
									class="my-0 mx-2"
									max-height="160px"
									max-width="250px"
									v-if="editStage && editCategoryStage.id == stage.id">
									<v-card-text class="fill-height d-flex flex-column align-center justify-center">
										<div class="d-flex flex-column justify-space-between align-center">
											<v-text-field
												label="Nombre Etapa"
												v-model="editCategoryStage.name"
												outlined
												required
												dense
												hide-details
												:rules="requiredRule"
											class="custom-height-search custom-height-calendar w-100"
											></v-text-field>
											<v-text-field
												label="Detalle (Opcional)"
												v-model="editCategoryStage.description"
												outlined
												required
												dense
												class="custom-height-search custom-height-calendar mt-1"
											></v-text-field>
											<div class="d-flex align-center mt-1" style="width: 100%">
												<v-checkbox class="my-0 py-0" v-model="editCategoryStage.needs_payment" color="#959595" hide-details dense></v-checkbox>
												<p class="mb-0 caption">Requiere Pago</p>
											</div>
											<div class="mt-2 d-flex" style="width: 100%">
												<v-btn
													depressed
													color="#FF120B"
													small
													dark
													class="mr-1 d-flex flex-grow-1"
													:loading="loadingCreateCategory"
													@click="updateStage()"
												>
													<v-icon small>mdi-check</v-icon>
												</v-btn>
												<v-btn
													depressed
													color="#959595"
													small
													dark
													:loading="loadingCreateCategory"
													@click="cancelEditStage()"
												>
													<v-icon small>mdi-close</v-icon>
												</v-btn>
											</div>
										</div>
									</v-card-text>
								</v-card>
								<v-card
									v-else
									class="my-0 mx-2 pa-0 square-card handle_drag"
									height="110px"
									width="160px">
									<v-card-text class="pa-3 fill-height d-flex flex-column align-start">
										<div class="flex-grow-1" style="width:100%">
											<p class="font-weight-bold caption mb-1 text-center" style="line-height: 13px;">{{stage.name}}</p>
											<p class="mb-0 text-center" style="font-size: 10px;line-height: 10px;">{{stage.description}}</p>
										</div>
										<div class="d-flex justify-space-between align-center" style="width: 100%">
											<div class="d-flex align-center">
												<v-btn
													depressed
													color="warning"
													x-small
													icon
													@click.stop="startEditStage(stage)"
												>
													<v-icon small>mdi-note-edit-outline</v-icon>
												</v-btn>
												<v-btn
													depressed
													color="#FF120B"
													x-small
													icon
													@click.stop="deleteStage(stage.id)"
												>
													<v-icon small>mdi-delete-outline</v-icon>
												</v-btn>
											</div>
											<v-chip
												:color="stage.needs_payment ? '#FF120B':'#959595'"
												label
												outlined
												x-small
											>
												<b>{{stage.needs_payment ? 'Requiere Pago':'Sin Pago'}}</b>
											</v-chip>
										</div>
									</v-card-text>
								</v-card>
							</div>
							<v-card
								class="my-2 mx-2"
								max-height="160px"
								max-width="250px"
								v-if="newCategoryStageShow">
								<v-card-text class="fill-height d-flex flex-column align-center justify-center">
									<div v-if="newCategoryStageShow" class="d-flex flex-column justify-space-between align-center">
										<v-text-field
											label="Nombre Etapa"
											v-model="newCategoryStage.name"
											outlined
											required
											dense
											hide-details
											:rules="requiredRule"
											class="custom-height-search custom-height-calendar w-100"
										></v-text-field>
										<v-text-field
											label="Detalle (Opcional)"
											v-model="newCategoryStage.description"
											outlined
											required
											dense
											class="custom-height-search custom-height-calendar mt-3"
										></v-text-field>
										<div class="d-flex align-center mt-1" style="width: 100%">
											<v-checkbox class="my-0 py-0" v-model="newCategoryStage.needs_payment" color="#959595" hide-details dense></v-checkbox>
											<p class="mb-0 caption">Requiere Pago</p>
										</div>
										<div class="mt-2 d-flex" style="width: 100%">
											<v-btn
												depressed
												color="#FF120B"
												small
												dark
												class="mr-1 d-flex flex-grow-1"
												:loading="loadingCreateCategory"
												@click="confirmCategoryStage()"
											>
												<v-icon small>mdi-check</v-icon>
											</v-btn>
											<v-btn
												depressed
												color="#959595"
												small
												dark
												:loading="loadingCreateCategory"
												@click="closeNewCategoryStage()"
											>
												<v-icon small>mdi-close</v-icon>
											</v-btn>
										</div>
									</div>
								</v-card-text>
							</v-card>
							<v-card
								class="my-2 mx-2 square-card exclude"
								height="90px"
								width="90px"
								v-if="selectedCategory"
								:disabled="!selectedCategory"
								@click="startNewCategoryStage">
								<v-card-text class="fill-height d-flex flex-column align-center justify-center">
									<v-icon large>mdi-plus</v-icon>
								</v-card-text>
							</v-card>
							<v-card
								class="my-2 mx-2 square-card exclude"
								height="120px"
								width="250px"
								v-else
								:disabled="!selectedCategory"
								@click="startNewCategoryStage">
								<v-card-text class="fill-height d-flex flex-column align-center justify-center">
									<v-icon>mdi-close-circle-outline</v-icon>
									<p class="mt-1 mb-0 caption text-center">Por favor selecciona una etapa del menú superior</p>
								</v-card-text>
							</v-card>
						</draggable>
					</div>
				</v-col>
			</v-row>
		</div>
		<NewProjectCategory  v-if="$store.state.projects.categoryModal" :reload="getCategories"/>
		<NewZoneIpt  v-if="$store.state.projects.zoneIptModal" :reload="getZonesIpt"/>
		<CopyCompanyStage
			v-if="$store.state.projects.toCopyCompanyPlanningCategoryId"
			:reload="toNewCopiedStage"/>
	</v-container>
</template>

<script>
import draggable from 'vuedraggable'
import _debounce from 'lodash/debounce'
import NewProjectCategory from '@/components/Modals/Projects/NewProjectCategory'
import NewZoneIpt from '@/components/Modals/Projects/NewZoneIpt'
import CopyCompanyStage from '@/components/Modals/Projects/CopyCompanyStage'
import {createProjectPlanningCategory, createProjectPlanningStage, updateProjectPlanningCategory, updateProjectPlanningStage, updateProjectPlanningStageOrder, deleteProjectPlanningCategory, deleteProjectPlanningStage, deleteZoneIpt} from '@/helpers/api/projects'

export default {
	components: {draggable, NewProjectCategory, NewZoneIpt, CopyCompanyStage},
  data() {
    return {
			selectedZone: null,
			projectType: null,
			selectedCategory: null,
			newCategoryShow: false,
			loadingCategoryOrder: false,
			loadingStateOrder: false,
			loadingCreateCategory: false,
			newCategory: {
				name: null,
				description: null,
				order: 1
			},
			newCategoryStageShow: false,
			loadingCreateCategoryStage: false,
			newCategoryStage: {
				name: null,
				description: null,
				order: 1,
				needs_payment: false
			},
			editStage: false,
			editCategoryStage: null,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
    };
  },
  computed: {
		zoneIpts() {
			return this.$store.state.projects.zoneIpt
		},
		categories: {
			get() {
				return this.$store.state.projects.projectPlanningCategories
			},
			set(value) {
				this.$store.commit('projects/setProjectPlanningCategories', value);
			},
		},
		selectedCategoryStages: {
			get() {
				if(this.selectedCategory && this.categories && this.categories.length > 0){
					const found = this.categories.find(category => category.id === this.selectedCategory)
					return found ? found.stages:[]
				}
				return null
			},
			set(value) {
				const index = this.categories.findIndex(category => category.id === this.selectedCategory)
				if(index != -1){
					this.categories[index].stages = value
				}
			},
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		zoneIpts(newValue){
			if(newValue && newValue.length > 0){
				this.selectedZone = newValue[0].id
			}
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getCompanyProjectTypes')
			}
		},
		selectedZone(newValue, oldValue){
			if(newValue != oldValue || !newValue){
				this.selectedCategory = null
			}
			this.getCategories()
		},
		projectType(newValue, oldValue){
			if(newValue != oldValue || !newValue){
				this.selectedCategory = null
			}
			this.getCategories()
		}
	},
	mounted(){
		this.getZonesIpt()
	},
  methods: {
		toNewCopiedStage(info){
			this.selectedZone = info.zone_id
			this.projectType = info.project_type_id

			this.getCategories()
			this.selectedCategory = info.project_planning_category_id
		},
		copyCategoriesConfig(to_copy_category_id){
			this.$store.commit('projects/setToCopyCompanyPlanningCategoryId', to_copy_category_id)
		},
		getCategories(){
			if(this.selectedZone){
				this.$store.dispatch('projects/getAllProjectPlanningCategories', {
					zone_id: this.selectedZone,
					project_type_id: this.projectType
				})
			}
		},
		getZonesIpt(){
			this.$store.dispatch('projects/getAllZoneIpts')
		},
		startEditZoneIpt(zone){
			const data = {
				...zone,
				...{zone_ipt_id: zone.id}
			}
			this.$store.commit('projects/setZoneIptModalEditInfo', data)
			this.$store.commit('projects/setZoneIptModal', true)
		},
		startNewZoneIpt(){
			this.$store.commit('projects/setZoneIptModalEditInfo', null)
			this.$store.commit('projects/setZoneIptModal', true)
		},
		async deleteZoneIpt(zone_id){
			const stage = await deleteZoneIpt({zone_ipt_id: zone_id})
			if(stage.code == 200){
				this.getZonesIpt()
			}
		},
		handleSelectedCategory(category_id){
			this.selectedCategory = category_id
		},
		startEditStage(stage){
			this.editCategoryStage = stage
			this.editStage = true
		},
		cancelEditStage(){
			this.editStage = false
			this.editCategoryStage = null
		},
		startEditCategory(category){
			const general_stage = !category.project_type_id
			const data = {
				...category,
				...{project_planning_category_id: category.id},
				...{zone_id: this.selectedZone, project_type_id: this.projectType, general_stage}
			}
			this.$store.commit('projects/setCategoryModalEditInfo', data)
			this.$store.commit('projects/setCategoryModal', true)
		},
		cancelEditCategory(){
			this.$store.commit('projects/setCategoryModal', false)
			this.$store.commit('projects/setCategoryModalEditInfo', null)
		},
		async updateStage(){
			this.loadingCreateCategory = true
			const data = {
				...this.editCategoryStage,
				...{project_planning_stage_id: this.editCategoryStage.id}
			}
			const stage = await updateProjectPlanningStage(data)
			if(stage.code == 200){
				this.getCategories()
				this.cancelEditStage()
			}
			this.loadingCreateCategory = false
		},
		startNewCategory(){
			const data = {
				zone_id: this.selectedZone,
				order: this.categories.length + 1,
				project_type_id: this.projectType
			}
			this.$store.commit('projects/setCategoryModalEditInfo', data)
			this.$store.commit('projects/setCategoryModal', true)
		},
		closeNewCategory(){
			this.newCategory = {
				name: null,
				description: null,
				order: 1
			}
			this.newCategoryShow = false
		},
		startNewCategoryStage(){
			this.newCategoryStage = {
				name: null,
				description: null,
				order: this.selectedCategoryStages.length + 1,
				needs_payment: false
			}
			this.newCategoryStageShow = true
		},
		closeNewCategoryStage(){
			this.newCategoryStage = {
				name: null,
				description: null,
				order: 1,
				needs_payment: false
			}
			this.newCategoryStageShow = false
		},
		async confirmCategoryStage(){
			this.loadingCreateCategory = true
			const data = {...this.newCategoryStage, ...{company_planning_cat_id: this.selectedCategory}}
			const category = await createProjectPlanningStage(data)
			if(category.code == 200){
				this.closeNewCategoryStage()
				this.getCategories()
			}
			this.loadingCreateCategory = false
		},
		async confirmCategory(){
			this.loadingCreateCategory = true
			const category = await createProjectPlanningCategory(this.newCategory)
			if(category.code == 200){
				this.closeNewCategory()
				this.getCategories()
			}
			this.loadingCreateCategory = false
		},
		async deleteStage(stage_id){
			const stage = await deleteProjectPlanningStage({project_planning_stage_id: stage_id})
			if(stage.code == 200){
				this.getCategories()
			}
		},
		async deleteCategory(category_id){
			const category = await deleteProjectPlanningCategory({project_planning_category_id: category_id})
			if(category.code == 200){
				this.getCategories()
			}
		},
		selectZone(zone){
			this.selectedZone = zone
		},
		onMoveDragEvent(evt){
			return evt.related.className.indexOf('exclude') === -1
		},
		startEndDragEvent: _debounce(function(ev){ this.endDragEvent(ev) }, 500),
		async endDragEvent(ev){
			if (typeof ev.newIndex === 'number') {
				this.loadingCategoryOrder = true
				const promises = this.categories.map((category, index) => {
					const data = {
						project_planning_category_id: category.id,
						order: index + 1,
					};
					return updateProjectPlanningCategory(data);
				});

				Promise.all(promises)
					.then(() => {
						this.getCategories()
						this.loadingCategoryOrder = false
					})
					.catch(() => {
						this.loadingCategoryOrder = false
					})
			}
		},
		startEndDragEventStages: _debounce(function(ev){ this.endDragEventStages(ev) }, 500),
		async endDragEventStages(ev){
			if (typeof ev.newIndex === 'number') {
				this.loadingStateOrder = true
				const stages = this.selectedCategoryStages.map((stage, index) => {
					return {
						id: stage.id,
						order: index + 1
					}
				})
				const stages_r = await updateProjectPlanningStageOrder({stages});
				if(stages_r.code == 200){
					this.getCategories()
				}
				this.loadingStateOrder = false
			}
		},
  },
};
</script>

<style scoped>
.square-card{
	aspect-ratio: 1;
	cursor: pointer;
}
.custom-height-calendar .v-input__control,
.custom-height-calendar .v-field__input,
.custom-height-calendar .v-input__slot,
.custom-height-calendar .v-select__slot {
  height: 30px!important;
	min-height: 30px!important;
}
.custom-height-calendar .v-input__append-inner{
	margin-top: 3px!important;
}
.custom-calendar-sidemini .v-date-picker-table{
	height: auto;
}
.custom-height-search label{
	font-size: 12px;
	top: 5px!important;
}
/* .custom-height-search .v-progress-linear .v-progress-linear__background {
  background-color: #FF120B;
  color: #FF120B;
} */
.custom-height-search .v-progress-linear__indeterminate {
  background-color: #FF120B!important; /* Cambia este color */
}
.ghost-element-drag{
	opacity: 0;
}
</style>