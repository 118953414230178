<template>
	<v-navigation-drawer
		v-model="$store.state.menu.menuSidebarStatus"
		fixed
		:mini-variant="$store.state.menu.sidebarMini"
		:width="$store.state.menu.sidebarMini ? '50':'220'"
	>
		<v-icon v-show="$vuetify.breakpoint.mobile" dark class="toggle-button pa-1" @click="toggleMenu">mdi-menu-open</v-icon>
		<div style="width: 100%;" class="fill-height">
			<v-row no-gutters justify="center">
				<v-col justify="center" class="text-center pa-4">
					<div class="d-flex justify-space-between align-center mb-3">
						<div class="d-flex justify-space-between align-center" v-if="$store.state.companies.selectedCompany">
							<img :src="$store.state.companies.selectedCompany.company.logo" style="max-width: 30px; width: 100%;"
								v-if="$store.state.companies.selectedCompany"/>
								<div class="ml-2 text-left">
									<p class="mb-0" style="font-size: 10px; opacity: 0.6">Empresa</p>
									<p class="font-weight-bold caption mb-0 body-2" style="color: #606060;">{{$store.state.companies.selectedCompany.company.name}}</p>
									<v-menu offset-y v-if="!$checkPermissions(['client_capabilities'])">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												dark
												x-small
												color="#524D4D"
												v-bind="attrs"
												v-on="on"
											>
												<p class="mb-0 mr-1" style="font-size: 10px">Cambiar</p>
												<v-icon dark x-small>mdi-swap-horizontal-bold</v-icon>
											</v-btn>
										</template>
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Cambiar Empresa</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(company, index) in $store.state.companies.companiesList"
													:key="index"
													dense
													@click="changeCompany(company)"
												>
													<v-list-item-title dense>
														{{ company.company.name }}
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-menu>
								</div>
						</div>
						<!-- <img :src="require('../../assets/logoarquimarket.png')" style="max-width: 30px; width: 100%; opacity: 0.5;" /> -->
					</div>
					
					<v-divider class="my-2"></v-divider>
					<div class="d-flex align-center" v-if="$store.state.user.userInfo">
						<div class="sidebar-avatars-container mb-1 mr-2">
							<img :src="$store.state.user.userInfo.profile.avatar" class="user-avatar" :class="$store.state.menu.sidebarMini ? 'mini':''" v-if="$store.state.user.userInfo.profile.avatar"/>
							<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
						</div>
						<div v-if="!$store.state.menu.sidebarMini">
							<p class="font-weight-bold text-left mb-0" style="color: #606060; font-size: 14px;">{{$store.state.user.userInfo.profile.name}}</p>
						</div>
					</div>
					<div v-else>
						<v-skeleton-loader type="avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
					</div>
				</v-col>
			</v-row>
			<Menu />
			<div class="mt-5 px-2">
				<v-btn
					depressed
					color="#FF1209"
					dark
					block
					:small="$store.state.menu.sidebarMini"
					@click="logout()"
				>
					<v-icon>mdi-logout</v-icon>
					<p class="mb-0 ml-2 subtitle-2"
					v-show="!$store.state.menu.sidebarMini">Salir</p>
				</v-btn>
				<p class="text-center caption mt-4 mb-0">versión 0.3.5</p>
				<p class="text-center text--disabled caption">21-01-2025</p>
				<div class="px-2">
					<!-- <p class="mb-1" style="font-size: 10px;">Seleccione Idioma</p> -->
					<GoogleTranslate />
				</div>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
import GoogleTranslate from '@/components/Helpers/GoogleTranslate'
import Menu from './Menu/Index'
export default {
	name: 'Sidebar',
	components: { Menu, GoogleTranslate },
	data: () => ({
	}),
	methods: {
		async changeCompany(company){
			await localStorage.setItem('company_id', company.company_id)
			location.reload()
		},
		toggleMenu(){
			if(this.$vuetify.breakpoint.mobile){
				this.$store.commit('menu/setMenuSidebarStatus', !this.$store.state.menu.menuSidebarStatus)
				return null
			}
			this.$store.commit('menu/toggleSidebarMini')
		},
		logout(){
			this.$store.commit('user/logoutUser')
			this.$router.push({name: 'Login'})
		}
	}
};
</script>
<style>
	.toggle-button{
		position: absolute!important;
		top: 0;
		right: 0;
		z-index: 999;
		background: #524D4D;
	}
	.v-skeleton-loader__bone{
		margin-right: auto;
		margin-left: auto;
	}
	.sidebar-avatars-container{
		width: 50px;
	}
	.user-avatar{
		width: 40px;
		height: 40px;
		border-radius: 40px;
	}
	.user-avatar.mini{
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}
	.user-avatar.icon{
		background-color: #F0F2F8;
	}
	.info-icon{
		background-color: #524D4D;
		border-radius: 25px;
		padding: 3px;
		width: 22px!important;
		height: 22px!important;
	}
	.project-avatar-sidebar{
		width: 40px;
		height: 40px;
		border-radius: 40px;
		position: absolute;
		bottom: 10px;
		right: 0;
	}
</style>
