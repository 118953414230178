<template>
	<div class="text-center">
		<v-dialog
			v-model="$store.state.users.editPermisosUserModal"
			max-width="800"
			scrollable
			persistent
		>
			<v-card>
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-start">
								<v-icon color="white" small class="info-icon mt-1 mr-2">mdi-account-plus-outline</v-icon>
								<div>
									<p class="mb-0 font-weight-bold text-primary title" v-if="$store.state.users.editPermissionsUser">Editar Permisos</p>
									<p class="mb-0"><b>{{$store.state.users.editPermissionsUser.profile.name}} {{$store.state.users.editPermissionsUser.profile.lastname}}</b> <span v-if="$store.state.companies.selectedCompany">- Empresa: {{$store.state.companies.selectedCompany.company.name}}</span></p>
								</div>
							</div>
							<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
								<v-icon dark>mdi-close</v-icon>
							</v-btn>
						</div>
						<v-form ref="form" v-model="validform" class="mt-6">
							<v-row>
								<v-col cols="12" sm="6" class="py-0">
									<div class="permisos-type-container">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">Usuarios</h2>
										<v-checkbox v-model="permisos.view_users" color="black" label="Ver Usuarios" hide-details dense @change="changeViewUsers"></v-checkbox>
										<v-checkbox v-model="permisos.create_users" :disabled="!permisos.view_users" color="black" label="Crear Usuarios" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_users" :disabled="!permisos.view_users" color="black" label="Editar Usuarios" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_users" :disabled="!permisos.view_users" color="black" label="Eliminar Usuarios" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_user_permissions" :disabled="!permisos.view_users" color="black" label="Editar Permisos Usuarios" hide-details dense></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">Empresas</h2>
										<v-checkbox v-model="permisos.edit_company" color="black" label="Editar Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_company" color="black" label="Eliminar Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_company_roles" color="black" label="Ver Roles Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.create_company_roles" color="black" label="Crear Roles Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_company_roles" color="black" label="Editar Roles Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_company_roles" color="black" label="Eliminar Roles Empresa" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_company_project_types" color="black" label="Ver Tipos de Proyectos" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.create_company_project_types" color="black" label="Crear Tipos de Proyectos" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_company_project_types" color="black" label="Editar Tipos de Proyectos" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_company_project_types" color="black" label="Eliminar Tipos de Proyectos" hide-details dense></v-checkbox>
									</div>
								</v-col>
								<v-col cols="12" sm="6" class="py-0">
									<div class="permisos-type-container">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">CRM/Solicitudes</h2>
										<v-checkbox v-model="permisos.view_crm" color="black" label="Ver CRM/Solicitudes" hide-details dense @change="changeViewCrm"></v-checkbox>
										<v-checkbox v-model="permisos.delete_crm" :disabled="!permisos.view_crm" color="black" label="Eliminar Registro CRM/Solicitudes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_crm" :disabled="!permisos.view_crm" color="black" label="Editar Datos en Detalle CRM/Solicitudes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_company_concepts" color="black" label="Visualizar Conceptos CRM/Solicitudes" hide-details dense @change="changeViewConcepts"></v-checkbox>
										<v-checkbox v-model="permisos.create_company_concepts" :disabled="!permisos.view_company_concepts" color="black" label="Crear Conceptos CRM/Solicitudes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_company_concepts" :disabled="!permisos.view_company_concepts" color="black" label="Editar Conceptos CRM/Solicitudes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_company_concepts" :disabled="!permisos.view_company_concepts" color="black" label="Eliminar Conceptos CRM/Solicitudes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_vt_maps" color="black" label="Ver Mapas de Visitas Técnicas" hide-details dense @change="changeViewVtMaps"></v-checkbox>
										<v-checkbox v-model="permisos.edit_vt_maps" :disabled="!permisos.view_vt_maps" color="black" label="Editar Polígonos de Visitas Técnicas" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.delete_vt_maps" :disabled="!permisos.view_vt_maps" color="black" label="Eliminar Polígonos de Visitas Técnicas" hide-details dense></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">Agenda</h2>
										<v-checkbox v-model="permisos.schedule_access" color="black" label="Acceso a Agenda" hide-details dense @change="changeViewSchedule"></v-checkbox>
										<v-checkbox v-model="permisos.schedule_assignment" :disabled="!permisos.schedule_access" color="black" label="Asignación a otros usuarios" hide-details dense></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">Clientes</h2>
										<v-checkbox v-model="permisos.view_clients" color="black" label="Acceso a Clientes" hide-details dense @change="changeViewClients"></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-text">Gestión Antecedentes</h2>
										<v-checkbox v-model="permisos.view_preprojects" color="black" label="Acceso a Gestión Antecedentes" hide-details dense @change="changeViewPreprojects"></v-checkbox>
										<v-checkbox v-model="permisos.edit_preprojects" :disabled="!permisos.view_preprojects" color="black" label="Editar Gestión Antecedentes" hide-details dense></v-checkbox>
									</div>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<div class="d-flex flex-row justify-end">
										<v-btn
											class="mx-2 my-1"
											rounded
											color="white"
											raised
											@click="closeModal"
										>
											<p class="mb-0 text-primary">Cancelar</p>
										</v-btn>
										<v-btn
											class="mx-2 my-1"
											rounded
											color="#2D4B95"
											raised
											dark
											:loading="loadingData"
											@click="updatePermissions"
										>
											<p class="mb-0">Confirmar</p>
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {updateUserCompanyPermissions} from '@/helpers/api/company'
export default {
	name: 'EditPermisosUserModal',
	data: () => ({
		permisos: {
		},
		loadingData: false,
		validform: false
	}),
	mounted(){
		if(this.$store.state.users.editPermissionsUser && this.$store.state.users.editPermissionsUser.company_rol){
			this.permisos = this.$store.state.users.editPermissionsUser.company_rol.capabilities ?? {}
		}
	},
	methods: {
		changeViewUsers(){
			if(!this.permisos.view_users){
				this.permisos.create_users = false
				this.permisos.edit_users = false
				this.permisos.delete_users = false
				this.permisos.edit_user_permissions = false
			}
		},
		changeViewCrm(){
			if(!this.permisos.view_crm){
				this.permisos.delete_crm = false
				this.permisos.edit_crm = false
			}
		},
		changeViewConcepts(){
			if(!this.permisos.view_company_concepts){
				this.permisos.create_company_concepts = false
				this.permisos.edit_company_concepts = false
				this.permisos.delete_company_concepts = false
			}
		},
		changeViewVtMaps(){
			if(!this.permisos.view_vt_maps){
				this.permisos.edit_vt_maps = false
				this.permisos.delete_vt_maps = false
			}
		},
		changeViewSchedule(){
			if(!this.permisos.schedule_access){
				this.permisos.schedule_assignment = false
			}
		},
		changeViewClients(){
			console.log('changeViewClients')
		},
		changeViewPreprojects(){
			if(!this.permisos.view_preprojects){
				this.permisos.edit_preprojects = false
			}
		},
		async updatePermissions(){
			this.loadingData = true
			const data = {
				'user_id': this.$store.state.users.editPermissionsUser.id,
				'capabilities': this.permisos
			}
			const permission = await updateUserCompanyPermissions(data)
			this.loadingData = false
			if(permission.code == 200){
				this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 30})
				const data = {
					message: 'Permisos actualizados correctamente',
					title: 'Ok',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.closeModal()
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
		},
		closeModal(){
			this.$store.commit('users/setEditPermissionsUser', null)
			this.$store.commit('users/setEditPermisosUserModal', false)
			this.permisos = {}
			this.validform = true
			this.loadingData = false
		}
	}
};
</script>

<style scoped>
	.info-container{
		color: #606060;
	}
	.text-primary{
		color: #524D4D;
	}
	.info-icon{
		background-color: #524D4D;
		border-radius: 25px;
		padding: 3px;
		width: 35px;
		height: 35px;
	}
	.info-icon.small-icon{
		width: 25px;
		height: 25px;
	}
	.info-img{
		width: 100%;
		max-height: 50px;
		object-fit: contain;
	}
	.adjuntos-container{
		background-color: #EFEFEF;
		max-height: 95px;
		overflow-y: scroll;
	}
	.interaction-container{
		width: 100%;
		background-color: #E5E9F2;
		border-radius: 5px;
		position: relative;
		padding: 12px;
	}
	.user-avatar.small{
		width: 25px;
		height: 25px;
	}
	.light-text{color: #959595;}
	.permisos-type-container{
		border: 1px solid #959595;
		border-radius: 10px;
		padding: 10px;
	}
	/deep/ .permisos-type-container .v-input--checkbox label.v-label{font-size: 14px;}
	/deep/ .permisos-type-container .theme--light.v-input,
	/deep/ .permisos-type-container .theme--light.v-label{
		color: rgba(0, 0, 0, 1);
	}
	/deep/ .permisos-type-container .theme--light.v-input--is-disabled,
	/deep/ .permisos-type-container .theme--light.v-label--is-disabled{color: rgba(0, 0, 0, 0.3)}
</style>