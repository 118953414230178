<template>
	<v-dialog
		v-model="$store.state.preprojects.openChatModal"
		scrollable
		max-width="1200"
	>
		<v-card class="fill-height" min-height="90vh">
			<v-card-text class="py-2 fill-height d-flex flex-column">
				<div class="d-flex align-center justify-space-between">
					<div class="d-flex align-center justify-space-between" style="width: 100%;">
						<div>
							<p class="font-weight-bold body-2 mb-0 mr-2">Chat Grupal - {{preproject.name}} - {{preproject.info?.address?.district?.name}} - {{preproject.client?.profile?.name}}</p>
						</div>
						<div>
							
						</div>
						<div class="d-flex">
							<v-menu
								ref="menu"
								v-model="menuOpen"
								transition="scale-transition"
								offset-y
								left
								:close-on-content-click="false"
								nudge-top="-10"
								eager
								v-if="preproject.chat_room && roomAdmin.user_id == userInfo.id"
							>
								<template #activator="{ on: onMenu }">
									<v-tooltip bottom>
										<template #activator="{ on: onTooltip }">
											<div v-on="onTooltip">
												<v-btn
													depressed
													color="#524D4D"
													outlined
													x-small
													v-on="onMenu"
												>
													<p class="mb-0 mr-2">Añadir Usuario a Chat</p>
													<v-icon>mdi-account-plus-outline</v-icon>
												</v-btn>
											</div>
										</template>
										<span v-if="!preproject.chat_room || roomAdmin.user_id != userInfo.id">Sólo el creador de la sala puede agregar/quitar usuarios</span>
									</v-tooltip>
								</template>
								<v-card scrollable class="pa-0">
									<v-card-text class="pa-0" style="height: 280px; width: 400px; overflow-y: scroll;">
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Usuarios Con Acceso a este Chat</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(user, index) in userListChat"
													:key="index"
													dense
													@click="toggleUserToChatList(user.id)"
												>
													<v-list-item-title dense>
														<div class="d-flex align-center">
															<v-icon class="mr-2" small :color="icon(user.id).color">{{icon(user.id).icon}}</v-icon>
															<div class="d-flex justify-space-between align-center" style="width: 100%">
																<div>
																	<p class="mb-0">{{ user?.profile?.name }} {{ user?.profile?.lastname }}</p>
																	<p class="mb-0 text-disabled caption">{{ user?.email }}</p>
																</div>
																<div>
																	<img :src="user?.profile?.avatar" class="user-avatar" v-if="user?.profile?.avatar"/>
																	<v-icon v-else class="user-avatar icon" color="#524D4D">mdi-account</v-icon>
																</div>
															</div>
														</div>
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-card-text>
									<v-card-actions class="d-flex justify-end" style="border-top: 1px solid rgba(0,0,0,0.15)">
										<v-btn
											depressed
											color="#524D4D"
											outlined
											dark
											x-small
											class="add-users-chat-btn"
											@click="onAddUserConfirm"
										>
											<v-icon>mdi-account-check-outline</v-icon>
											<p class="mb-0 mr-2">Confirmar</p>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-menu>
							<v-menu
								ref="menu"
								v-model="menuOpen"
								transition="scale-transition"
								offset-y
								left
								:close-on-content-click="false"
								nudge-top="-10"
								eager
								v-else
							>
								<template #activator="{ on: onMenu }">
									<v-tooltip bottom>
										<template #activator="{ on: onTooltip }">
											<div v-on="onTooltip">
												<v-btn
													depressed
													color="#524D4D"
													outlined
													x-small
													v-on="onMenu"
												>
													<p class="mb-0 mr-2">Participantes</p>
													<v-icon>mdi-account-group-outline</v-icon>
												</v-btn>
											</div>
										</template>
										<span v-if="!preproject.chat_room || roomAdmin.user_id != userInfo.id">Ver participantes de este chat</span>
									</v-tooltip>
								</template>
								<v-card scrollable class="pa-0">
									<v-card-text class="pa-0" style="height: 280px; width: 400px; overflow-y: scroll;">
										<v-list dense class="py-0">
											<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
												<p class="caption mb-0 font-weight-bold white--text">Usuarios Con Acceso a este Chat</p>
											</v-list-item>
											<v-list-item-group color="#524D4D">
												<v-list-item
													v-for="(user, index) in preproject.chat_room?.users"
													:key="index"
													dense
												>
													<v-list-item-title dense>
														<div class="d-flex align-center">
															<div class="d-flex justify-space-between align-center" style="width: 100%">
																<div>
																	<p class="mb-0">{{ user.user?.profile?.name }} {{ user.user?.profile?.lastname }} <span class="caption">- [{{ user?.rol }}]</span></p>
																	<p class="mb-0 text-disabled caption">{{ user.user?.email }}</p>
																</div>
																<div>
																	<img :src="user?.profile?.avatar" class="user-avatar" v-if="user?.profile?.avatar"/>
																	<v-icon v-else class="user-avatar icon" color="#524D4D">mdi-account</v-icon>
																</div>
															</div>
														</div>
													</v-list-item-title>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-card-text>
								</v-card>
							</v-menu>
							<v-btn
								depressed
								color="#524D4D"
								x-small
								outlined
								icon
								class="ml-2"
								@click="$store.commit('preprojects/setOpenChatModal', false)"
							>
								<v-icon small>mdi-close</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<div v-if="preproject.chat_room" class="d-flex flex-column flex-grow-1">
					<Messages :selectedChatRoomId="preproject.chat_room.id" />
				</div>
				<div v-else>
					<p>No se ha habilitado un chat para este pre proyecto, puedes habilitarlo a continuación.</p>
					<v-btn
						depressed
						color="#524D4D"
						small
						outlined
						class="mt-1"
						:loading="loadingChatCreation"
						@click="createPreProjectChatRoom"
					>
						<v-icon small>mdi-forum-plus-outline</v-icon>
						<p class="mb-0 ml-2 caption">Habilitar Chat Grupal</p>
					</v-btn>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>


<script>
import {createChatRoom, addUserToChatRoom} from '@/helpers/api/chats'
import Messages from '@/components/Views/Chat/Messages'

export default {
	name: 'PreProjectModule',
	components: {Messages},
	props: {},
  data() {
    return {
			chatFullscreen: true,
			menuOpen: false,
			add_chat_users_id: [],
			loading: false,
			loadingChatCreation: false,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
    };
  },
  computed: {
		openChatStatus(){
			return this.$store.state.preprojects.openChatModal
		},
		userInfo(){
			return this.$store.state.user.userInfo
		},
    preproject() {
      return this.$store.state.preprojects.preProject
    },
		userListChat(){
			return this.$store.state.users.usersList
		},
		roomAdmin(){
			return this.preproject.chat_room.users.find((user) => user.admin == true)
		}
  },
	watch: {
		openChatStatus(status){
			if(status){
				localStorage.setItem('chat_open', 'true')
			}
			else{
				localStorage.removeItem('chat_open')
			}
		},
		preproject(newValue){
			if(newValue && newValue.chat_room){
				this.add_chat_users_id = newValue.chat_room.users.map(user => user.user_id)
			}
		},
	},
	mounted(){
		if(this.preproject && this.preproject.chat_room){
			this.add_chat_users_id = this.preproject.chat_room.users.map(user => user.user_id)
		}
	},
  methods: {
		icon(user_id){
			const find = this.add_chat_users_id.find((userid) => user_id == userid)
			if(find){
				return {icon: 'mdi-checkbox-intermediate', color: '#FF120B'}
			}
			return {icon: 'mdi-checkbox-blank-outline', color: '#524D4D'}
		},
		openBudgetModal(){
			const data = {
				status: true,
				item: {item_type: 'preproject', item_id: this.preproject.id},
				budget: this.preproject.budget
			}
			this.$store.commit('general/setNewBudgetModal', data)
		},
		toggleUserToChatList(user_id){
			if (this.add_chat_users_id.includes(user_id)) {
				this.add_chat_users_id = this.add_chat_users_id.filter(id => id !== user_id);
			} else {
				this.add_chat_users_id = [...this.add_chat_users_id, user_id];
			}
		},
		async onAddUserConfirm(){
			try {
				await addUserToChatRoom({
					chat_room_id: this.preproject.chat_room.id,
					users_id: this.add_chat_users_id,
				})
				this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.preproject.id, showLoader: false})
				this.menuOpen = false
				const messageData = {
					message: 'Usuarios guardados correctamente',
					title: 'Usuarios guardados',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			} catch (error) {
				const messageData = {
					message: 'Ha ocurrido un error, por favor intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
			}
		},
		async createPreProjectChatRoom(){
			this.loadingChatCreation = true
			const data = {
				name: this.preproject.name,
				type: "App\\Models\\PreProject",
				type_id: this.preproject.id,
				users_id: [this.preproject.client_id]
			}
			await createChatRoom(data)
			this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.preproject.id})
			this.loadingChatCreation = false
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  },
};
</script>

<style>
	.add-users-chat-btn{
		padding: 4px;
	}
	.menu-bg-white{
		background-color: white;
	}
</style>