<template>
	<div>
    <v-data-table
      :headers="headers"
      :items="client_preprojects"
      item-key="id"
      :loading="loadingList"
			height="200px"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.name="{ item }">
				<p class="caption mb-0">{{ item.name }}</p>
      </template>
			<template v-slot:item.entity="{ item, index }">
				<div>
					<p class="caption mb-0">{{item.info?.address?.district?.name ?? '--'}}</p>
					<v-menu :ref="'cityMenu-' + index" offset-y z-index="9999" :close-on-content-click="false" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Municipalidad</p>
							</v-list-item>
							<v-list-item dense class="mb-1 px-1" style="min-height: 25px;">
								<v-text-field
									v-model="searchCity"
									label="Filtro"
									placeholder="Búsqueda"
									outlined
									required
									dense
									hide-details
									class="custom-height-search custom-height-calendar"
									style="max-width: 160px;"
								></v-text-field>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(city, index) in cities"
									:key="index"
									dense
									@click="changePreProjectCity(item, city.id)"
								>
									<v-list-item-title dense>
										{{ city.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.responsible="{ item }">
				<div>
					<p class="mb-0 caption">{{item.responsible?.profile?.name ?? '--'}}</p>
					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Responsable</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(responsible, index) in $store.state.users.usersList"
									:key="index"
									dense
									@click="changePreProjectResponsible(item, responsible.id)"
								>
									<v-list-item-title dense>
										{{ responsible.profile?.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
      <template v-slot:item.documents="{ item }">
				<p class="mb-0 caption">{{ item.documents.length == 0 ? '--':getDocumentDate(item) }}</p>
      </template>
      <template v-slot:item.status="{ item }">
				<v-chip
					class="mt-1"
					:color="getDocumentsStatus(item).color"
					x-small
					label
					dark
					v-if="getDocumentsStatus(item)"
				>
					{{getDocumentsStatus(item).label}}
				</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openPreProjectDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import {getClientPreProjects} from '@/helpers/api/clients'
import {updatePreProjectInfo, setResponsible} from '@/helpers/api/preprojects'
export default {
	name: 'ClientsPreProjectList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openPreProjectDetail: {
      type: Function,
      required: true
    }
	},
	data: () => ({
		searchCity: '',
		loadingList: true,
		client_preprojects: [],
		headers: [
        { text: '#', value: 'id', align: 'center', sortable: false },
        { text: 'Nombre Proyecto', value: 'name', align: 'center', sortable: false },
        { text: 'Municipalidad', value: 'entity', align: 'center', sortable: false },
        { text: 'Fecha Solicitud Desarchivo', value: 'documents', align: 'center', sortable: false },
        { text: 'Profesional Encargado', value: 'responsible', align: 'center', sortable: false },
        { text: 'Estado Antecedentes', value: 'status', align: 'center', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
	}),
	computed: {
		cities(){
			return this.$store.state.general.countryCities.filter(city => city.name.toLowerCase().includes(this.searchCity.toLowerCase()))
		},
	},
	mounted(){
		this.getClientPreProjects()
		this.$store.dispatch('users/getUserFromCompany')
	},
	methods: {
		async getClientPreProjects(){
			this.loadingList = true
			try {
				const client_preproject = await getClientPreProjects({
					client_id: this.clientId,
					start: 1,
					limit: 100
				})
				if(client_preproject.code == 200){
					this.client_preprojects = client_preproject.client_preprojects
				}
			} catch (error) {
				console.log(error)
			}
			this.loadingList = false
		},
		async changePreProjectCity(item, district_id){
			const data = {
				preproject_id: item.id,
				district_id
			}
			await updatePreProjectInfo(data)
			this.getClientPreProjects()
			this.searchCity = ''
      this.closeAllMenus()
		},
		closeAllMenus() {
			Object.keys(this.$refs).forEach((refName) => {
				if (refName.startsWith("cityMenu-")) {
					const menu = this.$refs[refName];
					if (menu) {
						menu.isActive = false;
					}
				}
			});
		},
		getDocumentDate(item){
			return this.$options.filters.toDateTime( item.documents.reduce((min, obj) => obj.created_at < min ? obj.created_at : min, item.documents[0].created_at) )
		},
		getDocumentsStatus(item){
			const all_delivered = item.documents.every((document) => document.delivered_date)
			const some_delayed = item.documents.some((document) => !document.delivered_date && document.max_date && moment().isAfter(document.max_date))
			if(all_delivered){
				return {label: 'Entregado', color: 'green'}
			}
			else if (some_delayed){
				return {label: 'Atrasados', color: 'red'}
			}
			return {label: 'Solicitados', color: 'orange'}
		},
		async changePreProjectResponsible(item, responsible_id){
			const data = {
				preproject_id: item.id,
				responsible_id
			}
			await setResponsible(data)
		this.getClientPreProjects()
		},
	}
}
</script>

<style scoped>
	.menu-bg-white{background: white;}
</style>