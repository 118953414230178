<template>
	<div>
		<v-text-field
			v-model="subject"
			label="Asunto"
			placeholder="Asunto"
			outlined
			required
			hide-details
			dense
			class="mb-2 w-100"
			:rules="requiredRule"
		></v-text-field>
		<vue2-tinymce-editor v-if="!loadingMce" v-model="reportEditor"
			:options="{
				plugins: 'paste image imagetools',
				language: 'es',
				language_url: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/5/langs/es.js',
				font_formats: 'Archivo Narrow=system-ui; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
				toolbar: 'undo redo | fontselect fontsizeselect addBudgetButton | styleselect bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table',
				setup: setupTiny,
				automatic_uploads:true,
				images_upload_handler: uploadImageFromTiny,
				file_picker_callback: filePickerTiny
			}"></vue2-tinymce-editor>
			<input 
				type="file" 
				ref="fileInput" 
				accept="image/*" 
				style="display: none;"
			/>
		<v-divider class="my-4"></v-divider>
		<v-row>
			<v-col cols="6">
				<div class="d-flex align-center justify-space-between mb-2">
					<p class="mb-0 font-weight-bold caption grey--text">Plantillas</p>
					<v-btn
						depressed
						color="#524D4D"
						x-small
						outlined
						:loading="loading"
						class="ml-2"
						@click="$store.commit('vt/setNewReportTemplateModal', true)"
					>
						<v-icon small>mdi-file-document-plus-outline</v-icon>
						<p class="mb-0 ml-2 caption">Agregar</p>
					</v-btn>
				</div>
				<div class="d-flex flex-column justify-content align-center">
						<v-menu
							ref="menu"
							v-model="menuTemplates"
							transition="scale-transition"
							offset-y
							left
							nudge-top="-10"
							eager
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="#524D4D"
									outlined
									dark
									block
									v-bind="attrs"
									v-on="on"
								>
									<p class="mb-0 mr-2">Seleccionar Plantilla</p>
									<v-icon>mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<div class="white d-flex flex-column">
								<div>
									<v-list class="template-list" dense>
										<v-list-item
											class="template-list-item"
											v-for="(template, i) in $store.state.vt.vtReportTemplates" :key="'t-' + i"
											@click="onChangeReportTemplate(template)">
											<v-list-item-content>
												<v-list-item-title>{{template.name}}</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action>
												<div class="d-flex align-center">
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																fab
																dark
																x-small
																icon
																color="warning"
																@click="editReportTemplate(template)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small dark>mdi-pencil-outline</v-icon>
															</v-btn>
														</template>
														<span>Editar</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																fab
																dark
																x-small
																icon
																color="#524D4D"
																@click="editReportTemplate(template, true)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small dark>mdi-content-duplicate</v-icon>
															</v-btn>
														</template>
														<span>Duplicar</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																fab
																dark
																x-small
																icon
																color="error"
																@click="deleteVTReportTemplates(template.id)"
																v-bind="attrs"
																v-on="on"
															>
																<v-icon small dark>mdi-delete-outline</v-icon>
															</v-btn>
														</template>
														<span>Eliminar</span>
													</v-tooltip>
												</div>
											</v-list-item-action>
										</v-list-item>
									</v-list>
								</div>
							</div>
						</v-menu>
				</div>
			</v-col>
			<v-col cols="6">
			</v-col>
		</v-row>
		<v-divider class="my-3"></v-divider>
		<v-row>
			<v-col cols="6">
				<div class="d-flex align-center">
					<p class="mb-0 font-weight-bold caption grey--text">Enviar con copia (Ingresa correos separados por coma)</p>
				</div>
				<div class="d-flex flex-column justify-content align-center">
						<div style="width: 100%;">
							<v-text-field
								v-model="cc_report"
								label="Enviar con copia"
								placeholder="Enviar con copia"
								outlined
								required
								hide-details
								dense
								class="mb-2 w-100"
							></v-text-field>
						</div>
				</div>
			</v-col>
			<v-col cols="6">
				<p class="mb-0 font-weight-bold caption grey--text">Confirmar envío</p>
				<v-btn
					depressed
					color="#524D4D"
					small
					block
					dark
					:loading="loading"
					@click="sendReport()"
				>
					<v-icon small>mdi-email-outline</v-icon>
					<p class="mb-0 ml-2 caption">Confirmar y Enviar</p>
				</v-btn>
				<v-btn
					depressed
					color="#524D4D"
					dark
					small
					block
					outlined
					:loading="loading"
					@click="cleanReportEditor"
					class="mt-2"
				>
					<v-icon small>mdi-cancel</v-icon>
					<p class="mb-0 ml-2 caption">Limpiar Todo</p>
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {fileUploadToReport, deleteVTReportTemplate} from '@/helpers/api/files'
import {sendReportPreproject} from '@/helpers/api/preprojects'
import {sendReportSchedule} from '@/helpers/api/schedule'

export default {
	name: 'ReportsHandler',
  components: {
    Vue2TinymceEditor
  },
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		ufValue(){
			return this.$store.state.general.dayUfValue
		}
	},
	data(){
		return{
			loadingMce: true,
			loading: false,
			subject: '',
			report_title: null,
			reportEditor: '',
			cc_report: null,
			menuTemplates: false,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
		}
	},
	mounted(){
		if(this.item.item_type == 'preproject'){
			this.subject = 'Presupuesto Desarchivo'
		}
		if(this.item.item_type == 'crm'){
			this.subject = 'Informe'
		}
		this.$store.dispatch('vt/getVtReportTemplates')
		this.$nextTick(() => {
			this.loadingMce = false
		});
	},
	methods: {
		getBudgetHtmlTableForInsert(){
			const budget = this.item.budget
			// Crear el contenedor de la tabla
			let tableHTML = '<table style="width: 100%; border-collapse: collapse;">';
			
			// Agregar encabezados
			tableHTML += `
				<thead>
					<tr>
						<th style="text-align: left; padding: 8px; border: 1px solid #ddd;">Concepto</th>
						<th style="text-align: left; padding: 8px; border: 1px solid #ddd;">Detalle</th>
						<th style="text-align: right; padding: 8px; border: 1px solid #ddd;">UF</th>
						<th style="text-align: right; padding: 8px; border: 1px solid #ddd;">Pesos</th>
					</tr>
				</thead>
				<tbody>
			`;
			
			// Agregar filas del presupuesto
			if (budget && Array.isArray(budget.categories)) {
				budget.categories.forEach((budget_category) => {
					tableHTML += `
						<tr>
							<td colspan="4" style="padding: 4px 8px; border: 1px solid #ddd;">
								<b>${budget_category.number}º Etapa - ${budget_category.name}</b>
							</td>
						</tr>
					`
					budget_category.parts.forEach((budget_part) => {
						tableHTML += `
							<tr>
								<td style="padding: 0 8px; border: 1px solid #ddd;">${budget_part.concept ?? ''}</td>
								<td style="padding: 0 8px; border: 1px solid #ddd;">${budget_part.detail ?? ''}</td>
								<td style="text-align: right; min-width: 70px; padding: 0 8px; border: 1px solid #ddd;">
									${budget_part.value != null ? this.$options.filters.toThousandFilter(budget_part.value) : ''} 
									<small><b>${budget_part.value != null ? '+ IVA' : ''}</b></small>
								</td>
								<td style="text-align: right; min-width: 120px; padding: 0 8px; border: 1px solid #ddd;">
									${budget_part.value != null ? 
											`$${this.$options.filters.toThousandFilter(Math.round(budget_part.value * this.ufValue))}` : ''} 
									<small><b>${budget_part.value != null ? '+ IVA' : ''}</b></small>
								</td>
							</tr>
						`
					})
				})
			}
			
			// Agregar fila del total del presupuesto
			if (budget) {
				tableHTML += `
					<tr>
						<td colspan="2" style="text-align: left; font-weight: bold; padding: 8px; border: 1px solid #ddd;">Presupuesto Total</td>
						<td style="text-align: right; padding: 8px; border: 1px solid #ddd;">
							${this.$options.filters.toThousandFilter(budget.total_value)} <small><b>+ IVA</b></small>
						</td>
						<td style="text-align: right; padding: 8px; border: 1px solid #ddd;">
							$${this.$options.filters.toThousandFilter(Math.round(budget.total_value * this.ufValue))} <small><b>+ IVA</b></small>
						</td>
					</tr>
				`;
			}
			
			// Cerrar la tabla
			tableHTML += '</tbody></table>';

			return tableHTML;
		},
		async sendReport(){
			const recipients = this.cc_report ? this.cc_report.split(",").map(email => email.trim()):null
			try {
				if(this.item.item_type == 'preproject'){
					const data = {
						preproject_id: this.item.id,
						report: this.reportEditor,
						recipients,
						subject: this.subject,
						report_title: this.report_title
					}
					await sendReportPreproject(data)
					this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.item.id})
				}
				else if(this.item.item_type == 'crm'){
					const data = {
						schedule_id: this.item.schedule.id,
						report: this.reportEditor,
						recipients,
						subject: this.subject,
						report_title: this.report_title
					}
					await sendReportSchedule(data)
					this.$store.dispatch('crm/getConversation', {conversation_id: this.item.id})
				}
				const messageData = {
					message: 'Correo enviado correctamente',
					title: 'Correo enviado',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.$store.dispatch('general/addMessage', messageData)
				this.$store.commit('preprojects/setOpenBudgetModal', false)
			} catch (error) {
				const messageData = {
					message: 'Hubo un error al enviar el correo, por favor verifica e intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', messageData)
			}
		},
		async editReportTemplate(reportTemplate, duplcating = false){
			const rt = {...reportTemplate}
			if(duplcating){
				rt.name = rt.name + ' (Copia)'
				delete rt.id
			}
			this.$store.commit('vt/setNewReportTemplateEditing', rt)
      this.$store.commit('vt/setNewReportTemplateModal', true)
		},
		cleanReportEditor(){
			this.reportEditor = ''
		},
		onChangeReportTemplate(report_template){
			this.report_title = report_template.name
			this.reportEditor = report_template.content
		},
		async deleteVTReportTemplates(template_id){
			await deleteVTReportTemplate({template_id})
			this.$store.dispatch('vt/getVtReportTemplates')
		},
		uploadImageFromTiny(file, succes, failure){
			const formData = new FormData();
			formData.append('file', file.blob());

			fileUploadToReport(formData)
				.then((uploaded_file) => {
					if (uploaded_file.code === 200) {
						succes(uploaded_file.url);
					} else {
						failure(new Error(`Error: ${uploaded_file.code}`));
					}
				})
				.catch((error) => {
					failure(error);
				});
		},
		filePickerTiny(callback) {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0];
				const formData = new FormData();
				formData.append('file', file);
				try {
					const uploaded_file = await fileUploadToReport(formData);
					if (uploaded_file.code === 200) {
						const url = uploaded_file.url;
						callback(url, { alt: file.name });
					} else {
						console.error('Error al subir la imagen:', uploaded_file.message);
					}
				} catch (error) {
					console.error('Error en la subida de la imagen:', error);
				}
			};

			// Simular un clic en el input para abrir el selector de archivos
			input.click();
		},
		setupTiny(editor){
      editor.ui.registry.addButton('addBudgetButton', {
        text: 'Insertar Presupuesto',
        icon: 'paste-text',
        onAction: () => {
					if(this.item.budget){
						const selection = editor.selection
						const range = selection.getRng()
						if (range) {
							editor.insertContent('<br>')
							editor.insertContent(this.getBudgetHtmlTableForInsert())
						} else {
							console.log('No hay texto seleccionado')
						}
					}
					else{
						const data = {
							message: 'No hay presupuesto generado, por favor genera uno antes de agregarlo. Puedes hacerlo con el botón "Gestionar Presupuesto".',
							title: 'No hay presupuesto',
							created: new Date(),
							type: 'danger',
							icon: 'mdi-close-octagon',
							color: '#F4516C',
						}
						this.$store.dispatch('general/addMessage', data)
					}
        }
      })
			editor.on('paste', (e) => {
				const clipboard = e.clipboardData || e.originalEvent.clipboardData;
				
				if (clipboard && clipboard.items) {
					let pastedContent = '';
					let imageCounter = 0;

					const handleItem = (item) => {
							return new Promise((resolve) => {
									if (item.type.indexOf('image') !== -1) {
											// Procesar imágenes como archivos
											const file = item.getAsFile();
											const formData = new FormData();
											formData.append('file', file);

											fileUploadToReport(formData)
													.then((uploaded_file) => {
															if (uploaded_file.code == 200) {
																	const url = uploaded_file.url;
																	pastedContent += `<img src="${url}" />`;
															}
															resolve();
													});
									} else if (item.type === 'text/html') {
											// Procesar HTML (posibles imágenes en base64)
											item.getAsString((htmlContent) => {
													const parser = new DOMParser();
													const doc = parser.parseFromString(htmlContent, 'text/html');
													const images = doc.querySelectorAll('img');

													if (images.length > 0) {
														// Si hay imágenes en base64, subirlas al servidor
														images.forEach((img, index) => {
															if (img.src.startsWith('data:image')) {
																const base64Data = img.src.split(',')[1];
																const contentType = img.src.split(';')[0].split(':')[1];

																const byteCharacters = atob(base64Data);
																const byteNumbers = new Array(byteCharacters.length);
																for (let i = 0; i < byteCharacters.length; i++) {
																	byteNumbers[i] = byteCharacters.charCodeAt(i);
																}

																const byteArray = new Uint8Array(byteNumbers);
																const blob = new Blob([byteArray], { type: contentType });
																const file = new File([blob], `image${imageCounter}.png`, { type: contentType });

																const formData = new FormData();
																formData.append('file', file);
																imageCounter++;

																// Subir imagen al servidor
																fileUploadToReport(formData)
																	.then((uploaded_file) => {
																		if (uploaded_file.code == 200) {
																			img.src = uploaded_file.url;
																		}
																		if (index === images.length - 1) {
																			pastedContent += doc.body.innerHTML;
																			resolve();
																		}
																	});
															} else {
																// Si no es base64, insertar la imagen directamente
																pastedContent += doc.body.innerHTML;
																resolve();
															}
														});
													} else {
															// No hay imágenes, solo insertar el HTML pegado
															pastedContent += htmlContent;
															resolve();
													}
											});
									} else if (item.type === 'text/plain') {
											// Procesar texto sin formato
											item.getAsString((plainText) => {
													pastedContent += plainText;
													resolve();
											});
									} else {
											resolve();
									}
							});
					};

					const processItemsSequentially = async () => {
							for (let i = 0; i < clipboard.items.length; i++) {
									await handleItem(clipboard.items[i]);
							}
							// Una vez procesados todos los ítems, insertar el contenido en el editor
							editor.execCommand('mceInsertContent', false, pastedContent);
					};

					// Procesar los ítems de forma secuencial
					processItemsSequentially();
				}
			});
		},
	}
}
</script>