<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.projects.zoneIptModal"
      max-width="400"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <!-- <v-icon color="white" small class="info-icon mr-2">mdi-pub</v-icon> -->
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Zona Proyecto</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
							<v-text-field
								label="Nombre Zona"
								v-model="newZoneIpt.name"
								outlined
								required
								dense
								:rules="requiredRule"
							></v-text-field>
							<v-text-field
								label="Descripción Zona (Opcional)"
								v-model="newZoneIpt.description"
								outlined
								required
								dense
							></v-text-field>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingCreateZone"
                      @click="confirmZone"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createZoneIpt, updateZoneIpt} from '@/helpers/api/projects'

export default {
  name: 'NewZoneIpt',
	props: {
		reload: {
      type: Function,
      required: true
    }
	},
  data: () => ({
    validform: true,
		loadingCreateZone: false,
		newZoneIpt: {
			name: null,
			description: null
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
  },
  mounted(){
		if(this.$store.state.projects.zoneIptModalEditInfo){
			this.newZoneIpt = {
				...this.newZoneIpt,
				...this.$store.state.projects.zoneIptModalEditInfo
			}
		}
  },
  methods: {
    closeModal(){
			this.$store.commit('projects/setZoneIptModal', false)
			this.$store.commit('projects/setZoneIptModalEditInfo', null)
			this.newZoneIpt = {
				name: null,
				description: null
			}
      this.validform = true
      this.loadingCreateZone = false
    },
		async confirmZone(){
			this.loadingCreateZone = true
			let zone;
			if (this.newZoneIpt.zone_ipt_id) {
				zone = await updateZoneIpt(this.newZoneIpt);
			} else {
				zone = await createZoneIpt(this.newZoneIpt);
			}
			if(zone.code == 200){
				this.reload()
				this.closeModal()
			}
			this.loadingCreateZone = false
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
		width: 200px;
	}
	.profile-image{
		width: 100%;
		height: 150px;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding: 5px 10px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>