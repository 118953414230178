<template>
	<v-container fluid style="background-color: #3D1152;" fill-height class="d-flex flex-column justify-center">
		<v-snackbar
			v-model="showMessage"
			vertical
			bottom
			center
			:color="messageColor"
		>
			<p class="mb-0">{{message}}</p>
		</v-snackbar>
		<img :src="require('../../assets/logoarquimarket.png')" style="max-width: 150px; width: 100%;"/>
		<div class="login-form-container d-flex justify-center mt-8">
			<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;"
			>
				<p class="white--text text-center">Hola, por favor ingresa tu nueva contraseña</p>
				<v-text-field
					v-model="userPassword"
					:rules="[passwordRules]"
					label="Nueva Contraseña"
					dark
					required
					:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-text-field
					v-model="confirmPassword"
					:rules="[passwordRules, (userPassword === confirmPassword) || 'Las contraseñas no coinciden']"
					label="Confirmar Contraseña"
					dark
					required
					:append-icon="passType ? 'mdi-eye-off' : 'mdi-eye'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#3DAE2B"
					dark
					:loading="loadingLogin"
					@click="startValidation"
				>
					Continuar
				</v-btn>
			</v-form>
		</div>
	</v-container>
</template>

<script>
import {login, checkUserToken} from '@/helpers/api/user'

export default {
	name: 'NewUserPassword',
	data: () => ({
		valid: true,
		showMessage: false,
		messageColor: 'error',
		message: '',
		email: '',
		emailRules: [
			v => !!v || 'Email es obligatorio',
			v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
		],
		userPassword: '',
		confirmPassword: '',
		passwordRules: [
			v => !!v || 'Contraseña es requerida',

		],
		passType: true,
		loadingLogin: false,
		token: '',
		user_id: null
	}),
	mounted(){
		this.user_id = this.$route.query.token.split('-')[0]
		this.token = this.$route.query.token.split('-')[1]
		this.$store.commit('user/logoutUser')
	},
	methods: {
		validate () {
			this.$refs.form.validate()
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		async startValidation(){
			this.loadingLogin = true
			const data = {
				password: this.userPassword,
				password_confirmation: this.confirmPassword,
				token: this.token,
				user_id: this.user_id
			}
			const user = await checkUserToken(data)
			if(user.code === 200 && user.status){
				this.message = 'Cuenta modificada con éxito. En unos segundos ingresarás a la plataforma automáticamente.'
				this.messageColor = 'success'
				this.showMessage = true
				setTimeout(() => {
					this.loginUser(user.user, this.userPassword)
				}, 2000)
			}
			else if(user.code === 200){
				this.message = user.message
				this.messageColor = 'warning'
				this.showMessage = true
				this.loadingLogin = false
			}
			else{
				this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
				this.loadingLogin = false
			}
		},
		loginUser (email, userPassword) {
			login(email, userPassword)
				.then(() => {
					this.loadingLogin = false
					this.$router.push({name: 'Dashboard'})
				})
				.catch((error) => {
					this.loadingLogin = false
					this.message = 'Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.'
					if(error.response && error.response.status == 401){
						this.message = 'Email o contraseña incorrectos. Por favor verifica los datos ingresados.'
					}
					this.messageColor = 'warning'
					this.showMessage = true
				})
		}
	},
};
</script>
<style scoped>
	.login-form-container{
		width: 100%;
	}	
</style>
