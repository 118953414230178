import {endpoints} from './endpoints'
import request from '../request'

export function createContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionCategory,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionCategoryOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionCategoryOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSectionCategory (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSectionCategory,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContractSectionCategories (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContractSectionCategories,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function createContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSection,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSection,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateContractSectionOrders (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateContractSectionOrders,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteContractSection (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteContractSection,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContractSections (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContractSections,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContract (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContract,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractSectionB (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionB,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createContractSectionContent (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createContractSectionContent,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getContract (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getContract,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCompanyContracts (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCompanyContracts,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}