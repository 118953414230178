import { getConversations, getArchivedConversations, getConversation } from "@/helpers/api/conversations"
import router from '@/router'
const listingColumns = localStorage.getItem('crm_columns')

const CRMStore = {
  namespaced: true,
  state: {
		loadingConversations: false,
    conversations: [],
    conversations_count: 0,
    arch_conversations_count: 0,
    archivedConversations: [],
		conversation: null,
		loadingConversation: false,
		newContactModalData: null,
		newContactModal: false,
		listingColumns: listingColumns ? JSON.parse(listingColumns):{
			id: true,
			user: true,
			address: true,
			// message: true,
			schedule: true,
			schedule_user: true,
			details: true,
			created_at: true,
			actions: true,
		},
		openBudgetModal: false
  },
  mutations: {
    setConversations(state, conversations) {
      state.conversations = conversations
    },
    setConversationsCount(state, conversations_count) {
      state.conversations_count = conversations_count
    },
    setArchivedConversationsCount(state, conversations_count) {
      state.arch_conversations_count = conversations_count
    },
    setArchivedConversations(state, conversations) {
      state.archivedConversations = conversations
    },
    setLoadingConversations(state, status) {
      state.loadingConversations = status
    },
    setConversation(state, conversation) {
      state.conversation = conversation
    },
    setLoadingConversation(state, status) {
      state.loadingConversation = status
    },
    setNewContactModal(state, status) {
      state.newContactModal = status
    },
    setNewContactModalData(state, data) {
      state.newContactModalData = data
    },
    setListingColumns(state, listingColumns) {
      state.listingColumns = listingColumns
			localStorage.setItem('crm_columns', JSON.stringify(listingColumns))
    },
    setOpenBudgetModal(state, status) {
      state.openBudgetModal = status
    },
  },
  actions: {
    async getConversations({ commit }, {search = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingConversations', true)
      const data = {
				search,
        start,
        limit
      }
      const conversations = await getConversations(data)
      if (conversations.code === 200) {
        commit('setConversations', conversations.conversations)
        commit('setConversationsCount', conversations.conversations_count)
      }
      commit('setLoadingConversations', false)
    },
    async getArchivedConversations({ commit }, {search = null, start = 0, limit = 20} = {}) {
			start = isNaN(start) ? 0 : start
      commit('setLoadingConversations', true)
      const data = {
				search,
        start,
        limit
      }
      const conversations = await getArchivedConversations(data)
      if (conversations.code === 200) {
        commit('setArchivedConversations', conversations.conversations)
        commit('setArchivedConversationsCount', conversations.conversations_count)
      }
      commit('setLoadingConversations', false)
    },
    getConversation({ commit }, { conversation_id = null, showLoader = true } = {}) {
			if(showLoader){
				commit('setLoadingConversation', true)
				commit('setConversation', null)
			}
      const data = {
        conversation_id
      }
      getConversation(data)
				.then((conversation) => {
					if (conversation.code === 200) {
						commit('setConversation', conversation.conversation)
					}
					commit('setLoadingConversation', false)
				})
				.catch(() => {
					commit('setConversation', null)
					commit('setLoadingConversation', false)
					router.push({name: 'CRM'})
				})
    }
  }
}

export default CRMStore
