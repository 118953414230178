<template>
	<v-card class="mb-4">
		<v-card-text class="py-2">
			<div class="d-flex align-center justify-space-between">
				<p class="font-weight-bold body-2 mb-0 mr-2">Carga de Archivos</p>
				<div class="px-2 py-0">
					<v-btn
						depressed
						color="#524D4D"
						x-small
						outlined
						:loading="loading"
						@click="showFilePicker"
					>
						<v-icon small>mdi-file-document-plus-outline</v-icon>
						<p class="mb-0 ml-2 caption">Agregar Archivo</p>
					</v-btn>
					<input 
						type="file" 
						ref="fileInput"
						style="display: none;"
						:accept="acceptedFileTypes"
					/>
				</div>
			</div>
			<v-divider class="mt-2 mb-6"></v-divider>
			<div class="d-flex flex-wrap">
				<div
					v-for="( file, index ) in preproject.files" :key="index"
					class="file-icon-buttons rounded ma-1 pa-1 d-flex flex-column justify-center"
					:class="selectedFile && selectedFile.id == file.id ? 'selected':''"
					@click="$store.commit('general/setSelectedFile',file)"
				>
					<v-icon :color="getIconFile(file).color" large>{{getIconFile(file).icon}}</v-icon>
					<p class="mb-0 mt-2 caption text-center truncate-text">{{file.name}}</p>
				</div>
			</div>
			<v-divider class="my-2"></v-divider>
			<v-row class="mb-4">
				<v-col cols="6" v-for="( file, index ) in filesToUpload" :key="index">
					<div class="single-file-container pa-3 rounded">
						<div class="d-flex justify-space-between align-center mb-2">
							<p class="mb-0 caption truncate-text">Archivo: <b>{{file.file.name}}</b></p>
							<v-btn
								depressed
								color="#524D4D"
								small
								icon
								:loading="file.loading"
								@click="removeFileToUpload(index)"
							>
								<v-icon small>mdi-close-outline</v-icon>
							</v-btn>
						</div>
						<v-text-field
							v-model="file.name"
							label="Descripción"
							outlined
							required
							dense
							hide-details
							class="mb-1"
							:rules="requiredRule"
						></v-text-field>
							<v-btn
								depressed
								color="#524D4D"
								small
								outlined
								:loading="file.loading"
								@click="uploadFile(file, index)"
							>
								<v-icon small>mdi-upload</v-icon>
								<p class="mb-0 ml-2 caption">Subir Archivo</p>
							</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>


<script>
import {addFileToPreProject} from '@/helpers/api/preprojects'

export default {
	name: 'PreProjectModule',
	components: {},
	props: {},
  data() {
    return {
			loading: false,
			filesToUpload: [],
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
    };
  },
  computed: {
    selectedFile() {
      return this.$store.state.general.selectedFile
    },
    preproject() {
      return this.$store.state.preprojects.preProject
    },
		acceptedFileTypes() {
			return [
				'application/pdf', 
				'application/msword', 
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
				'application/vnd.ms-excel', 
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
				'image/jpeg', 
				'image/png', 
				'image/gif', 
				'image/bmp', 
				'image/svg+xml'
			].join(',');
		}
  },
	watch: {
	},
	mounted(){
	},
  methods: {
		getIconFile(file){
			const fileExtension = file.path.split('.').pop().toLowerCase();
			if (fileExtension === 'pdf') {
					return { icon: 'mdi-file-pdf-box', color: '#FF0000' };
			} else if (fileExtension === 'doc' || fileExtension === 'docx') {
					return { icon: 'mdi-file-word', color: '#1E88E5' };
			} else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
					return { icon: 'mdi-file-excel', color: '#4CAF50' };
			} else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(fileExtension)) {
					return { icon: 'mdi-file-image', color: '#FFD700' };
			} else {
					return { icon: 'mdi-file', color: '#9E9E9E' };
			}
		},
		showFilePicker() {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0]
				this.filesToUpload.push({
					preproject_id: this.preproject.id,
					file,
					name: null,
					loading: false
				})
				input.value = null
			};
			input.click();
		},
		async uploadFile(file, index){
			file.loading = true
			const formData = new FormData()
			formData.append('file', file.file)
			formData.append('preproject_id', file.preproject_id)
			file.name && formData.append('name', file.name)
			await addFileToPreProject(formData)
			this.$store.dispatch('preprojects/getPreProject', {preproject_id: this.preproject.id})
			this.filesToUpload.splice(index, 1)
			file.loading = false
		},
		removeFileToUpload(index){
			this.filesToUpload.splice(index, 1)
		}
  },
};
</script>

<style scoped>
	.single-file-container{
		background-color: white;
		border: 1px solid rgb(193, 191, 191);
	}
	.file-icon-buttons{
		width: 70px;
		height: 80px;
		border: 1px solid rgb(213, 211, 211);
		cursor: pointer;
	}
	.file-icon-buttons:hover{
		background-color: rgb(239, 239, 239);
	}
	.file-icon-buttons.selected{
		background-color: rgb(213, 211, 211);
	}
	.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
	}
</style>