import { render, staticRenderFns } from "./ContractCategoryModal.vue?vue&type=template&id=70ae9aaa&scoped=true&"
import script from "./ContractCategoryModal.vue?vue&type=script&lang=js&"
export * from "./ContractCategoryModal.vue?vue&type=script&lang=js&"
import style0 from "./ContractCategoryModal.vue?vue&type=style&index=0&id=70ae9aaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ae9aaa",
  null
  
)

export default component.exports