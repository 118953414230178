const endpoints = {
	createUserChatRoom: '/chats/createUserChatRoom',
	createChatRoom: '/chats/createChatRoom',
	addUserToChatRoom: '/chats/addUserToChatRoom',
	removeUserFromChatRoom: '/chats/removeUserFromChatRoom',
	addMessageToChatRoom: '/chats/addMessageToChatRoom',
	getUserChatRooms: '/chats/getUserChatRooms',
	getChatRoomMessages: '/chats/getChatRoomMessages',
	chatFileToItem: '/chats/chatFileToItem'
}
export {endpoints}