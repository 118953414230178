const endpoints = {
	createContractSectionCategory: '/contracts/config/createContractSectionCategory',
	updateContractSectionCategory: '/contracts/config/updateContractSectionCategory',
	updateContractSectionCategoryOrders: '/contracts/config/updateContractSectionCategoryOrders',
	getCompanyContractSectionCategories: '/contracts/config/getCompanyContractSectionCategories',
	deleteContractSectionCategory: '/contracts/config/deleteContractSectionCategory',
	createContractSection: '/contracts/config/createContractSection',
	updateContractSection: '/contracts/config/updateContractSection',
	updateContractSectionOrders: '/contracts/config/updateContractSectionOrders',
	deleteContractSection: '/contracts/config/deleteContractSection',
	getCompanyContractSections: '/contracts/config/getCompanyContractSections',
	createContract: '/contracts/createContract',
	createContractSectionB: '/contracts/createContractSection',
	createContractSectionContent: '/contracts/createContractSectionContent',
	getContract: '/contracts/getContract',
	getCompanyContracts: '/contracts/getCompanyContracts',
}
export {endpoints}