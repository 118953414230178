<template>
	<v-card class="mb-4">
		<v-card-text class="py-2">
			<div class="d-flex align-center justify-space-between">
				<p class="font-weight-bold body-2 px-1 mb-0">Agenda - Visita Técnica</p>
				<v-btn
					icon
					color="#524D4D"
					v-if="!conversation.schedule"
					@click="editCrmSchedule"
					:disabled="!editPermission"
				>
					<v-icon>mdi-invoice-clock-outline</v-icon>
				</v-btn>
			</div>
			<v-divider class="my-1"></v-divider>
			<div class="py-2" v-if="conversation.schedule">
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-if="editScheduleTitle">
					<v-text-field
						v-model="newScheduleTitle"
						label="Nuevo Título Agenda"
						placeholder="Ingrese el título"
						outlined
						required
						dense
						hide-details=""
						maxlength="255"
						class="my-1"
					>
						<template v-slot:append-outer>
							<v-btn
								icon
								color="error"
								@click="editScheduleTitle = false"
								:disabled="!editPermission"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
							<v-btn
								icon
								color="warning"
								@click="sendEditScheduleTitle"
								:disabled="!editPermission"
							>
								<v-icon>mdi-check</v-icon>
							</v-btn>
						</template>
					</v-text-field>
				</div>
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
					<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Título Agenda</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.schedule.user_schedule?.title}}</b></div>
					<div class="action-container">
						<v-btn
							icon
							color="warning"
							x-small
							@click="startEditTitle"
							:disabled="!editPermission"
						>
							<v-icon>mdi-pencil-outline</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
					<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Fecha agendada</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.schedule.vt_date | toDateTime}} hrs.</b></div>
					<div class="action-container"></div>
				</div>
				<div class="d-flex justify-space-between align-center" v-if="editAddress">
					<v-autocomplete
						v-model="newAddress.address"
						:items="[...itemsAddress, ...[conversation.project_info?.address?.address]]"
						name="address"
						:search-input.sync="search"
						prepend-inner-icon="mdi-map-marker"
						label="Dirección"
						placeholder="Dirección"
						autocomplete="off"
						outlined
						dense
						hide-details
						:cache-items="cachingItems"
						:loading="loadingAddress"
						:filter="d => d"
						color="secondary"
						item-color="secondary"
						return-object
						@change="change"
					></v-autocomplete>
					<v-btn
						icon
						small
						color="error"
						@click="editAddress = false"
						:disabled="!editPermission"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
					<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Dirección</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.project_info?.address?.address}}</b></div>
					<div class="action-container">
						<v-btn
							icon
							color="warning"
							x-small
							@click="startEditAddress"
							:disabled="!editPermission"
						>
							<v-icon>mdi-vector-square-edit</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="d-flex justify-space-between align-center" v-if="editValue">
					<v-text-field
						v-model="newValueCrm"
						label="Nuevo Valor visita técnica"
						placeholder="Ingrese el valor"
						outlined
						required
						dense
						hide-details=""
						maxlength="9"
						class="my-1"
						@keypress="isNumberKey"
					>
						<template v-slot:append-outer>
							<v-btn
								icon
								color="error"
								@click="editValue = false"
								:disabled="!editPermission"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
							<v-btn
								icon
								color="warning"
								@click="sendEditValue"
								:disabled="!editPermission"
							>
								<v-icon>mdi-check</v-icon>
							</v-btn>
						</template>
					</v-text-field>
				</div>
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
					<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Valor Visita Técnica</div><span>:</span> <b class="text-end" style="flex: 1;">${{conversation.project_info?.address_value | toThousandFilter}}</b></div>
					<div class="action-container">
						<v-btn
							icon
							color="warning"
							x-small
							@click="editValue = !editValue"
							:disabled="!editPermission"
						>
							<v-icon>mdi-pencil-outline</v-icon>
						</v-btn>
					</div>
				</div>
				<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
					<div class="d-flex justify-space-between" style="width: 100%"><div style="width: 150px">Tipo de Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.project_info?.project_type.name}}</b></div>
					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;" v-if="$checkPermissions(['edit_crm'])">
						<template v-slot:activator="{ on, attrs }">
							<div class="action-container">
								<v-btn
									icon
									color="warning"
									x-small
									v-bind="attrs"
									v-on="on"
								>
									<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
								</v-btn>
							</div>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Tipo de Proyecto</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(project_type, index) in $store.state.companies.companiesProjectTypes"
									:key="index"
									dense
									@click="changeProjectType(project_type.id)"
								>
									<v-list-item-title dense>
										{{ project_type.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
				<v-divider class="my-3"></v-divider>
				<v-select
					v-if="$checkPermissions(['schedule_assignment'])"
					v-model="scheduledUserId"
					:items="$store.state.users.usersList"
					:loading="loadingUsers"
					item-text="profile.name"
					item-value="id"
					label="Usuario encargado"
					outlined
					dense
					required
					hide-details
					:rules="requiredRule"
					@change="changeUserAssignation"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{data.item.profile.name}} - <span class="light-blue-text">[{{data.item.email}}]</span></v-list-item-title>
						</v-list-item-content>
					</template>
					<template #append-outer>
						<v-btn
							class="my-0"
							icon
							@click="addUser"
						>
							<v-icon>mdi-account-plus</v-icon>
						</v-btn>
					</template>
				</v-select>
				<p v-else class="d-flex justify-space-between mb-1">Usuario Encargado: <b>{{scheduledUserName}}</b></p>
				<v-divider class="my-3"></v-divider>
				<p class="d-flex justify-space-between align-center mb-1">
					Estado Visita Técnica:
					<v-chip
						v-if="conversation.schedule"
						class="mt-1"
						:color="conversation.schedule.status == 1 ? 'orange':'green'"
						label
						:dark="conversation.schedule.status == 2"
						:outlined="conversation.schedule.status == 1"
						small
					>
						<v-icon small class="mr-2">{{ conversation.schedule.status == 1 ? 'mdi-alert':'mdi-check' }}</v-icon>
						{{vtStatusText}}
					</v-chip>
					<v-chip
						v-else
						class="mt-1"
						color="danger"
						label
						dark
						outlined
						small
					>
						<v-icon small class="mr-2">mdi-close</v-icon>
						No se generó agenda
					</v-chip>
				</p>
				<p class="d-flex justify-space-between align-center mb-1"
					v-if="conversation.schedule && conversation.schedule.payments.length > 0">Fecha de Pago Visita Técnica: <b>{{ conversation.schedule.payments[0].payment_info?.created_at | toDateTime }}</b></p>
				<v-divider class="my-2"></v-divider>
				<div class="d-flex justify-space-between align-center mb-2">
					<p class="font-weight-bold mb-0">Visitas Técnicas</p>
					<v-chip
						class="mt-1"
						label
						outlined
						small
						@click="startCloseAgenda"
						:disabled="!editPermission"
					>
						<v-icon small class="mr-2">mdi-calendar-alert-outline</v-icon>
						Agregar Cierre Visita
					</v-chip>
				</div>
				<div class="px-2" v-if="conversation.schedule.closure.length > 0">
					<div class="d-flex px-1">
						<p class="py-1 mb-0 d-flex flex-1 caption font-weight-bold w-100" style="width: 100%">
							Fecha de realización
						</p>
						<v-divider class="mx-1" vertical></v-divider>
						<p class="py-1 mb-0 d-flex flex-1 caption font-weight-bold w-100" style="width: 100%">
							Comentario
						</p>
					</div>
					<v-divider></v-divider>
					<div class="py-0 d-flex px-1 item-background-hover" v-for="(closure,index) in conversation.schedule.closure" :key="index">
						<p class="py-1 mb-0 d-flex flex-1 caption" style="width: 100%">
							<v-icon small class="mr-2">mdi-calendar</v-icon>
							{{closure.closed}}
						</p>
						<v-divider class="mx-1" vertical></v-divider>
						<p class="py-1 mb-0 d-flex flex-1 caption" style="width: 100%">
							{{closure.closure_report}}
						</p>
					</div>
				</div>
				<v-divider class="my-2"></v-divider>
				<p class="d-flex justify-space-between align-center mb-1">
					Estado Informe:
					<v-chip
						class="mt-1"
						:color="vtStatus.color"
						small
						label
						dark
						v-if="vtStatus"
					>
						<v-icon class="mr-1" x-small>mdi-email-outline</v-icon>
						{{vtStatus.text}}
					</v-chip>
				</p>
				<v-divider class="my-3"></v-divider>
				<GmapMap
					v-if="geoAddress"
					:center="geoAddress"
					:zoom="mapZoom"
					@zoom_changed="updateZoom"
					ref="vtMap"
					style="width: 100%; height: 300px"
				>
					<GmapMarker
						:position="geoAddress"
						@mousedown="startZoomIn"
						@mouseup="stopZoomIn"
						:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
					/>
				</GmapMap>
				<v-btn
					depressed
					color="#524D4D"
					:dark="!((sentEmailDiff != -1 && sentEmailDiff < 24) || !(conversation.schedule && conversation.schedule.status == 1)) && editPermission"
					block
					small
					class="mt-3"
					:disabled="((sentEmailDiff != -1 && sentEmailDiff < 24) || !(conversation.schedule && conversation.schedule.status == 1)) && editPermission"
					:loading="loading"
					@click="resendPaymentRequest()"
				>
					<v-icon small>mdi-email-outline</v-icon>
					<p class="mb-0 ml-2 caption">Reenviar Solicitud de Pago</p>
				</v-btn>
				<v-btn
					depressed
					color="#524D4D"
					:dark="conversation.schedule && conversation.schedule.status == 1 && editPermission"
					block
					small
					class="mt-3"
					:loading="loading"
					:disabled="!(conversation.schedule && conversation.schedule.status == 1) || !editPermission"
					@click="confirmScheduleManual()"
				>
					<v-icon small>mdi-email-outline</v-icon>
					<p class="mb-0 ml-2 caption">Confirmar Visita Técnica (Transferencia)</p>
				</v-btn>
				<v-divider class="my-4"></v-divider>
				<p class="font-weight-bold body-2 mb-0">Pagos Realizados</p>
				<div class="py-0 px-1 d-flex align-center justify-space-between" v-for="(payment, index) in conversation.schedule.payments" :key="'p-' + index">
					<div class="d-flex flex-column mx-1 mt-2" style="flex: 1 1 auto;">
						<p class="caption mb-1 justify-space-between d-flex">Fecha de pago: <b>{{ payment.payment_info?.created_at | toDateTime }}</b></p>
						<p class="caption mb-1 justify-space-between d-flex">Concepto: <b>{{payment.concept}}</b></p>
						<p class="caption mb-1 justify-space-between d-flex">Forma de Pago: <b>{{payment.payment_info?.method}}</b></p>
						<p class="caption mb-1 justify-space-between d-flex">Monto: <b>${{payment.payment_info?.amount | toThousandFilter}}</b></p>
					</div>
				</div>
				<v-divider class="my-4"></v-divider>
				<p class="font-weight-bold body-2 mb-0">Correos enviados</p>
				<div class="py-0 px-1 d-flex align-center justify-space-between" v-for="(email, index) in conversation.schedule.request_email" :key="'m-' + index">
					<div>
						<v-icon class="mr-2" small>mdi-email-outline</v-icon>
						{{ email.created_at | toDateTime }}
					</div>
					<v-chip
						class="ma-2"
						color="#959595"
						small
						dark
					>
						Solicitud de Pago/Confirmación
					</v-chip>
				</div>
				<div class="py-0 px-1 d-flex align-center justify-space-between"
					v-if="conversation.schedule && conversation.schedule.payments.length > 0">
					<div>
						<v-icon class="mr-2" small>mdi-email-outline</v-icon>
						{{ conversation.schedule.payments[0].payment_info?.created_at | toDateTime }}
					</div>
					<v-chip
						class="ma-2"
						color="success"
						small
						dark
					>
						Fecha de Pago Visita Técnica
					</v-chip>
				</div>
			</div>
			<div class="pa-2" v-else>
				No se agendó visita técnica
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import moment from 'moment'
import {sendScheduleEmail} from '@/helpers/api/conversations'
import {editCrmValue, assignScheduleToUser, editCrmProjectType} from '@/helpers/api/crm'
import {confirmSchedule, updateScheduleAddress, updateUserSchedule} from '@/helpers/api/schedule'

export default {
  data() {
    return {
			search: '',
			mapZoom: 7,
      zoomInterval: null,
      isZooming: false,
			itemsAddress: [],
			loadingAddress: false,
			loading: false,
			newValueCrm: null,
			editScheduleTitle: false,
			newScheduleTitle: null,
			editAddress: false,
			newAddress: {address: null, coordinates: null},
			cachingItems: false,
			scheduledUserId: null,
			changingScheduleUser: false,
			editValue: false,
			userData: {
				name: null,
				email: null,
				phone: null
			},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
		}
	},
  computed: {
		editPermission(){
			return this.$checkPermissions(['edit_crm']) || this.scheduledUserId == this.$store.state.user.userInfo.id
		},
    conversation() {
      return this.$store.state.crm.conversation
    },
		loadingUsers(){
			return this.$store.state.users.loadingUserList || this.changingScheduleUser
		},
		vtStatusText(){
			if(this.conversation.schedule.closure.length > 0){
				return 'Realizada'
			}
			else if(this.conversation.schedule.status == 1){
				return 'Esperando confirmación/pago'
			}
			return 'Confirmada'
		},
		vtStatus(){
			if(this.conversation.schedule && this.conversation.schedule.status == 2 && this.conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(this.conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		},
		geoAddress(){
			if(this.conversation && this.conversation.project_info && this.conversation.project_info?.coordinates){
				return {lat: this.conversation.project_info?.coordinates.coordinates[1], lng: this.conversation.project_info?.coordinates.coordinates[0]}
			}
			return null
		},
		sentEmailDiff(){
			if(this.conversation.schedule && this.conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = this.conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(this.conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
	},
	watch: {
    conversation(newValue) {
			console.log('conversation', newValue)
			if(newValue && newValue.user){
				this.userData = {
					name: newValue.user.name,
					email: newValue.user.email,
					phone: newValue.user.phone
				}
				this.scheduledUserId = newValue.schedule?.user_schedule?.user_id
			}
    }
	},
	mounted(){
		if(this.conversation && this.conversation.user){
			console.log('conversation', this.conversation)
			this.userData = {
				name: this.conversation.user.name,
				email: this.conversation.user.email,
				phone: this.conversation.user.phone
			}
			this.scheduledUserId = this.conversation.schedule?.user_schedule?.user_id
		}
	},
	methods: {
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		editCrmSchedule(){
			let address = null
			let coordinates = null
			if(this.conversation.project_info && this.conversation.project_info?.address?.address){
				address = this.conversation.project_info?.address?.address
				coordinates = this.conversation.project_info?.coordinates
			}
			this.$store.commit('schedule/setNewScheduleEditing', {
				title: 'Visita Técnica',
				user_name: this.userData.name,
				user_email: this.userData.email,
				user_phone: this.userData.phone,
				crm_id: this.$route.params.id,
				address: {address, coordinates},
				to_crm: true
			})
			this.$store.commit('schedule/setNewScheduleModal', true)
		},
		async changeUserAssignation(new_user_id){
			this.changingScheduleUser = true
			await assignScheduleToUser({new_user_id, schedule_id: this.conversation.schedule.user_schedule.id})
			this.$store.dispatch('crm/getConversation', this.conversation.id)
			this.changingScheduleUser = false
		},
		startEditTitle(){
			this.editScheduleTitle = !this.editScheduleTitle
			this.newScheduleTitle = this.conversation.schedule.user_schedule?.title
		},
		async sendEditScheduleTitle(){
			await updateUserSchedule({schedule_id: this.conversation.schedule.user_schedule.id, title: this.newScheduleTitle})
			this.editScheduleTitle = false
			this.$store.dispatch('crm/getConversation', this.conversation.id)
		},
		startEditAddress(){
			this.cachingItems = true
			this.editAddress = !this.editAddress
			let address = this.conversation.project_info?.address?.address ?? ''
			if(this.conversation.project_info?.project_district && this.conversation.project_info?.project_district.length > 1){
				address += ' ' + this.conversation.project_info?.project_district
			}
			this.itemsAddress = [{text: address.toUpperCase(), value: address.toUpperCase()}]
			this.newAddress.address = address.toUpperCase()
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.vtMap.$mapObject;
					if (map) {
						map.setCenter(this.geoAddress); // El mapa se vuelve a centrar en el mismo punto
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.itemsAddress = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.itemsAddress = []; // Limpiar los resultados en caso de error
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newAddress.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
						this.updateAddress(this.newAddress)
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			this.getPlaceDetails(value.value.place_id)
      this.newAddress.address = value.text;
    },
		async updateAddress(newAddress){
			this.editAddress = false
			const data = {
				...{schedule_id: this.conversation.schedule.id},
				...newAddress
			}
			await updateScheduleAddress(data)
			this.$store.dispatch('crm/getConversation', this.conversation.id)
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
		async sendEditValue(){
			await editCrmValue({conversation_id: this.conversation.id, new_value: this.newValueCrm})
			this.editValue = false
			this.$store.dispatch('crm/getConversation', this.conversation.id)
		},
		async changeProjectType(project_type_id){
			await editCrmProjectType({conversation_id: this.conversation.id, project_type_id})
			this.$store.dispatch('crm/getConversation', this.conversation.id)
		},
		startCloseAgenda(){
			if(this.conversation.schedule){
				this.$store.commit('vt/setAgendaClosureModal', this.conversation.schedule.id)
			}
		},
		async resendPaymentRequest(){
			if(!this.conversation.project_info?.address_value || this.conversation.project_info?.address_value == 0){
				const data = {
					message: 'Por favor asigna un valor a la visita técnica antes de enviar el correo.',
					title: 'Visita sin valor',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-exclamation',
					color: 'orange'
				}
				this.$store.dispatch('general/addMessage', data)
				return null
			}
			this.loading = true
			const schedule_payment = await sendScheduleEmail({schedule_id: this.conversation.schedule.id})
			if(schedule_payment.code == 200){
				const data = {
					message: 'El correo con la solicitud de pago ha sido enviado al usuario.',
					title: 'Correo enviado correctamente',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', this.conversation.id)
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al enviar el correo',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		},
		async confirmScheduleManual(){
			if(!this.conversation.project_info?.address_value || this.conversation.project_info?.address_value == 0){
				const data = {
					message: 'Por favor asigna un valor a la visita técnica antes de enviar el correo.',
					title: 'Visita sin valor',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-exclamation',
					color: 'orange'
				}
				this.$store.dispatch('general/addMessage', data)
				return null
			}
			this.loading = true
			const schedule_payment = await confirmSchedule({schedule_id: this.conversation.schedule.id, payment_method: 'Transferencia'})
			if(schedule_payment.code == 200){
				const data = {
					message: 'Se ha confirmado la visita técnica.',
					title: 'Agenda Confirmada',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', this.conversation.id)
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al confirmar',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		}
	}
}
</script>
<style scoped>
	.action-container{
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
</style>