<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.general.addressModal"
      max-width="600"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-start">
							<v-icon color="white" class="info-icon mr-2">mdi-information-symbol</v-icon>
							<p class="mb-0 font-weight-bold text-primary title">Dirección</p>
            </div>

            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4 mt-2">
								<v-col cols="12" class="py-0">
									<v-autocomplete
										v-model="addressInfo.address"
										:items="[...itemsAddress, ...[addressInfo.address]]"
										name="address"
										:search-input.sync="search"
										prepend-inner-icon="mdi-map-marker"
										label="Dirección"
										placeholder="Dirección"
										autocomplete="off"
										outlined
										dense
										hide-details
										:loading="loadingAddress"
										:filter="d => d"
										color="secondary"
										item-color="secondary"
										return-object
										class="mb-2"
										@change="change"
									></v-autocomplete>
                  <v-text-field
                    v-model="addressInfo.rol"
                    label="Rol"
                    placeholder="Ingrese rol"
                    outlined
										hide-details
										class="mb-2"
                    dense
                  ></v-text-field>
									<v-select
										v-model="addressInfo.state_id"
										:items="$store.state.general.states"
										:loading="$store.state.general.loadingStates"
										:menu-props="{ top: true, offsetY: true }"
										item-text="name"
										item-value="id"
										label="Seleccione Región"
										outlined
										dense
										hide-details
										class="mb-2"
										@change="onChangeState"
									></v-select>
									<v-select
										v-model="addressInfo.district_id"
										:items="$store.state.general.cities"
										:loading="$store.state.general.loadingCities"
										item-text="name"
										item-value="id"
										label="Seleccione Comuna"
										outlined
										dense
										hide-details
										class="mb-2"
										:disabled="!addressInfo.state_id"
									></v-select>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {updateAddress} from '@/helpers/api/general'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'AddressModal',
  data: () => ({
    validform: true,
		loadingData: false,
		itemsAddress: [],
		loadingAddress: false,
		search: '',
		autocompleteService: null,
		addressInfo: {
			address_id: null,
			address: null,
			state_id: null,
			district_id: null,
			rol: null

		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
		google: gmapApi
	},
	watch: {
    search(newSearch) {
			this.cachingItems = false
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.itemsAddress = [];
      }
    },
	},
  mounted(){
		this.$nextTick(() => {
			if(this.$store.state.general.addressModal && this.$store.state.general.addressModal.edit){
				this.addressInfo = this.$store.state.general.addressModal.edit
			}
			if(this.addressInfo.state_id){
				this.onChangeState(this.addressInfo.state_id)
			}
			this.$gmapApiPromiseLazy().then(() => {
				this.autocompleteService = new this.google.maps.places.AutocompleteService()
			})
		});
  },
  methods: {
    change(value) {
			this.getPlaceDetails(value.value.place_id)
      this.addressInfo.address = value.text
    },
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.itemsAddress = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.itemsAddress = [];
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.addressInfo.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
		onChangeState(state){
			this.$store.dispatch('general/getCities', state)
		},
		async sendForm(){
			this.loadingData = true
			await updateAddress(this.addressInfo)
			this.updateInfo()
			this.closeModal()
			this.loadingData = false
		},
		updateInfo(){
			if(this.$route.name == 'CRM'){
				this.$store.dispatch('crm/getConversations')
			}
			else if(this.$route.name == 'ArchivedCRM'){
				this.$store.dispatch('crm/getArchivedConversations')
			}
		},
    closeModal(){
      this.$store.commit('general/setAddressModal', null)
    }
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>