<template>
  <v-container fluid style="max-width: 1200px; margin-left: 0;">
		<v-row>
			<v-col cols="9">
				<div class="mb-3 d-flex justify-space-between align-center">
					<p class="mb-0 font-weight-bold">Creación Contrato</p>
				</div>
				<div class="contract-container" v-if="contract">
					<div class="py-2 px-4" v-for="(section, index) in contract.sections" :key="index">
						<div class="section-container rounded">
							<div class="pa-4">
								<p class="font-weight-bold">{{ sectionIndex(index) }} {{section.title}}</p>
								<div v-for="(section_content, indexB) in section.contents" :key="'a'+indexB">
									<div class="mb-4">
										<div class="section-content" v-html="section_content.content"></div>
									</div>
								</div>
							</div>
							<div class="pa-4">
								<v-divider class="mb-4"></v-divider>
								<v-btn
									depressed
									color="#959595"
									class="my-2 flex-grow-1"
									small
									block
									outlined
									v-if="!(addingContent == section.id)"
									@click="addNewContent(section)"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Contenido a Sección</p>
								</v-btn>
								<div v-else>
									<div class="mb-3 d-flex justify-space-between align-center">
										<div>
											<p class="mb-0 caption font-weight-bold">Agregar Contenido a Sección</p>
											<p class="mb-0 caption text--secondary">Puedes seleccionar una sección y un contenido preestablecido</p>
										</div>
										<div style="width: 30%">
											<v-select
												v-model="selectedCategory"
												:items="$store.state.contracts.companyContractCategories"
												:loading="$store.state.contracts.loadingCompanyContractCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="id"
												label="Seleccione Sección"
												class="custom-height-search custom-height-calendar mb-2 w-100"
												outlined
												dense
												return-object
												required
												v-if="!blockCategorySelect"
											></v-select>
											<v-select
												v-model="selectedContent"
												:items="$store.state.contracts.companyContracts"
												:loading="$store.state.contracts.loadingCompanyContracts"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="id"
												label="Seleccione Contenido"
												class="custom-height-search custom-height-calendar w-100"
												outlined
												dense
												return-object
												required
												clearable
											></v-select>
										</div>
									</div>
									<v-text-field
										v-model="newContent.title"
										label="Nombre"
										placeholder="Ingrese título identificador"
										outlined
										required
										dense
										:rules="requiredRule"
									></v-text-field>
									<vue2-tinymce-editor
										:inline="true"
										v-model="newContent.content"
										:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
									<div class="d-flex">
										<v-btn
											depressed
											color="green"
											class="my-2 mx-2 flex-grow-1"
											small
											dark
											@click="confirmContent()"
										>
											<v-icon>mdi-check-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Confirmar</p>
										</v-btn>
										<v-btn
											depressed
											color="#FF120B"
											class="my-2 mx-2"
											small
											outlined
											@click="closeNewContent()"
										>
											<v-icon>mdi-close-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Cancelar</p>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="py-2 px-4">
						<div class="section-container rounded">
							<div class="pa-4">
								<v-btn
									depressed
									color="#959595"
									class="my-2 flex-grow-1"
									small
									block
									outlined
									v-if="!addingSection"
									@click="addNewSection()"
								>
									<v-icon>mdi-plus</v-icon>
									<p class="mb-0 ml-2 caption">Agregar Sección</p>
								</v-btn>
								<div v-else>
									<div class="mb-3 d-flex justify-space-between align-center">
										<div>
											<p class="mb-0 caption font-weight-bold">Agregar una Sección</p>
											<p class="mb-0 caption text--secondary">Puedes seleccionar una sección preestablecida</p>
										</div>
										<div style="width: 30%">
											<v-select
												v-model="selectedCategory"
												:items="$store.state.contracts.companyContractCategories"
												:loading="$store.state.contracts.loadingCompanyContractCategories"
												:menu-props="{ top: true, offsetY: true }"
												item-text="title"
												item-value="id"
												label="Seleccione Sección"
												class="custom-height-search custom-height-calendar mb-2 w-100"
												outlined
												dense
												return-object
												required
												clearable
												@change="onChangeCategory"
											></v-select>
										</div>
									</div>
									<v-text-field
										v-model="newSection.title"
										label="Nombre"
										placeholder="Ingrese título de la sección"
										outlined
										required
										dense
										:rules="requiredRule"
									></v-text-field>
									<div class="d-flex">
										<v-btn
											depressed
											color="green"
											class="my-2 mx-2 flex-grow-1"
											small
											dark
											@click="confirmSection()"
										>
											<v-icon>mdi-check-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Confirmar</p>
										</v-btn>
										<v-btn
											depressed
											color="#FF120B"
											class="my-2 mx-2"
											small
											outlined
											@click="closeNewSection()"
										>
											<v-icon>mdi-close-circle-outline</v-icon>
											<p class="mb-0 ml-2 caption">Cancelar</p>
										</v-btn>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <draggable v-model="contractSections" @end="startEndDragEvent">
					<div>
						<v-btn
							depressed
							color="#959595"
							class="my-2 flex-grow-1 mx-1"
							small
							block
							outlined
							@click="addNewSection"
						>
							<v-icon>mdi-plus</v-icon>
							<p class="mb-0 ml-2 caption">Agregar Sección</p>
						</v-btn>
					</div>
				</draggable> -->
			</v-col>
			<v-col cols="3">
				<draggable
					v-model="$store.state.contracts.companyContractCategories"
					tag="tbody"
					item-key="id"
					@end="startEndDragEventCategory"
				>
					<tr v-for="(item) in $store.state.contracts.companyContractCategories" :key="item.id" style="cursor: pointer;">
						<td style="cursor: pointer;">{{ item.title }}</td>
						<td style="cursor: pointer;">{{ item.sections_count }}</td>
					</tr>
				</draggable>
			</v-col>
		</v-row>
  </v-container>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'
import { getContract, createContractSectionB, createContractSectionContent } from '@/helpers/api/contracts'

export default {
	name: 'ContractDetail',
  components: { Vue2TinymceEditor, draggable },
  data() {
    return {
			loading: false,
			search: '',
			selectedCategory: null,
			selectedContent: null,
			editIndex: null,
			blockCategorySelect: false,
			addingSection: false,
			newSection: {
				contract_id: null,
				title: null,
				order: 1
			},
			addingContent: false,
			newContent: {
				title: null,
				order: 1,
				content: null,
				contract_section_id: null
			},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			contract: null,
			contractSections: [
				{
					id: 1,
					title: 'Objeto del Contrato',
					parent_section_id: 1,
					section_contents: [{
						title: 'Section 1',
						parent_content_id: 1,
						content: '<p>Los servicios de arquitectura y especialidades del presente contrato contemplan el proyecto de dise&ntilde;o de una ampliaci&oacute;n de 80m&sup2; en un segundo piso de una vivienda existente de un piso de 60m&sup2; m&aacute;s la redistribuci&oacute;n de su planta original y la alteraci&oacute;n de esta (modificaciones a realizar o ya realizadas en la vivienda original), haciendo un total de 140m&sup2; m&aacute;ximos y sus gestiones pertinentes con la Direcci&oacute;n de Obras Municipales con el fin de obtener el permiso de edificaci&oacute;n por Art. 5.1.4 de la OGUC y Art. 166&deg; de la LGUC y luego obtener la recepci&oacute;n definitiva una vez sean terminadas las obras constructivas y certificadas las especialidades de agua potable, alcantarillado, electricidad, gas y otras. Esto se encuentra ubicado en calle 3 Poniente N&deg;2849, comuna de Macul, enmarc&aacute;ndose en las normativas pertinentes como Ordenanza General de Urbanismo y Construcci&oacute;n en adelante O.G.U.C., Ley General de Urbanismo y Construcci&oacute;n en adelante L.G.U.C., Plan Regulador Metropolitano de Santiago en adelante P.R.M.S. y Plan Regulador Comunal de Macul en adelante PRCM.</p>'
					}]
				}
			]
    };
  },
  computed: {
    concepts() {
      return this.$store.state.companies.concepts
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCategory(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractsConfig', {category_id: this.selectedCategory.id, start: 0, limit: 100})
			}
		},
		selectedContent(newValue){
			this.newContent.title = newValue ? newValue.title:null
			this.newContent.content = newValue ? newValue.content:''
			this.newContent.company_contract_section_id = newValue ? newValue.company_contract_section_id:null
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('contracts/getCompanyContractCategoriesConfig', {start: 0, limit: 100})
		}
		this.getContractInfo()
	},
  methods: {
		async getContractInfo(){
			if(this.$route.query.id){
				const contract = await getContract({contract_id: this.$route.query.id})
				if(contract.code == 200){
					this.contract = contract.contract
				}
			}
		},
		onChangeCategory(category){
			this.newSection.title = category ? category.title:null
			this.newSection.company_planning_cat_id = category ? category.id:null
		},
		sectionIndex(index){
			return '' +  this.$store.state.general.romanNumerals[index + 1] + '. ' + this.$store.state.general.ordinalsNumbers[index + 1] + ':'
		},
		closeNewSection(){
			this.newSection = {
				contract_id: null,
				title: null,
				order: 1
			}
			this.addingSection = false
		},
		addNewSection(){
			this.selectedCategory = null
			this.selectedContent = null
			this.newSection = {
				contract_id: this.contract.id,
				title: null,
				order: this.contractSections.length + 1
			}
			this.addingSection = true
		},
		closeNewContent(){
			this.newContent = {
				contract_section_id: null,
				title: null,
				order: 1,
				content: null
			}
			this.addingContent = false
		},
		addNewContent(section){
			this.selectedCategory = null
			this.selectedContent = null
			if(section.company_planning_cat_id){
				this.selectedCategory = {id: section.company_planning_cat_id, title: section.title}
				this.blockCategorySelect = true
			}
			const subsections_count = section.contents.length + 1
			this.newContent = {
				title: null,
				contract_section_id: section.id,
				order: subsections_count,
				content: null
			}
			this.addingContent = section.id
		},
		async confirmSection(){
			await createContractSectionB(this.newSection)
			this.getContractInfo()
			this.closeNewSection()
		},
		async confirmContent(){
			await createContractSectionContent(this.newContent)
			this.getContractInfo()
			this.closeNewContent()
		},
		startEndDragEventCategory: _debounce(function(ev){ this.endDragEventCategory(ev) }, 800),
		async endDragEventCategory(ev){
			console.log('DRAG', ev)
			if (typeof ev.newIndex === 'number') {
				console.log('DRAG')
			}
		},
  },
};
</script>

<style scoped>
.contract-container{
	background-color: white;
	/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
.section-container{
	border: 1px solid  #d3d3d3;
}
/deep/ .section-content p{
	margin-bottom: 0;
}
</style>