// Importa Vue y Vuex
import Vue from 'vue'
import Vuex from 'vuex'

// Importa los módulos
import userStore from './UserStore.js'
import menuStore from './MenuStore.js'
import generalStore from './GeneralStore.js'
import usersStore from './UsersStore.js'
import companiesStore from './CompaniesStore.js'
import CRMStore from './CRMStore.js'
import ChatBotStore from './ChatBotStore.js'
import DashboardStore from './DashboardStore.js'
import VTStore from './VTStore.js'
import NotificationsStore from './NotificationsStore.js'
import ScheduleStore from './ScheduleStore.js'
import PreProjectsStore from './PreProjectsStore.js'
import ChatStore from './ChatStore.js'
import BudgetStore from './BudgetStore.js'
import ProjectsStore from './ProjectsStore.js'
import ContractsStore from './ContractsStore.js'

// Usa Vuex en Vue
Vue.use(Vuex)

// Crea una instancia de Vuex.Store con los módulos
const store = new Vuex.Store({
  modules: {
    user: userStore,
    menu: menuStore,
    general: generalStore,
    users: usersStore,
    companies: companiesStore,
		crm: CRMStore,
		chatbot: ChatBotStore,
		dashboard: DashboardStore,
		vt: VTStore,
		notifications: NotificationsStore,
		schedule: ScheduleStore,
		preprojects: PreProjectsStore,
		chats: ChatStore,
		budget: BudgetStore,
		projects: ProjectsStore,
		contracts: ContractsStore,
  }
})

// Exporta el store
export default store