<template>
  <v-container fluid class="crm-conversation">
    <v-row class="mb-4" v-if="$store.state.crm.loadingConversation">
      <v-col cols="12">
				<div class="d-flex flex-column justify-center align-center">
					<v-progress-circular
						:size="50"
						:width="5"
						color="rgba(0,0,0,0.2)"
						class="mb-3"
						indeterminate
					></v-progress-circular>
					Cargando Información
				</div>
      </v-col>
    </v-row>
		<v-row class="mb-4" v-else-if="conversation">
			<!-- <v-col col="12">
				<v-tabs
					v-model="tab"
					background-color="transparent"
					grow
				>
					<v-tab
						v-for="item in items"
						:key="item"
					>
						{{ item }}
					</v-tab>
				</v-tabs>

				<v-tabs-items v-model="tab">
					<v-tab-item
						v-for="item in items"
						:key="item"
					>
						<v-card
							color="basil"
							flat
						>
							<v-card-text>asdasda</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-col> -->
      <v-col cols="12" md="5">
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center justify-space-between" style="width: 100%;">
								<div class="d-flex align-center">
									<p class="font-weight-bold body-2 px-1 mb-0 mr-2">Datos Usuario</p>
									<v-chip
										x-small
										dark
										color="green"
										v-if="userInfo && userInfo.account"
									>
										<v-icon x-small dark>mdi-account-outline</v-icon>
										<p class="mb-0 ml-2">Usuario con Cuenta</p>
									</v-chip>
								</div>
								<v-btn
									icon
									color="#524D4D"
									@click="editUser = !editUser"
									:disabled="!editPermission"
								>
									<v-icon>{{editUser ? 'mdi-close':'mdi-account-box-edit-outline'}}</v-icon>
								</v-btn>
							</div>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="py-2" v-if="userInfo || editUser">
							<div v-if="editUser">
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Nombre: </span>
                  <v-text-field
                    v-model="userData.name"
                    outlined
                    required
                    dense
										hide-details
										style="max-width: 75%"
                  ></v-text-field>
								</div>
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Email: </span>
                  <v-text-field
                    v-model="userData.email"
                    outlined
                    required
                    dense
										hide-details
										style="max-width: 75%"
                  ></v-text-field>
								</div>
								<div class="d-flex justify-space-between align-center mb-1">
									<span class="mr-5">Teléfono: </span>
                  <v-text-field
                    v-model="userData.phone"
                    outlined
                    required
                    dense
										style="max-width: 75%"
                    :rules="phoneRule"
										maxlength="11"
										@keypress="isNumberKey"
                  ></v-text-field>
								</div>
								<v-btn
									depressed
									color="#524D4D"
									dark
									block
									small
									class="mt-3"
									:loading="loadingEditUser"
									@click="editUserData()"
								>
									<v-icon small>mdi-account-edit</v-icon>
									<p class="mb-0 ml-2 caption">Confirmar Cambios</p>
								</v-btn>
							</div>
							<div v-else>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Nombre</div><span>:</span> <b class="text-end" style="flex: 1;">{{userInfo.name}} {{userInfo.lastname}}</b>
									<div class="action-container"></div>
								</div>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Email</div><span>:</span> <b class="text-end" style="flex: 1;">{{userInfo.email}}</b>
									<div class="action-container"></div>
								</div>
								<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
									<div style="width: 150px">Teléfono</div><span>:</span> <b class="text-end" style="flex: 1;">{{userInfo.phone}}</b>
									<div class="action-container"></div>
								</div>
							</div>
						</div>
						<div class="pa-2" v-else>
							No se logró obtener datos de usuario
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 px-1 mb-0 mr-2">Datos Generales</p>
								<v-chip
									color="#524D4D"
									label
									outlined
									x-small
									class="px-1"
								>
									<b>{{ conversation.mode == 1 ? 'Manual':'Automático' }}</b>
								</v-chip>
							</div>
						</div>
						<v-divider class="my-2"></v-divider>
						<div class="px-0 py-0">
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
								<div style="width: 150px">ID CRM/Solicitud</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.id}}</b>
								<div class="action-container"></div>
							</div>
							<div class="d-flex justify-space-between mb-1 px-1 item-background-hover">
								<div style="width: 150px">Fecha de creación prospecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{conversation.created_at | toDateTime}} hrs.</b>
								<div class="action-container"></div>
							</div>

							<div class="d-flex justify-space-between align-center" v-if="editProjectDetail">
								<v-text-field
									v-model="newProjectDetail"
									label="Proyecto"
									placeholder="Detalle proyecto"
									outlined
									required
									dense
									hide-details=""
									maxlength="255"
									class="my-1"
								>
									<template v-slot:append-outer>
										<v-btn
											icon
											color="error"
											@click="editProjectDetail = false"
											:disabled="!editPermission"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-btn
											icon
											color="warning"
											@click="sendEditProjectDetail"
											:disabled="!editPermission"
										>
											<v-icon>mdi-check</v-icon>
										</v-btn>
									</template>
								</v-text-field>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover" v-else>
								<p class="d-flex mb-0 justify-space-between" style="width: 100%"><span>Detalle Proyecto:</span> <b class="ml-1">{{conversation.project_info?.project_detail}}</b></p>
								<div class="action-container">
									<v-btn
										icon
										color="warning"
										x-small
										@click="startEditProjectDetail"
										:disabled="!editPermission"
									>
										<v-icon>mdi-pencil-outline</v-icon>
									</v-btn>
								</div>
							</div>
						</div>
          </v-card-text>
        </v-card>
				<VTSection />
      </v-col>
      <v-col cols="12" md="7">
				<v-expansion-panels class="mb-4" v-model="activePanel" multiple>
					<!-- <v-expansion-panel :disabled="!editPermission">
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div class="d-flex justify-space-between">
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Preparar Nuevo Informe</p>
								<v-btn
									depressed
									color="#524D4D"
									x-small
									outlined
									:loading="loading"
									@click.stop="openBudgetModal"
								>
									<v-icon small>mdi-table-cog</v-icon>
									<p class="mb-0 ml-2 caption">Gestionar Presupuesto</p>
								</v-btn>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-divider class="my-1"></v-divider>
							<div class="pa-2" v-if="conversation.schedule">
								<ReportsHandler :item="{...conversation, item_type: 'crm'}"/>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel> -->
					<v-expansion-panel>
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div>
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Informes</p>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list dense class="py-0 transparent" v-if="conversation.schedule">
								<v-list-item-group v-model="selectedReport">
									<v-list-item
										v-for="(report, index) in conversation.schedule.reports"
										:key="index"
										dense
										:value="report"
									>
										<v-list-item-title dense>
											<div class="d-flex align-center">
												<div class="d-flex justify-space-between align-center" style="width: 100%">
													<div class="d-flex justify-space-between align-center">

														<div class="d-flex align-center mr-4">
															<v-icon small class="mr-2">mdi-file-document-outline</v-icon>
															<p class="caption mb-0">Informe - <b>{{report.created_at | toDateTime}}</b></p>
														</div>
														<v-btn
															depressed
															color="#959595"
															x-small
															outlined
															class="my-0"
														>
															<v-icon small>mdi-eye-outline</v-icon>
															<p class="mb-0 ml-1 caption">Visualizar</p>
														</v-btn>
													</div>
												</div>
											</div>
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
							<div v-if="selectedReport">
								<v-divider class="mt-6 mb-2"></v-divider>
								<div class="d-flex justify-space-between">
									<p class="caption mb-0">Contenido Informe</p>
									<div class="d-flex caption align-center">
										<v-icon small class="mr-2">mdi-calendar</v-icon> {{selectedReport.created_at | toDateTime}}
									</div>
								</div>
								<v-divider class="my-2"></v-divider>
								<div
									v-html="selectedReport.report_html"></div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header class="px-4 py-2" style="min-height: 0">
							<div>
								<p class="font-weight-bold body-2 mb-0" style="opacity: 0.7">Detalle Conversación</p>
							</div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="pa-2">
								<div>
									<div class="messages-container" v-if="conversationMessages.length > 0">
										<div
											v-for="(message, index) in conversationMessages"
											:key="'msg-' + index"
											class="single-message" :class="message.role == 1 ? 'assistant':''"
											v-html="$options.filters.addTargetsToLinksMessages(message.message)">
										</div>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
        <!-- <v-card>
          <v-card-text class="py-2">
						<div>
							<p class="font-weight-bold body-2 mb-0">Detalle Conversación</p>
						</div>
						<v-divider class="my-1"></v-divider>
						<div class="pa-2">
							<div>
								<div class="messages-container" v-if="conversationMessages.length > 0">
									<div
										v-for="(message, index) in conversationMessages"
										:key="index"
										class="single-message" :class="message.role == 1 ? 'assistant':''"
										v-html="$options.filters.addTargetsToLinksMessages(message.message)">
									</div>
								</div>
							</div>
						</div>
          </v-card-text>
        </v-card> -->
				<BudgetSectionModal />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import {sendScheduleEmail, editConversationUser} from '@/helpers/api/conversations'
import {editCrmValue, assignScheduleToUser, editCrmProjectDetail, editCrmProjectType} from '@/helpers/api/crm'
import {confirmSchedule, sendReportSchedule, updateScheduleAddress, updateUserSchedule} from '@/helpers/api/schedule'
import {deleteVTReportTemplate} from '@/helpers/api/vts'
import {fileUploadToReport} from '@/helpers/api/files'
import { gmapApi } from 'vue2-google-maps'
import VTSection from '@/components/Views/CRM/Sections/VTSection'
import BudgetSectionModal from '@/components/Views/CRM/Sections/BudgetSectionModal'
// import ReportsHandler from '@/components/Helpers/Reports/ReportsHandler'

export default {
	name: 'Conversation',
	components: {BudgetSectionModal, VTSection},
  data() {
    return {
			mapZoom: 7,
			selectedReport: null,
      zoomInterval: null,
      isZooming: false,
			itemsAddress: [],
			loadingAddress: false,
			search: '',
			autocompleteService: null,
			tab: null,
			items: ['Información', 'Conversación', 'Correos Enviados', 'Informes'],
			reportTemplate: null,
			loading: false,
			editValue: false,
			editProjectDetail: false,
			editScheduleTitle: false,
			editUser: false,
			editAddress: false,
			newValueCrm: null,
			newScheduleTitle: null,
			newProjectDetail: null,
			loadingEditUser: false,
			reportEditor: '',
			menuTemplates: false,
			cc_report: null,
			scheduledUserId: null,
			changingScheduleUser: false,
			subject: 'Informe Visita Técnica',
			report_title: 'Informe Visita Técnica Revisión Normativa',
			activePanel: 2,
			templateName: null,
			userData: {
				name: null,
				email: null,
				phone: null
			},
			cachingItems: false,
			newAddress: {address: null, coordinates: null},
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
			phoneRule: [
				v => !!v || 'Campo requerido',
				v => /^569\d{8}$/.test(v) || 'Debe tener 11 dígitos y empezar con 569',
			],
    };
  },
  computed: {
		google: gmapApi,
		editPermission(){
			return this.$checkPermissions(['edit_crm']) || this.scheduledUserId == this.$store.state.user.userInfo.id
		},
		scheduledUserName(){
			const user = this.$store.state.users.usersList.find((user) => {
				return user.id == this.scheduledUserId
			})
			if(!user){ return null }
			return user.profile.name + ' ' + user.profile.lastname
		},
		loadingUsers(){
			return this.$store.state.users.loadingUserList || this.changingScheduleUser
		},
		vtStatusText(){
			if(this.conversation.schedule.closure.length > 0){
				return 'Realizada'
			}
			else if(this.conversation.schedule.status == 1){
				return 'Esperando confirmación/pago'
			}
			return 'Confirmada'
		},
		userInfo(){
			if(this.$store.state.crm.conversation?.user){
				const user = this.$store.state.crm.conversation?.user
				if(user && user.user && user.user.profile){
					return {
						name: user.user.profile.name,
						lastname: user.user.profile.lastname,
						email: user.user.email,
						phone: user.user.profile.phone,
						account: true
					}
				}
				return {
					name: user.name,
					lastname: '',
					email: user.email,
					phone: user.phone,
				}
			}
			return null
		},
    conversation() {
      return this.$store.state.crm.conversation
    },
    reportTemplates() {
      return this.$store.state.vt.vtReportTemplates
    },
		conversationMessages(){
      return this.$store.state.crm.conversation.messages
		},
		geoAddress(){
			if(this.conversation && this.conversation.project_info && this.conversation.project_info?.coordinates){
				return {lat: this.conversation.project_info?.coordinates.coordinates[1], lng: this.conversation.project_info?.coordinates.coordinates[0]}
			}
			return null
		},
		sentEmailDiff(){
			if(this.conversation.schedule && this.conversation.schedule.request_email.length > 0){
				const current = moment()
				const startTime = this.conversation.schedule.request_email[0].created_at
				var duration = moment.duration(current.diff(startTime))
				var hours = duration.asHours()
				return hours
			}
			else if(this.conversation.schedule.request_email.length == 0){
				return -1
			}
			return null
		},
		vtStatus(){
			if(this.conversation.schedule && this.conversation.schedule.status == 2 && this.conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(this.conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		}
  },
	watch: {
    userInfo(newValue) {
			if(newValue){
				this.userData = {
					name: newValue.name,
					email: newValue.email,
					phone: newValue.phone
				}
			}
    },
    conversation(newValue) {
			if(newValue){
				this.scheduledUserId = newValue.schedule?.user_schedule?.user_id
			}
    },
    search(newSearch) {
			this.cachingItems = false
      if (newSearch) {
        this.googleSearch(newSearch);
      } else {
        this.itemsAddress = []; // Limpiar los resultados si no hay búsqueda
      }
    },
		activePanel(newValue){
			if(newValue >= 0){
				this.$store.dispatch('vt/getVtReportTemplates')
			}
		},
		reportTemplates(newValue){
			if(newValue && this.reportTemplate){
				const reportSelected = newValue.find((report) => {
					return this.reportTemplate.id == report.id
				})
				if(reportSelected){
					this.onChangeReportTemplate(reportSelected)
				}
			}
		}
	},
	mounted(){
		try {
			this.$store.dispatch('users/getUserFromCompany')
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
			this.$nextTick(() => {
				this.$gmapApiPromiseLazy().then(() => {
					this.autocompleteService = new this.google.maps.places.AutocompleteService()
				})
			});
		} catch (error) {
			console.log('Error: ', error)
		}
	},
	beforeDestroy(){
		this.$store.commit('crm/setConversation', null)
	},
  methods: {
		openBudgetModal(){
			const data = {
				status: true,
				item: {item_type: 'crm', item_id: this.conversation.id},
				budget: this.conversation.budget
			}
			this.$store.commit('general/setNewBudgetModal', data)
		},
		async changeProjectType(project_type_id){
			await editCrmProjectType({conversation_id: this.conversation.id, project_type_id})
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		startEditProjectDetail(){
			this.editProjectDetail = !this.editProjectDetail
			this.newProjectDetail = this.conversation.project_info?.project_detail
		},
		startEditTitle(){
			this.editScheduleTitle = !this.editScheduleTitle
			this.newScheduleTitle = this.conversation.schedule.user_schedule?.title
		},
		async sendEditProjectDetail(){
			await editCrmProjectDetail({conversation_id: this.conversation.id, project_detail: this.newProjectDetail})
			this.editProjectDetail = false
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		async sendEditScheduleTitle(){
			await updateUserSchedule({schedule_id: this.conversation.schedule.user_schedule.id, title: this.newScheduleTitle})
			this.editScheduleTitle = false
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		startEditAddress(){
			this.cachingItems = true
			this.editAddress = !this.editAddress
			let address = this.conversation.project_info?.address?.address ?? ''
			if(this.conversation.project_info?.project_district && this.conversation.project_info?.project_district.length > 1){
				address += ' ' + this.conversation.project_info?.project_district
			}
			this.itemsAddress = [{text: address.toUpperCase(), value: address.toUpperCase()}]
			this.newAddress.address = address.toUpperCase()
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
		isNumberKey(event) {
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode < 48 || charCode > 57) {
				event.preventDefault();
			}
		},
		async changeUserAssignation(new_user_id){
			this.changingScheduleUser = true
			await assignScheduleToUser({new_user_id, schedule_id: this.conversation.schedule.user_schedule.id})
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
			this.changingScheduleUser = false
		},
		async sendEditValue(){
			await editCrmValue({conversation_id: this.$route.params.id, new_value: this.newValueCrm})
			this.editValue = false
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		editCrmSchedule(){
			let address = null
			let coordinates = null
			if(this.conversation.project_info && this.conversation.project_info?.address?.address){
				address = this.conversation.project_info?.address?.address
				coordinates = this.conversation.project_info?.coordinates
			}
			this.$store.commit('schedule/setNewScheduleEditing', {
				title: 'Visita Técnica',
				user_name: this.userData.name,
				user_email: this.userData.email,
				user_phone: this.userData.phone,
				crm_id: this.$route.params.id,
				address: {address, coordinates},
				to_crm: true
			})
			this.$store.commit('schedule/setNewScheduleModal', true)
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.vtMap.$mapObject;
					if (map) {
						map.setCenter(this.geoAddress); // El mapa se vuelve a centrar en el mismo punto
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    googleSearch(query) {
      const options = {
        input: query,
				componentRestrictions: { country: 'CL' }
      };
      this.autocompleteService.getPlacePredictions(options, (predictions, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          this.itemsAddress = predictions.map(prediction => ({
            text: prediction.description,
            value: prediction 
          }));
        } else {
          console.error('Error fetching predictions: ', status);
          this.itemsAddress = []; // Limpiar los resultados en caso de error
        }
      });
    },
		async getPlaceDetails(placeId) {
			if (typeof this.google !== 'undefined' && this.google.maps) {
				const service = new this.google.maps.places.PlacesService(document.createElement('div'));
				
				service.getDetails({ placeId }, (place, status) => {
					if (status === this.google.maps.places.PlacesServiceStatus.OK) {
						const { geometry } = place;
						this.newAddress.coordinates = {
							lat: geometry.location.lat(),
							lng: geometry.location.lng()
						}
						this.updateAddress(this.newAddress)
					}
				});
			} else {
				console.error('Google Maps API no está cargada');
			}
		},
    change(value) {
			console.log('value', value)
			this.getPlaceDetails(value.value.place_id)
      this.newAddress.address = value.text;
    },
		async updateAddress(newAddress){
			this.editAddress = false
			const data = {
				...{schedule_id: this.conversation.schedule.id},
				...newAddress
			}
			await updateScheduleAddress(data)
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		uploadImageFromTiny(file, succes, failure){
			const formData = new FormData();
			formData.append('file', file.blob());

			fileUploadToReport(formData)
				.then((uploaded_file) => {
					if (uploaded_file.code === 200) {
						console.log(uploaded_file.url);
						succes(uploaded_file.url);
					} else {
						failure(new Error(`Error: ${uploaded_file.code}`));
					}
				})
				.catch((error) => {
					failure(error);
				});
		},
		filePickerTiny(callback) {
			const input = this.$refs.fileInput;
			input.onchange = async (event) => {
				const file = event.target.files[0];
				const formData = new FormData();
				formData.append('file', file);
				try {
					const uploaded_file = await fileUploadToReport(formData);
					if (uploaded_file.code === 200) {
						const url = uploaded_file.url;
						callback(url, { alt: file.name });
					} else {
						console.error('Error al subir la imagen:', uploaded_file.message);
					}
				} catch (error) {
					console.error('Error en la subida de la imagen:', error);
				}
			};

			// Simular un clic en el input para abrir el selector de archivos
			input.click();
		},
		setupTiny(editor){
			editor.on('paste', (e) => {
				const clipboard = e.clipboardData || e.originalEvent.clipboardData;
				console.log('clipboard', clipboard);
				
				if (clipboard && clipboard.items) {
					let pastedContent = '';
					let imageCounter = 0;

					const handleItem = (item) => {
							return new Promise((resolve) => {
									if (item.type.indexOf('image') !== -1) {
											// Procesar imágenes como archivos
											const file = item.getAsFile();
											const formData = new FormData();
											formData.append('file', file);

											fileUploadToReport(formData)
													.then((uploaded_file) => {
															if (uploaded_file.code == 200) {
																	const url = uploaded_file.url;
																	pastedContent += `<img src="${url}" />`;
															}
															resolve();
													});
									} else if (item.type === 'text/html') {
											// Procesar HTML (posibles imágenes en base64)
											item.getAsString((htmlContent) => {
													const parser = new DOMParser();
													const doc = parser.parseFromString(htmlContent, 'text/html');
													const images = doc.querySelectorAll('img');

													if (images.length > 0) {
														// Si hay imágenes en base64, subirlas al servidor
														images.forEach((img, index) => {
															if (img.src.startsWith('data:image')) {
																const base64Data = img.src.split(',')[1];
																const contentType = img.src.split(';')[0].split(':')[1];

																const byteCharacters = atob(base64Data);
																const byteNumbers = new Array(byteCharacters.length);
																for (let i = 0; i < byteCharacters.length; i++) {
																	byteNumbers[i] = byteCharacters.charCodeAt(i);
																}

																const byteArray = new Uint8Array(byteNumbers);
																const blob = new Blob([byteArray], { type: contentType });
																const file = new File([blob], `image${imageCounter}.png`, { type: contentType });

																const formData = new FormData();
																formData.append('file', file);
																imageCounter++;

																// Subir imagen al servidor
																fileUploadToReport(formData)
																	.then((uploaded_file) => {
																		if (uploaded_file.code == 200) {
																			img.src = uploaded_file.url;
																		}
																		if (index === images.length - 1) {
																			pastedContent += doc.body.innerHTML;
																			resolve();
																		}
																	});
															} else {
																// Si no es base64, insertar la imagen directamente
																pastedContent += doc.body.innerHTML;
																resolve();
															}
														});
													} else {
															// No hay imágenes, solo insertar el HTML pegado
															pastedContent += htmlContent;
															resolve();
													}
											});
									} else if (item.type === 'text/plain') {
											// Procesar texto sin formato
											item.getAsString((plainText) => {
													pastedContent += plainText;
													resolve();
											});
									} else {
											resolve();
									}
							});
					};

					const processItemsSequentially = async () => {
							for (let i = 0; i < clipboard.items.length; i++) {
									await handleItem(clipboard.items[i]);
							}
							// Una vez procesados todos los ítems, insertar el contenido en el editor
							editor.execCommand('mceInsertContent', false, pastedContent);
					};

					// Procesar los ítems de forma secuencial
					processItemsSequentially();
				}
			});
		},
		startCloseAgenda(){
			if(this.conversation.schedule){
				this.$store.commit('vt/setAgendaClosureModal', this.conversation.schedule.id)
			}
		},
		async editReportTemplate(reportTemplate, duplcating = false){
			const rt = {...reportTemplate}
			if(duplcating){
				rt.name = rt.name + ' (Copia)'
				delete rt.id
			}
			this.$store.commit('vt/setNewReportTemplateEditing', rt)
      this.$store.commit('vt/setNewReportTemplateModal', true)
		},
		cleanReportEditor(){
			this.reportEditor = ''
		},
		onChangeReportTemplate(report_template){
			this.report_title = report_template.name
			this.reportEditor = report_template.content
		},
		async deleteVTReportTemplates(template_id){
			await deleteVTReportTemplate({template_id})
			this.$store.dispatch('vt/getVtReportTemplates')
		},
		async sendScheduleReport(){
			const recipients = this.cc_report ? this.cc_report.split(",").map(email => email.trim()):null
			const data = {
				schedule_id: this.conversation.schedule.id,
				report: this.reportEditor,
				recipients,
				subject: this.subject,
				report_title: this.report_title
			}
			await sendReportSchedule(data)
			this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
		},
		async editUserData(){
			this.loadingEditUser = true
			const data = {...this.userData, ...{conversation_id: this.conversation.id}}
			try {
				await editConversationUser(data)
				this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
				this.editUser = false
			} catch (error) {
				if(error.data){
					error.data.forEach(message => {
						const messageData = {
							message: message.message,
							title: 'Error',
							type: 'danger',
							icon: 'mdi-close-octagon',
							color: '#F4516C',
						}
						this.setMessage(messageData)
					})
				}
			}
			this.loadingEditUser = false
		},
		async resendPaymentRequest(){
			if(!this.conversation.project_info?.address_value || this.conversation.project_info?.address_value == 0){
				const data = {
					message: 'Por favor asigna un valor a la visita técnica antes de enviar el correo.',
					title: 'Visita sin valor',
					created: new Date(),
					type: 'warning',
					icon: 'mdi-exclamation',
					color: 'orange'
				}
				this.$store.dispatch('general/addMessage', data)
				return null
			}
			this.loading = true
			const schedule_payment = await sendScheduleEmail({schedule_id: this.conversation.schedule.id})
			if(schedule_payment.code == 200){
				const data = {
					message: 'El correo con la solicitud de pago ha sido enviado al usuario.',
					title: 'Correo enviado correctamente',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al enviar el correo',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		},
		async confirmScheduleManual(){
			this.loading = true
			const schedule_payment = await confirmSchedule({schedule_id: this.conversation.schedule.id, payment_method: 'Transferencia'})
			if(schedule_payment.code == 200){
				const data = {
					message: 'Se ha confirmado la visita técnica.',
					title: 'Agenda Confirmada',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.dispatch('general/addMessage', data)
				this.$store.dispatch('crm/getConversation', {conversation_id: this.$route.params.id})
			}
			else{
				const data = {
					message: 'Por favor intenta nuevamente.',
					title: 'Error al confirmar',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.$store.dispatch('general/addMessage', data)
			}
			this.loading = false
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  },
};
</script>

<style scoped>
	.messages-container{
		display: flex;
		flex-direction: column;
		padding: 10px;
		flex: 1;
		border-radius: 10px;
		height: 400px;
		overflow-y: scroll;
	}
	.single-message{
		background-color: rgb(217, 239, 217);
		border: 1px solid rgb(197, 232, 197);
		padding: 4px 8px;
		max-width: 80%;
		border-radius: 10px;
		font-size: 14px;
		margin-bottom: 8px;
		text-transform: none;
	}
	.single-message.assistant{
		background-color: rgb(213, 211, 211);
		border: 1px solid rgb(193, 191, 191);
		align-self: flex-end;
	}
	.template-list-item:hover{
		background-color: rgba(0,0,0,0.2);
	}
	.menu-bg-white{background: white;}
	.item-background-hover:hover{
		background-color: rgba(0,0,0,0.1);
	}
	.action-container{
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
</style>
<style>
	.crm-conversation .single-message p{
		margin-bottom: 0;
	}
</style>