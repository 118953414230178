	<template>
	<v-container fluid align-start fill-height>
		<div style="width: 100%;">
			<v-row class="pb-4 mt-0 px-2" style="max-width: 1200px;">
				<v-col cols="6" class="py-0">
					<div class="mb-3 d-flex justify-space-between align-center">
						<p class="mb-0 caption font-weight-bold">Proyecto</p>
						<v-btn
							depressed
							color="#959595"
							x-small
							outlined
							class="my-0"
							@click="startNewProject"
						>
							<v-icon small>mdi-plus</v-icon>
							<p class="mb-0 ml-1 caption">Nuevo</p>
						</v-btn>
					</div>
					<div class="d-flex flex-column fill-height">
						<v-select
							v-model="selectedProject"
							:items="$store.state.projects.companyProjects"
							:loading="$store.state.projects.loadingCompanyProjects"
							:menu-props="{ top: true, offsetY: true }"
							item-text="name"
							item-value="id"
							label="Proyecto"
							class="custom-height-search custom-height-calendar flex-grow-0"
							outlined
							dense
							required
							return-object
						>
							<template v-slot:item="data">
								<v-list-item-content>
									<v-list-item-title>
										<div class="d-flex">
											<p class="mb-0" style="width: 200px">
												<span class="font-weight-bold">{{data.item.name}}</span><br/>
												<span class="light-blue-text">{{data.item.info.address?.district?.name}}</span>
											</p>
											<span>-</span>
											<p class="flex-grow-1 text-right mb-0">
												<span class="font-weight-bold">{{data.item.client?.profile?.name}} {{data.item.client?.profile?.lastname}}</span><br/>
												<span class="light-blue-text">{{data.item.client?.email}}</span>
											</p>
										</div>
									</v-list-item-title>
								</v-list-item-content>
							</template>
						</v-select>
						<v-divider class="my-2"></v-divider>
						<p class="mb-2 caption font-weight-bold">Proyecto Seleccionado</p>
						<div v-if="selectedProject">
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Zona Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{selectedProject.info?.zone?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Tipo Proyecto</div><span>:</span> <b class="text-end" style="flex: 1;">{{selectedProject.info?.project_type?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Cliente</div><span>:</span> <b class="text-end" style="flex: 1;">{{selectedProject.client.profile.name}} {{selectedProject.client.profile.lastname}}</b></div>
								<div class="action-container">
									<v-btn
										depressed
										color="warning"
										x-small
										icon
										@click.stop="goToPreProject"
									>
										<v-icon small>mdi-eye-outline</v-icon>
									</v-btn>
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Dirección</div><span>:</span> <b class="text-end" style="flex: 1;">{{selectedProject.info.address?.address}}</b></div>
								<div class="action-container">
								</div>
							</div>
							<div class="d-flex justify-space-between align-center mb-1 px-1 item-background-hover">
								<div class="d-flex justify-space-between caption" style="width: 100%"><div style="width: 100px">Municipio</div><span>:</span> <b class="text-end" style="flex: 1;">{{selectedProject.info.address?.district?.name}}</b></div>
								<div class="action-container">
								</div>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="4" class="py-0">
					<p class="mb-2 font-weight-bold caption">Requiere Tramitación</p>
					<div class="d-flex align-center justify-space-between">
						<p class="mb-0 font-weight-bold caption">Seremi Minvu</p>
						<v-switch
							class="mt-0"
							color="#FF120B"
							dense
							hide-details
							v-model="hasSeremi"
							:disabled="!selectedProject"
							@change="toggleSeremi"
						></v-switch>
					</div>
					<div class="d-flex align-center justify-space-between">
						<p class="mb-0 font-weight-bold caption">Consejo Monumentos Nacionales (CMN)</p>
						<v-switch
							class="mt-0"
							color="#FF120B"
							dense
							hide-details
							v-model="hasCMN"
							:disabled="!selectedProject"
							@change="toggleCMN"
						></v-switch>
					</div>
				</v-col>
				<v-col cols="2" class="py-0">
					<div class="mb-3 d-flex justify-space-between align-center">
						<p class="mb-0 caption font-weight-bold">Etapas Proyectos (Empresa)</p>
					</div>
					<div class="d-flex flex-column fill-height">

						<v-list dense class="py-0 transparent"
							:disabled="!selectedProject && loadingCategoryOrder">
							<v-list-item-group>
								<v-list-item
									v-for="(category, index) in categories"
									:key="index"
									dense
									:value="category"
									@click="setCategoryToProject(category)"
								>
									<v-list-item-title dense>
										<div class="d-flex align-center">
											<div class="d-flex justify-space-between align-center" style="width: 100%">
												<div class="d-flex justify-space-between align-center">
													<p class="caption mb-0 mr-2">{{ category.name }}</p>
													<v-tooltip bottom v-if="category.description">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-bind="attrs"
																v-on="on"
																x-small>mdi-information</v-icon>
														</template>
														<span>{{ category.description }}</span>
													</v-tooltip>
												</div>
												
												<!-- <v-btn
													depressed
													color="green"
													small
													class="my-0"
													icon
													@click.stop="startCategoryToProject(category)"
												>
													<v-icon small>mdi-plus-box-outline</v-icon>
												</v-btn> -->
											</div>
										</div>
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</div>
				</v-col>
			</v-row>
			<v-row class="py-2 my-1 px-2">
				<v-col cols="12">
					<v-divider class="mt-2 mb-4"></v-divider>
					<div class="mb-3 d-flex align-center">
						<p class="mb-0 caption font-weight-bold mr-4">Configuración Etapas</p>
						<!-- <v-btn
							depressed
							color="green"
							small
							outlined
							class="my-0"
							v-if="!projectHasPlanning"
							:disabled="this.projectPlanning && this.projectPlanning.planning_categories.length == 0"
							@click="confirmPlanning"
						>
							<v-icon small>mdi-check-circle-outline</v-icon>
							<p class="mb-0 ml-1 caption">Confirmar Configuración</p>
						</v-btn> -->
					</div>
				</v-col>
				<v-col cols="12" class="py-0">
					<div v-if="projectPlanning" class="mt-3">
						<div v-for="(planning, index) in projectPlanningCategories" :key="index">
							<div class="ma-2 mb-0 mt-3">
								<p class="font-weight-bold mb-0">{{ planning.name }}</p>
							</div>
							<div class="d-flex justify-start align-center fill-height">
								<draggable
									class="d-flex flex-wrap align-center"
									v-model="planning.stages"
									handle=".handle_drag"
									@end="(ev) => startEndDragEventStages(ev, planning)"
									filter=".exclude"
									easing="cubic-bezier(1, 0, 0, 1)"
									animation="200"
									ghostClass="ghost-element-drag"
									:move="onMoveDragEvent">
									<div
										class="my-2"
										v-for="(stage, index) in planning.stages"
										:key="index">
										<v-card
											class="my-0 mx-2"
											max-height="160px"
											max-width="250px"
											v-if="editStage && editCategoryStage.id == stage.id">
											<v-card-text class="fill-height d-flex flex-column align-center justify-center">
												<div class="d-flex flex-column justify-space-between align-center">
													<v-text-field
														label="Nombre Etapa"
														v-model="editCategoryStage.name"
														outlined
														required
														dense
														hide-details
														:rules="requiredRule"
													class="custom-height-search custom-height-calendar w-100"
													></v-text-field>
													<v-text-field
														label="Detalle (Opcional)"
														v-model="editCategoryStage.description"
														outlined
														required
														dense
														class="custom-height-search custom-height-calendar mt-1"
													></v-text-field>
													<div class="d-flex align-center mt-1" style="width: 100%">
														<v-checkbox class="my-0 py-0" v-model="editCategoryStage.needs_payment" color="#959595" hide-details dense></v-checkbox>
														<p class="mb-0 caption">Requiere Pago</p>
													</div>
													<div class="mt-2 d-flex" style="width: 100%">
														<v-btn
															depressed
															color="#FF120B"
															small
															dark
															class="mr-1 d-flex flex-grow-1"
															:loading="loadingCreateCategory"
															@click="updateStage()"
														>
															<v-icon small>mdi-check</v-icon>
														</v-btn>
														<v-btn
															depressed
															color="#959595"
															small
															dark
															:loading="loadingCreateCategory"
															@click="cancelEditStage()"
														>
															<v-icon small>mdi-close</v-icon>
														</v-btn>
													</div>
												</div>
											</v-card-text>
										</v-card>
										<v-card
											v-else
											class="my-0 mx-2 pa-0 square-card handle_drag"
											height="110px"
											width="160px">
											<v-card-text class="pa-3 fill-height d-flex flex-column align-start">
												<div class="flex-grow-1" style="width:100%">
													<p class="font-weight-bold caption mb-1 text-center" style="line-height: 13px;">{{stage.name}}</p>
													<p class="mb-0 text-center" style="font-size: 10px;line-height: 10px;">{{stage.description}}</p>
												</div>
												<div class="d-flex justify-space-between align-center" style="width: 100%">
													<div class="d-flex align-center">
														<v-btn
															depressed
															color="warning"
															x-small
															icon
															@click.stop="startEditStage(stage)"
														>
															<v-icon small>mdi-note-edit-outline</v-icon>
														</v-btn>
														<v-btn
															depressed
															color="#FF120B"
															x-small
															icon
															@click.stop="deleteStage(stage.id)"
														>
															<v-icon small>mdi-delete-outline</v-icon>
														</v-btn>
													</div>
													<v-chip
														:color="stage.needs_payment ? '#FF120B':'#959595'"
														label
														outlined
														x-small
													>
														<b>{{stage.needs_payment ? 'Requiere Pago':'Sin Pago'}}</b>
													</v-chip>
												</div>
											</v-card-text>
										</v-card>
									</div>
									<v-card
										class="my-2 mx-2"
										max-height="160px"
										max-width="250px"
										v-if="newCategoryStageShow && planning.id == newCategoryStage.project_planning_cat_id">
										<v-card-text class="fill-height d-flex flex-column align-center justify-center">
											<div v-if="newCategoryStageShow" class="d-flex flex-column justify-space-between align-center">
												<v-text-field
													label="Nombre Etapa"
													v-model="newCategoryStage.name"
													outlined
													required
													dense
													hide-details
													class="custom-height-search custom-height-calendar w-100"
												></v-text-field>
												<v-text-field
													label="Detalle (Opcional)"
													v-model="newCategoryStage.description"
													outlined
													required
													dense
													class="custom-height-search custom-height-calendar mt-3"
												></v-text-field>
												<div class="d-flex align-center mt-1" style="width: 100%">
													<v-checkbox class="my-0 py-0" v-model="newCategoryStage.needs_payment" color="#959595" hide-details dense></v-checkbox>
													<p class="mb-0 caption">Requiere Pago</p>
												</div>
												<div class="mt-2 d-flex" style="width: 100%">
													<v-btn
														depressed
														color="#FF120B"
														small
														dark
														class="mr-1 d-flex flex-grow-1"
														:loading="loadingCreateCategory"
														@click="confirmCategoryStage()"
													>
														<v-icon small>mdi-check</v-icon>
													</v-btn>
													<v-btn
														depressed
														color="#959595"
														small
														dark
														:loading="loadingCreateCategory"
														@click="closeNewCategoryStage()"
													>
														<v-icon small>mdi-close</v-icon>
													</v-btn>
												</div>
											</div>
										</v-card-text>
									</v-card>
									<v-card
										class="my-2 mx-2 square-card exclude"
										height="90px"
										width="90px"
										v-else
										@click="startNewCategoryStage(planning)">
										<v-card-text class="fill-height d-flex flex-column align-center justify-center">
											<v-icon large>mdi-plus</v-icon>
										</v-card-text>
									</v-card>
								</draggable>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
		<NewProjectCategory v-if="$store.state.projects.categoryModal" :reload="getCategories"/>
		<CopyCompanyStage
			v-if="$store.state.projects.toCopyCompanyPlanningCategoryId"
			:reload="toNewCopiedStage"/>
	</v-container>
</template>

<script>
import draggable from 'vuedraggable'
import _debounce from 'lodash/debounce'
import NewProjectCategory from '@/components/Modals/Projects/NewProjectCategory'
import CopyCompanyStage from '@/components/Modals/Projects/CopyCompanyStage'
import {setProjectPlanningCategoryPrj, updateProjectPlanningStagePrj, updateProjectPlanningStageOrderPrj, createProjectPlanningStagePrj, getProjectPlanningPrj, createProjectPlanningCategory, deleteProjectPlanningCategory, removeProjectPlanningStage, deleteZoneIpt} from '@/helpers/api/projects'

export default {
	components: {draggable, NewProjectCategory, CopyCompanyStage},
  data() {
    return {
			hasSeremi: false,
			hasCMN: false,
			projectHasPlanning: false,
			projectPlanning: null,
			selectedZone: 1,
			projectType: 1,
			selectedProject: null,
			selectedCategory: [],
			newCategoryShow: false,
			loadingCategoryOrder: false,
			loadingStateOrder: false,
			loadingCreateCategory: false,
			newCategory: {
				name: null,
				description: null,
				order: 1
			},
			newCategoryStageShow: false,
			loadingCreateCategoryStage: false,
			newCategoryStage: {
				name: null,
				description: null,
				order: 1,
				needs_payment: false,
				project_planning_cat_id: null
			},
			editStage: false,
			editCategoryStage: null,
			requiredRule: [
				v => !!v || 'Campo requerido'
			],
    };
  },
  computed: {
		zoneIpts() {
			return this.$store.state.projects.zoneIpt
		},
		projectPlanningCategories(){
			if(this.projectPlanning){
				return this.projectPlanning.planning_categories
			}
			return []
		},
		categories: {
			get() {
				return this.$store.state.projects.projectPlanningCategories
			},
			set(value) {
				this.$store.commit('projects/setProjectPlanningCategories', value);
			},
		},
		selectedCategoryStages: {
			get() {
				if(this.selectedCategoriesInfo && this.categories && this.categories.length > 0){
					const found = this.selectedCategoriesInfo.find(category => category.id === this.selectedCategory)
					return found ? found.stages:[]
				}
				return null
			},
			set(value) {
				const index = this.categories.findIndex(category => category.id === this.selectedCategory)
				if(index != -1){
					this.categories[index].stages = value
				}
			},
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		selectedCategoriesInfo(){
			if(!this.categories){ return null }
			return this.categories.filter(cat => this.selectedCategory.includes(cat.id))
		},
		newCreatedElement(){
			return this.$store.state.general.newCreatedElement
		}
  },
	watch: {
		newCreatedElement(newValue){
			if(newValue && newValue.type == 'project'){
				this.selectedProject = newValue.value
				this.$store.commit('general/setNewCreatedElement', null)
			}
		},
		zoneIpts(newValue){
			if(newValue && newValue.length > 0){
				this.selectedZone = newValue[0].id
			}
		},
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getCompanyProjectTypes')
			}
		},
		selectedZone(newValue, oldValue){
			if(newValue != oldValue || !newValue){
				this.selectedCategory = null
			}
			this.getCategories()
		},
		projectType(newValue, oldValue){
			if(newValue != oldValue || !newValue){
				this.selectedCategory = null
			}
			this.getCategories()
		},
		selectedProject(newValue){
			if(newValue){
				this.getProjectPlanning()
				this.getCategories()
			}
			else{
				this.hasSeremi = false
				this.hasCMN = false
				this.projectHasPlanning = false
				this.projectPlanning = null
				this.selectedCategory = []
				this.newCategoryShow = false
				this.newCategoryStageShow = false
			}
		},
		projectPlanningCategories(newValue){
			if(newValue){
				const stage = this.projectPlanningCategories[0].stages.find(stage => stage.code && stage.code.includes('seremi'))
				this.hasSeremi = !!stage
				const stage_cmn = this.projectPlanningCategories[0].stages.find(stage => stage.code && stage.code.includes('cmn'))
				this.hasCMN = !!stage_cmn
			}
		}
	},
	mounted(){
		this.getCompanyProjects()
		this.getZonesIpt()
	},
  methods: {
		goToPreProject(){
			if(this.selectedProject){
				this.$router.push({
					name: 'Client',
					params: { id: this.selectedProject.client_id },
					query: { p: this.selectedProject.info?.preproject?.preproject_id }
				})
			}
		},
		async getProjectPlanning(){
			const planning = await getProjectPlanningPrj({project_id: this.selectedProject.id})
			if(planning.code == 200){
				this.projectPlanning = planning.project_planning
				this.projectHasPlanning == this.projectPlanning && this.projectPlanningplanning_categories.length > 0
			}
		},
		startCategoryToProject(category){
			this.projectPlanning = {...this.projectPlanning, ...{planning_categories: [category]}}
		},
		async setCategoryToProject(category){
			if(!this.selectedProject){
				const messageData = {
					message: 'Por favor selecciona un proyecto para comenzar',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
				return
			}
			const data = {
				...category,
				...{ category_stages: category.stages, project_id: this.selectedProject.id }
			}
			const planning = await setProjectPlanningCategoryPrj(data)
			if(planning.code === 200){
				this.projectPlanning = planning.project_planning
			}
		},
		async toggleSeremi(status){
			if(status){
				const data = {
					name: 'Tramitación Seremi Minvu',
					code: 'seremi',
					description: null,
					order: this.projectPlanningCategories[0].stages.length + 1,
					needs_payment: false,
					project_planning_cat_id: this.projectPlanningCategories[0].id
				}
				this.loadingCreateCategory = true
				const category = await createProjectPlanningStagePrj(data)
				if(category.code == 200){
					this.closeNewCategoryStage()
					this.getProjectPlanning()
				}
				this.loadingCreateCategory = false
				console.log(data)
			}
			else{
				const stage = this.projectPlanningCategories[0].stages.find(stage => stage.code && stage.code.includes('seremi'))
				stage && this.deleteStage(stage.id)
			}
		},
		async toggleCMN(status){
			if(status){
				const data = {
					name: 'Tramitación Consejo Monumentos Nacionales (CMN)',
					code: 'cmn',
					description: null,
					order: this.projectPlanningCategories[0].stages.length + 1,
					needs_payment: false,
					project_planning_cat_id: this.projectPlanningCategories[0].id
				}
				this.loadingCreateCategory = true
				const category = await createProjectPlanningStagePrj(data)
				if(category.code == 200){
					this.closeNewCategoryStage()
					this.getProjectPlanning()
				}
				this.loadingCreateCategory = false
				console.log(data)
			}
			else{
				const stage = this.projectPlanningCategories[0].stages.find(stage => stage.code && stage.code.includes('cmn'))
				stage && this.deleteStage(stage.id)
			}
		},
		toNewCopiedStage(info){
			this.selectedZone = info.zone_id
			this.projectType = info.project_type_id

			this.getCategories()
			this.selectedCategory = info.project_planning_category_id
		},
		copyCategoriesConfig(to_copy_category_id){
			this.$store.commit('projects/setToCopyCompanyPlanningCategoryId', to_copy_category_id)
		},
		getCategories(){
			if(this.selectedZone){
				this.$store.dispatch('projects/getAllProjectPlanningCategories', {
					zone_id: this.selectedProject.zone_id,
					project_type_id: this.selectedProject.project_type_id
				})
			}
		},
		getCompanyProjects(){
			this.$store.dispatch('projects/getProjects')
		},
		getZonesIpt(){
			this.$store.dispatch('projects/getAllZoneIpts')
		},
		startNewProject(){
			this.$store.commit('projects/setProjectModalInfo', null)
			this.$store.commit('projects/setProjectModal', true)
		},
		startEditZoneIpt(zone){
			const data = {
				...zone,
				...{zone_ipt_id: zone.id}
			}
			this.$store.commit('projects/setZoneIptModalEditInfo', data)
			this.$store.commit('projects/setZoneIptModal', true)
		},
		startNewZoneIpt(){
			this.$store.commit('projects/setZoneIptModalEditInfo', null)
			this.$store.commit('projects/setZoneIptModal', true)
		},
		async deleteZoneIpt(zone_id){
			const stage = await deleteZoneIpt({zone_ipt_id: zone_id})
			if(stage.code == 200){
				this.getZonesIpt()
			}
		},
		compareCategories(planningCategories, category) {
			return planningCategories.some(planningCat => 
					category.some(cat => 
							planningCat.name.includes(cat.name) || cat.name.includes(planningCat.name)
					)
			);
		},
		async handleSelectedCategory(category) {
			if(this.compareCategories(this.projectPlanning.planning_categories, category)){
				const messageData = {
					message: 'Ya existe una estapa de este tipo en la configuración',
					title: 'Ha ocurrido un error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
			}
			else{
				// this.projectPlanning = {...this.projectPlanning, ...{planning_categories: category}}
				this.setCategoryToProject(category)
			}
		},
		startEditStage(stage){
			this.editCategoryStage = stage
			this.editStage = true
		},
		cancelEditStage(){
			this.editStage = false
			this.editCategoryStage = null
		},
		startEditCategory(category){
			const general_stage = !category.project_type_id
			const data = {
				...category,
				...{project_planning_category_id: category.id},
				...{zone_id: this.selectedZone, project_type_id: this.projectType, general_stage}
			}
			this.$store.commit('projects/setCategoryModalEditInfo', data)
			this.$store.commit('projects/setCategoryModal', true)
		},
		cancelEditCategory(){
			this.$store.commit('projects/setCategoryModal', false)
			this.$store.commit('projects/setCategoryModalEditInfo', null)
		},
		async updateStage(){
			this.loadingCreateCategory = true
			const data = {
				...this.editCategoryStage,
				...{project_planning_stage_id: this.editCategoryStage.id}
			}
			const stage = await updateProjectPlanningStagePrj(data)
			if(stage.code == 200){
				this.getProjectPlanning()
				this.cancelEditStage()
			}
			this.loadingCreateCategory = false
		},
		startNewCategory(){
			const data = {
				zone_id: this.selectedZone,
				order: this.categories.length + 1,
				project_type_id: this.projectType
			}
			this.$store.commit('projects/setCategoryModalEditInfo', data)
			this.$store.commit('projects/setCategoryModal', true)
		},
		closeNewCategory(){
			this.newCategory = {
				name: null,
				description: null,
				order: 1
			}
			this.newCategoryShow = false
		},
		startNewCategoryStage(category){
			this.newCategoryStage = {
				name: null,
				description: null,
				order: category.stages.length + 1,
				needs_payment: false,
				project_planning_cat_id: category.id
			}
			this.newCategoryStageShow = true
		},
		closeNewCategoryStage(){
			this.newCategoryStage = {
				name: null,
				description: null,
				order: 1,
				needs_payment: false,
				project_planning_cat_id: null
			}
			this.newCategoryStageShow = false
		},
		async confirmCategoryStage(){
			this.loadingCreateCategory = true
			const category = await createProjectPlanningStagePrj(this.newCategoryStage)
			if(category.code == 200){
				this.closeNewCategoryStage()
				this.getProjectPlanning()
			}
			this.loadingCreateCategory = false
		},
		async confirmCategory(){
			this.loadingCreateCategory = true
			const category = await createProjectPlanningCategory(this.newCategory)
			if(category.code == 200){
				this.closeNewCategory()
				this.getCategories()
			}
			this.loadingCreateCategory = false
		},
		async deleteStage(stage_id){
			const stage = await removeProjectPlanningStage({project_planning_stage_id: stage_id})
			if(stage.code == 200){
				this.getProjectPlanning()
			}
		},
		async deleteCategory(category_id){
			const category = await deleteProjectPlanningCategory({project_planning_category_id: category_id})
			if(category.code == 200){
				this.getCategories()
			}
		},
		selectZone(zone){
			this.selectedZone = zone
		},
		onMoveDragEvent(evt){
			return evt.related.className.indexOf('exclude') === -1
		},
		startEndDragEventStages: _debounce(function(ev, planning){ this.endDragEventStages(ev, planning) }, 500),
		async endDragEventStages(ev, planning){
			if (typeof ev.newIndex === 'number') {
				this.loadingStateOrder = true
				const stages = planning.stages.map((stage, index) => {
					return {
						id: stage.id,
						order: index + 1
					}
				})
				const data = {
					project_id: this.selectedProject.id,
					stages
				}
				const stages_r = await updateProjectPlanningStageOrderPrj(data)
				if(stages_r.code == 200){
					this.projectPlanning = stages_r.project_planning
					this.projectHasPlanning == this.projectPlanning && this.projectPlanningplanning_categories.length > 0
				}
				this.loadingStateOrder = false
				// const promises = planning.stages.map((stage, index) => {
				// 	const data = {
				// 		project_planning_stage_id: stage.id,
				// 		order: index + 1
				// 	}
				// 	return updateProjectPlanningStagePrj(data);
				// });

				// Promise.all(promises)
				// 	.then(() => {
				// 		this.getProjectPlanning()
				// 		this.loadingStateOrder = false
				// 	})
				// 	.catch(() => {
				// 		this.loadingStateOrder = false
				// 	})
			}
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    }
  },
};
</script>

<style scoped>
.square-card{
	aspect-ratio: 1;
	cursor: pointer;
}
.custom-height-calendar .v-input__control,
.custom-height-calendar .v-field__input,
.custom-height-calendar .v-input__slot,
.custom-height-calendar .v-select__slot {
  height: 30px!important;
	min-height: 30px!important;
}
.custom-height-calendar .v-input__append-inner{
	margin-top: 3px!important;
}
.custom-calendar-sidemini .v-date-picker-table{
	height: auto;
}
.custom-height-search label{
	font-size: 12px;
	top: 5px!important;
}
/* .custom-height-search .v-progress-linear .v-progress-linear__background {
  background-color: #FF120B;
  color: #FF120B;
} */
.custom-height-search .v-progress-linear__indeterminate {
  background-color: #FF120B!important; /* Cambia este color */
}
.ghost-element-drag{
	opacity: 0;
}
.item-background-hover:hover{
	background-color: rgba(0,0,0,0.1);
}
.action-container{
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.light-blue-text{color: #959595;}
</style>