<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="$store.state.companies.companiesList"
      :search="search"
      item-key="id"
      :loading="$store.state.companies.loadingCompaniesList"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.name="{ item }">
				<div class="d-flex align-center">
					<v-avatar
						size="30"
					>
						<img :src="item.company.logo" class="user-avatar" v-if="item.company.logo"/>
						<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-image</v-icon>
					</v-avatar>
					<p class="ml-2 mb-0">{{ item.company.name }}</p>
				</div>
      </template>
      <template v-slot:item.responsible="{ item }">
				<p class="mb-0" v-if="item.company.user_responsible && item.company.user_responsible.profile">
					{{item.company.user_responsible.profile.name }} {{item.company.user_responsible.profile.lastname }}
				</p>
				<p class="mb-0" v-else>
					Sin usuario seleccionado
				</p>
			</template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom v-if="$store.state.user.userInfo">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="editCompany(item)"
							v-bind="attrs"
							v-on="on"
							v-if="$checkPermissions(['edit_company'])"
						>
							<v-icon small dark>mdi-pencil</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip>
				<v-tooltip bottom v-if="$store.state.user.userInfo">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="error"
							class="mx-1"
							v-if="$checkPermissions(['delete_company'])"
							@click="deleteCompany(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Eliminar</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {deleteCompany} from '@/helpers/api/company'
export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Responsable', value: 'responsible' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.$store.state.users.usersList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
	mounted(){
		this.$store.dispatch('companies/getCompaniesList')
	},
  methods: {
		showDeleteCompany(company){
		if(this.$store.state.user.userInfo){
			return company.company.responsible == this.$store.state.user.userInfo.id
		}
		return false
		},
    addCompany() {
			this.$store.commit('companies/setNewCompanyModal', true)
    },
    editCompany(company) {
			const data = {
				company_id: company.company.id,
				name: company.company.name,
				description: company.company.description,
				logo: company.company.logo,
				url: company.company.url,
				rut: company.company.rut,
				address: company.company.address,
				legal_name: company.company.legal_name,
				responsible: company.company.responsible,
				city_id: company.company.city_id,
				state_id: company.company.state_id
			}
			this.$store.commit('companies/setNewCompanyModalEditing', data)
			this.$store.commit('companies/setNewCompanyModal', true)
    },
    async deleteCompany(company_id) {
      await deleteCompany({company_id})
			this.$store.dispatch('companies/getCompaniesList')
    }
  },
};
</script>

<style scoped>
</style>