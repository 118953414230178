<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="$store.state.users.usersList"
      :search="search"
      item-key="id"
      :loading="$store.state.users.loadingUserList"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.name="{ item }">
				<div class="d-flex align-center">
					<v-avatar
						size="30"
					>
						<img :src="item.profile.avatar" class="user-avatar" v-if="item.profile?.avatar"/>
						<v-icon v-else class="user-avatar icon" color="#524D4D" x-large>mdi-account</v-icon>
					</v-avatar>
					<p class="ml-2 mb-0">{{ item.profile?.name }}</p>
				</div>
      </template>
      <template v-slot:item.role="{ item }">
				<div class="d-flex align-center">
					<p class="mb-0">{{ item.company_rol?.role?.name }}</p>
				</div>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom v-if="$checkPermissions(['edit_user_permissions'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#959595"
							class="mx-1"
							@click="editUserPermission(item)"

							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-shield-edit-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar Permisos</span>
				</v-tooltip>
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="#31D56A"
							class="mx-1"
							@click="$router.push({ name: 'Chat', query: { user_id: item.id } })"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-chat-outline</v-icon>
						</v-btn>
					</template>
					<span>Chatear</span>
				</v-tooltip> -->
				<v-tooltip bottom v-if="$checkPermissions(['edit_users'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="editUser(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-pencil-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip>
				<v-tooltip bottom v-if="$checkPermissions(['delete_users'])">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="error"
							class="mx-1"
							@click="deleteUser(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Eliminar</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {removeUserFromCompany} from '@/helpers/api/company'

export default {
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Rol', value: 'role' },
        { text: 'Email', value: 'email' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    filteredUsers() {
      return this.$store.state.users.usersList.filter(user => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('users/getUserFromCompany', {start: 0, limit: 30})
				this.$store.dispatch('companies/getRoles', {start: 0, limit: 30})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('users/getUserFromCompany')
			this.$store.dispatch('companies/getRoles', {start: 0, limit: 30})
		}
	},
  methods: {
		editUserPermission(user){
			this.$store.commit('users/setEditPermissionsUser', user)
			this.$store.commit('users/setEditPermisosUserModal', true)
		},
    addUser() {
			this.$store.commit('users/setNewUserModalEditing', null)
			this.$store.commit('users/setNewUserModal', true)
    },
    editUser(user) {
			const data = {...user, ...{user_id: user.id}}
			this.$store.commit('users/setNewUserModalEditing', data)
			this.$store.commit('users/setNewUserModal', true)
    },
    async deleteUser(user_id) {
			await removeUserFromCompany({
				company_id: this.$store.state.companies.selectedCompany.company_id,
				user_id
			})
			this.$store.dispatch('users/getUserFromCompany')
    },
  },
};
</script>

<style scoped>
</style>