const endpoints = {
	addCrmEntry: '/crm/addCrmEntry',
	deleteCrmEntry: '/crm/deleteCrmEntry',
	archiveCrmEntry: '/crm/archiveCrmEntry',
	restoreCrmEntry: '/crm/restoreCrmEntry',
	editCrmValue: '/crm/editCrmValue',
	editCrmProjectDetail: '/crm/editCrmProjectDetail',
	editCrmProjectType: '/crm/editCrmProjectType',
	assignScheduleToUser: '/crm/assignScheduleToUser',
}
export {endpoints}