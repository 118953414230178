const endpoints = {
	getUserFromCompany: '/companies/users/getUserFromCompany',
	searchUsersFromCompany: '/companies/searchUsersFromCompany',
	createUserForCompany: '/companies/users/createUserForCompany',
	updateUserForCompany: '/companies/users/updateUserForCompany',
	removeUserFromCompany: '/companies/users/removeUserFromCompany',
	updateUserCompanyPermissions: '/companies/users/updateUserCompanyPermissions',
	updateUserCompanyStatus: '/companies/users/updateUserCompanyStatus',
	getUserCompanyStatus: '/companies/users/getUserCompanyStatus',
	createConcept: '/concepts/createConcept',
	updateConcept: '/concepts/updateConcept',
	deleteConcept: '/concepts/deleteConcept',
	getConcepts: '/concepts/getConcepts',
	create: '/companies/create',
	update: '/companies/update',
	delete: '/companies/delete',
	getAdminCompanies: 'companies/users/getAdminCompanies',
	createRole: '/roles/createRole',
	updateRole: '/roles/updateRole',
	updateRolesOrders: '/roles/updateRolesOrders',
	deleteRole: '/roles/deleteRole',
	getRoles: '/roles/getRoles',
	getProjectTypes: '/companies/getProjectTypes',
	createProjectType: '/companies/createProjectType',
	updateProjectType: '/companies/updateProjectType',
	updateProjectTypeOrders: '/companies/updateProjectTypeOrders',
	deleteProjectType: '/companies/deleteProjectType'
}
export {endpoints}