<template>
  <v-container fluid class="crm-conversation">
    <v-data-table
      :headers="headers"
      :items="concepts"
      :search="search"
      item-key="id"
      :loading="$store.state.companies.loadingConcepts"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.text="{ item }">
				<div class="my-4">
					<div v-html="item.text"></div>
				</div>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="editConcept(item)"
							v-if="$checkPermissions(['edit_company_concepts'])"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-pencil-outline</v-icon>
						</v-btn>
					</template>
					<span>Editar</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="error"
							class="mx-1"
							v-if="$checkPermissions(['delete_company_concepts'])"
							@click="deleteConcept(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Eliminar</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {deleteConcept} from '@/helpers/api/company'

export default {
	name: 'CompanyConcepts',
  data() {
    return {
			loading: false,
			search: '',
      headers: [
        { text: 'Identificador', value: 'internal_id', width: '15%' },
        { text: 'Descripción', value: 'description', width: '25%' },
        { text: 'Información', value: 'text', width: '40%' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    concepts() {
      return this.$store.state.companies.concepts
    },
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getConcepts')
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('companies/getConcepts')
		}
	},
  methods: {
		addConcept(){
			this.$store.commit('companies/setNewCompanyConceptModal', true)
		},
		editConcept(concept){
			const data = {
				concept_id: concept.id,
				internal_id: concept.internal_id,
				description: concept.description,
				text: concept.text
			}
			this.$store.commit('companies/setNewCompanyConceptModalEditing', data)
			this.$store.commit('companies/setNewCompanyConceptModal', true)
		},
		async deleteConcept(concept_id){
			await deleteConcept({concept_id})
			this.$store.dispatch('companies/getConcepts')
		}
  },
};
</script>

<style scoped>
</style>