<template>
  <v-container fluid>
    <v-row class="mb-4" v-if="loading">
      <v-col cols="12">
				<div class="d-flex flex-column justify-center align-center">
					<v-progress-circular
						:size="50"
						:width="5"
						color="rgba(0,0,0,0.2)"
						class="mb-3"
						indeterminate
					></v-progress-circular>
					Cargando Información
				</div>
      </v-col>
    </v-row>
		<v-row class="mb-4" v-else>
      <v-col cols="12" md="5">
        <v-card class="mb-4">
          <v-card-text class="py-2">
						<div class="d-flex align-center justify-space-between">
							<div class="d-flex align-center">
								<p class="font-weight-bold body-2 mb-0 mr-2">Datos Usuario</p>
							</div>
						</div>
						<div>
							<p class="d-flex justify-space-between mb-1">Nombre: <b>{{user_info.profile.name}}</b></p>
							<p class="d-flex justify-space-between mb-1">Email: <b>{{user_info.email}}</b></p>
							<p class="d-flex justify-space-between mb-1">Teléfono: <b>{{user_info.profile.phone}}</b></p>
						</div>

						<v-divider class="my-4"></v-divider>
						
						<p class="font-weight-bold body-2 mb-1">Empresas Asociadas</p>
						<v-chip
							v-for="(company,index) in user_info.companies"
							:key="index"
							color=""
							label
							outlined
							small
							class="mx-1"
						>
							{{ company.company.name }}
						</v-chip>
          </v-card-text>
        </v-card>
			</v-col>
      <v-col cols="12" md="7">
				<p class="font-weight-bold body-2 mb-1">Políticas Aceptadas</p>
				<v-data-table
					:headers="headers"
					:items="policies"
					item-key="id"
					:loading="loading"
					disable-sort
					loading-text="Cargando datos..."
					:footer-props="{
						itemsPerPageText: 'Filas por página'
					}"
				>
					<template v-slot:item.date="{ item }">
						<p class="mb-0">
							{{item.created_at | toDate}}
						</p>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
  </v-container>
</template>

<script>
import {getPolicyUserAcceptance} from '@/helpers/api/general'
export default {
  data() {
    return {
			loading: true,
      user_info: null,
			policies: null,
      headers: [
        { text: 'Motivo', value: 'reason', width: '60%' },
        { text: 'Fecha Aceptación', value: 'date' }
      ],
    };
  },
	mounted(){
		this.getUserPolicies()
	},
  methods: {
		async getUserPolicies(){
			this.loading = true
			const policies = await getPolicyUserAcceptance({user_id: this.$route.params.user_id})
			if(policies.code == 200){
				this.user_info = policies.user
				this.policies = policies.policies
			}
			this.loading = false
		}
  },
};
</script>

<style scoped>
</style>