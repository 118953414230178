import {endpoints} from './endpoints'
import request from '../request'

export function getVTReportTemplates (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getVTReportTemplates,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createVTReportTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateVTReportTemplate,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteVTReportTemplate (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteVTReportTemplate,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getStates (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getStates,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCities (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCities,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCountryCities (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCountryCities,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getPolicyAcceptance (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getPolicyAcceptance,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getPolicyUserAcceptance (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getPolicyUserAcceptance,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getCoordinatesValuesPolygon (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getCoordinatesValuesPolygon,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateAddress (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateAddress,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

//Social Profiles
export function updatePublicProfile (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updatePublicProfile,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updatePublicProfileStatus (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updatePublicProfileStatus,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getItemPublicProfile (params) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getItemPublicProfile,
			method: 'get',
			params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}