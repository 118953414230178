<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="$store.state.companies.roles"
      :search="search"
      item-key="id"
      :loading="$store.state.companies.loadingRoles"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
			:items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <draggable
          v-model="$store.state.companies.roles"
					tag="tbody"
          item-key="id"
          @end="startEndDragEvent"
        >
					<tr v-for="(item) in items" :key="item.id" style="cursor: pointer;">
						<td style="cursor: pointer;">{{ item.name }}</td>
						<td style="cursor: pointer;">{{ item.description }}</td>
						<td>
							<div v-if="item.company_id">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											color="warning"
											class="mx-1"
											v-if="$checkPermissions(['edit_company_roles'])"
											@click="editRole(item)"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-pencil-outline</v-icon>
										</v-btn>
									</template>
									<span>Editar</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											color="error"
											class="mx-1"
											v-if="$checkPermissions(['delete_company_roles'])"
											@click="deleteRole(item.id)"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-delete-outline</v-icon>
										</v-btn>
									</template>
									<span>Eliminar</span>
								</v-tooltip>
							</div>
							<div v-else>
								<p class="caption text--disabled mb-0">Rol de Sistema</p>
							</div>
						</td>
					</tr>
        </draggable>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {deleteRole, updateRolesOrders} from '@/helpers/api/company'
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getRoles', {start: 0, limit: 100})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('companies/getRoles', {start: 0, limit: 100})
		}
	},
  methods: {
		startEndDragEvent: _debounce(function(ev){ this.endDragEvent(ev) }, 800),
		async endDragEvent(ev){
			if (typeof ev.newIndex === 'number') {
				const roles = this.$store.state.companies.roles.map((project_type, index) => {
					return { order: index + 1, id: project_type.id}
				})
				await updateRolesOrders({roles})
				this.$store.dispatch('companies/getRoles', {start: 0, limit: 100})
			}
		},
    addRole() {
			this.$store.commit('companies/setNewRoleModalEditing', null)
			this.$store.commit('companies/setNewRoleModal', true)
    },
    editRole(role) {
			const data = {
				role_id: role.id,
				name: role.name,
				description: role.description
			}
			this.$store.commit('companies/setNewRoleModalEditing', data)
			this.$store.commit('companies/setNewRoleModal', true)
    },
    async deleteRole(role_id) {
      await deleteRole({role_id})
			this.$store.dispatch('companies/getRoles')
    }
  },
};
</script>

<style scoped>
</style>