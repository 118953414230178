<template>
	<v-container fluid fill-height>
		<div class="d-flex" style="height: 100%;width: 100%;" :class="$vuetify.breakpoint.mobile ? 'calendar-mobile':''">
			<div class="px-2 d-flex flex-column" style="height: 100%;width: 100%;">
				<div class="d-flex align-center justify-space-between" style="width: 100%">
					<div class="d-flex align-center">
						{{calendarTitle}}
					</div>
					<div class="d-flex align-center justify-end ml-4" style="flex: 1">
						<div class="d-flex align-center" style="flex: 1;max-width: 400px;">
							<v-icon color="#524D4D" @click="searchForString">mdi-magnify</v-icon>
							<!-- <v-text-field
								v-model="searchInput"
								:loading="$store.state.schedule.loadingSchedules"
								outlined
								dense
								hide-details
								item-text="name"
								item-value="id"
								return-object
								clearable
								label="Búsqueda"
								class="mb-0 mr-2 custom-height-search custom-height-calendar"
								color="#524D4D"
								@input="sendToSearch"
							></v-text-field> -->
							<v-autocomplete
								v-model="searchInput"
								:items="items"
								name="name"
								:search-input.sync="search"
								label="Búsqueda"
								placeholder="Búsqueda"
								autocomplete="off"
								outlined
								dense
								hide-details
								:loading="loadingSearch"
								:filter="d => d"
								color="secondary"
								item-color="secondary"
								class="custom-height-search custom-height-calendar"
								@keydown.enter="searchForString"
								@change="change"
							>
								<template v-slot:item="{ item }">
									<div style="width: 100%">
										<p class="mb-0 caption font-weight-bold">{{item.title}}</p>
										<div class="d-flex align-center">
											<v-icon x-small>mdi-calendar-today-outline</v-icon>
											<p class="ml-2 mb-0 caption">{{item.date_time}}</p>
										</div>
										<div class="d-flex align-center" v-if="getInvitedUsers(item)">
											<v-icon x-small>mdi-email-outline</v-icon>
											<p class="ml-2 mb-0 caption">{{getInvitedUsers(item)}}</p>
										</div>
										<v-divider class="my-1"></v-divider>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<!-- <v-btn
							dark
							icon
							color="#524D4D"
							class="mr-2"
							@click="showSearchBar = !showSearchBar"
						>
							<v-icon dark>mdi-magnify</v-icon>
						</v-btn> -->
						<v-select
							v-model="selectedView"
							:items="availableViews"
							outlined
							dense
							required
							hide-details
							color="#524D4D"
							item-color="#524D4D"
							style="max-width: 150px;"
							class="custom-height-calendar mr-2"
							@change="onChangeView"
						>
							<template v-slot:selection="{ item }">
								<p class="mb-0 caption">{{item.text}}</p>
							</template>
						</v-select>
						<v-btn
							dark
							outlined
							small
							color="#524D4D"
							@click="goToToday"
						>
							Hoy
						</v-btn>
						<v-btn
							dark
							icon
							color="#524D4D"
							@click="goToPrev"
						>
							<v-icon dark large>mdi-chevron-left</v-icon>
						</v-btn>
						<v-btn
							dark
							icon
							color="#524D4D"
							@click="goToNext"
						>
							<v-icon dark large>mdi-chevron-right</v-icon>
						</v-btn>
						<v-btn
							dark
							icon
							color="#524D4D"
							v-if="$vuetify.breakpoint.mobile"
							@click="showMapSideView = !showMapSideView"
						>
							<v-icon dark large>mdi-dots-horizontal-circle-outline</v-icon>
						</v-btn>
					</div>
				</div>
				<FullCalendar
					ref="calendar"
					class="fullcalendar-schedule-custom"
					:options='calendarOptions'
					style="height: 100%,width: 100%"
				>
					<template v-slot:eventContent='arg'>
						<div
							:ref="'event' + arg.event.extendedProps.array_index"
							class="px-1 py-0 rounded w-100 white--text calendar-event-custom"
							:style="'width: 100%; height: 100%; background-color:' + (arg.event.extendedProps.bg_color ?? '#959595')"
							@click="handleDateClick(arg.event, $event)">
							<div class="d-flex justify-space-between align-center">
								<v-chip
									v-show="scheduleTypeText(arg.event.extendedProps)"
									color="white"
									label
									outlined
									x-small
									class="px-1"
								>
									<b>{{ scheduleTypeText(arg.event.extendedProps, true) }}</b>
								</v-chip>
								<v-tooltip bottom v-if="arg.event.extendedProps.simple_agenda">
									<template v-slot:activator="{ on, attrs }">
											<v-icon
												small
												color="white"
												v-bind="attrs"
												v-on="on">mdi-information-outline</v-icon>
									</template>
									<span>ID EN CRM/Solicitudes: {{ arg.event.extendedProps.simple_agenda.bot_conversation_id }}</span>
								</v-tooltip>
							</div>
							<p class="mb-0 font-weight-bold caption custom-title-schedule">{{ arg.event.title }}</p>
							<p class="mb-0 caption"><v-icon x-small dark>mdi-clock-outline</v-icon> {{getHour(arg.event.start)}}</p>
						</div>
					</template>
				</FullCalendar>
				<v-card v-if="showPopup" class="popup" :style="{ top: popupPosition.top, left: popupPosition.left }" ref="popup">
					<div class="popup-content">
						<div class="d-flex justify-space-between align-center">
							<div class="d-flex justify-start align-center">
								<v-chip
									v-show="scheduleTypeText(selectedEvent)"
									color=""
									label
									outlined
									x-small
								>
									<b>{{ scheduleTypeText(selectedEvent) }}</b>
								</v-chip>
							</div>
							<div class="d-flex justify-end align-center">
								<v-menu
									ref="menuColors"
									v-model="menuColorPicker"
									:close-on-content-click="false"
									:return-value.sync="color"
									transition="scale-transition"
									offset-y
									max-width="170px"
									min-width="170px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											text
											x-small
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small :color="color">mdi-circle</v-icon>
											<v-icon small>mdi-chevron-down</v-icon>
										</v-btn>
									</template>
									<div class="white pa-1">
										<v-row no-gutters align="center">
											<v-col
												v-for="(col, i) in availableColors"
												:key="i"
												cols="2"
											>
												<v-btn
													icon
													x-small
													@click="onColorSelect(col)"
												>
													<v-icon :color="col">mdi-circle</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</div>
								</v-menu>


								<v-tooltip bottom v-if="selectedEvent.simple_agenda">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											icon
											color="#959595"
											@click="toSimpleAgenda()"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-file-document-arrow-right-outline</v-icon>
										</v-btn>
									</template>
									<span>Ver Detalle</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											icon
											color="#524D4D"
											@click="duplicateSchedule()"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-content-duplicate</v-icon>
										</v-btn>
									</template>
									<span>Duplicar</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											icon
											color="warning"
											@click="toActionSchedule()"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-pencil-outline</v-icon>
										</v-btn>
									</template>
									<span>Editar</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											icon
											color="error"
											@click="deleteSchedule()"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-delete-outline</v-icon>
										</v-btn>
									</template>
									<span>Eliminar</span>
								</v-tooltip>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											fab
											dark
											x-small
											icon
											color="#524D4D"
											@click="closePopup()"
											v-bind="attrs"
											v-on="on"
										>
											<v-icon small dark>mdi-close</v-icon>
										</v-btn>
									</template>
									<span>Cerrar</span>
								</v-tooltip>
							</div>
						</div>
						<div class="d-flex justify-space-between align-center flex-1" style="width: 100%">
							<h4 style="font-size: 14px">{{ selectedEvent.title }}</h4>
							<p
								v-if="selectedEvent.simple_agenda"
								class="mb-0 caption">ID CRM/Solicitud: {{ selectedEvent.simple_agenda.bot_conversation_id }}</p>
						</div>
						<div v-if="selectedEventUserInfo">
							<p class="mb-0 d-flex align-center" style="font-size: 14px"><v-icon small class="mr-2">mdi-account-outline</v-icon> {{selectedEventUserInfo.user_name}}</p>
							<p class="mb-0 d-flex align-center" style="font-size: 14px"><v-icon small class="mr-2">mdi-email-outline</v-icon> {{selectedEventUserInfo.user_email}}</p>
							<p class="mb-0 d-flex align-center" style="font-size: 14px"><v-icon small class="mr-2">mdi-phone-outline</v-icon> {{selectedEventUserInfo.user_phone}}</p>
						</div>
						<div v-else>
							<p class="mb-0 d-flex align-center" style="font-size: 14px"><v-icon small class="mr-2">mdi-account-off-outline</v-icon> Sin usuario invitado</p>
						</div>
						<p class="mb-0 d-flex align-center" style="font-size: 14px"><v-icon small class="mr-2">mdi-calendar</v-icon> {{selectedEvent.date_time}}</p>
						<div>
							<v-chip
								v-if="selectedEvent.address"
								class="mt-1"
								label
								outlined
								small
								style="width: 100%;font-size: 14px;"
								@click="onAddressClick"
							>
								<v-icon small class="mr-2">mdi-map-marker</v-icon>
								{{ selectedEvent.address.address }}
							</v-chip>
						</div>
						<div>
							<v-chip
								v-if="selectedEvent.info && selectedEvent.info.meeting_link"
								class="mt-1"
								label
								outlined
								small
								style="width: 100%;font-size: 14px;"
								@click="goToMeet(selectedEvent.info.meeting_link)"
							>
								<v-icon small class="mr-2">mdi-laptop-account</v-icon>
								Ingresar a la sala de Google Meet
							</v-chip>
						</div>
					</div>
				</v-card>
			</div>
			<div style="width: 340px" v-show="showMapSideView">
				<v-card elevation="4" class="pa-1 d-flex flex-column" style="height: 100%">
					<v-date-picker
						v-model="focusedDate"
						no-title
						scrollable
						:first-day-of-week="1"
						locale="es-cl"
						:events="holidays"
						width="100%"
						reactive
						event-color="red lighten-1"
						:picker-date.sync="pickerDate"
						@input="(date) => $refs.calendar.getApi().gotoDate(date)"
						class="custom-calendar-sidemini pb-4"
					/>
					<div v-if="$store.state.schedule.userGoogleCalendar">
						<v-divider class="my-1"></v-divider>	
						<div class="d-flex align-center justify-space-between px-2 mb-1">
							<p class="mb-0 caption">Eventos Calendar</p>
							<v-switch
								class="mt-0"
								color="#FF120B"
								dense
								hide-details
								v-model="g_calendar"
							></v-switch>
						</div>
					</div>
					<div style="height: 100%;position: relative;">
						<div class="google-maps-custom">
							<GmapMap
								:center="mapCenter"
								:zoom="mapZoom"
								style="width: 100%;height: 100%;"
								ref="mapRef"
								@zoom_changed="updateZoom"
								@dragend="updateCenter"
							>
								<GmapMarker
									v-if="selectedEvent.coordinates"
									:position="selectedEvent.coordinates"
									:icon="{url: require('@/assets/marker_schedule.png'), scaledSize: { width: 45, height: 47 }}"
									@click="onAddressClick"
								/>
							</GmapMap>
							<v-btn
								color="#FF120B"
								dark
								small
								class="custom-go-to-maps py-3"
								style="width: 175px"
								@click="goToMapsLink"
							>
								<p class="mb-0 font-weight-bold">Abrir en Maps</p>
							</v-btn>
						</div>
					</div>
				</v-card>
			</div>
		</div>
	</v-container>
</template>

<script>
import moment from 'moment'
import _debounce from 'lodash/debounce'
import FullCalendar from '@fullcalendar/vue'

//Fullcalendar Views
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'

import interactionPlugin from '@fullcalendar/interaction'
import esLocale from '@fullcalendar/core/locales/es'

import {updateUserSchedule, deleteUserSchedule, getUserSchedules, getEvents, getHolidays} from '@/helpers/api/schedule'

export default {
  components: {
    FullCalendar
  },
	computed:{
		mapsLink (){
			let lat = -33.4489
			let lng = -70.6693
			if(this.selectedEvent.coordinates){
				lat = this.selectedEvent.coordinates.lat
				lng = this.selectedEvent.coordinates.lng
			}
      return `https://www.google.com/maps/search/?api=1&map_action=map&query=${lat}%2C${lng}`
			
		},
		selectedEventUserInfo(){
			if(this.selectedEvent && this.selectedEvent.user_info){
				const user_info = this.selectedEvent.user_info.find((user) => {
					return user.user_id != this.selectedEvent.user_id
				})
				if(user_info){
					return {
						user_name: user_info.user_name,
						user_email: user_info.user_email,
						user_phone: user_info.user_phone
					}
				}
			}
			return null
		},
		forceAgendaMode(){
			return this.$store.state.schedule.forceAgendaMode
		},
		holidays(){
			return this.currentYearHolidays || this.$store.state.general.holidayDays
		},
		calendarEvents(){
			const simple_agenda = this.$store.state.schedule.schedules.map((schedule) => {
				return {
					date: new Date(schedule.vt_date),
					user_id: schedule.user_id,
					date_time: schedule.date_time,
					title: schedule.user_conversation && schedule.user_conversation.name ? schedule.user_conversation.name:'Agenda',
					address: {address: schedule.project_info?.address?.address},
					district: schedule.project_info?.project_district,
					conversation_id: schedule.bot_conversation_id,
					bg_color: 'grey',
					color: 'grey',
					coordinates: schedule.project_info && schedule.project_info.coordinates ? {
						lat: schedule.project_info.coordinates.coordinates[1],
						lng: schedule.project_info.coordinates.coordinates[0]
					}:null
				}
			})
			const schedules = this.$store.state.schedule.userSchedules.map((schedule) => {
				return {
					id: schedule.id,
					user_id: schedule.user_id,
					date: new Date(schedule.date_time),
					date_time: schedule.date_time,
					title: schedule.title,
					description: schedule.description,
					user_info: schedule.user_info,
					info: schedule.info,
					address: schedule.address,
					bg_color: schedule.color,
					color: schedule.color,
					is_schedule: true,
					simple_agenda: schedule.simple_agenda,
					coordinates: schedule.address && schedule.address.coordinates ? {
						lat: schedule.address.coordinates.coordinates[1],
						lng: schedule.address.coordinates.coordinates[0]
					}:null
				}
			})
			const agenda = [...simple_agenda, ...schedules].map((item, index) => ({
				...item,
				array_index: index
			}))
			return agenda
		},
		calendarOptions(){
			return {
				locale: esLocale,
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, multiMonthPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
				slotLabelFormat: [{ hour: '2-digit', minute: '2-digit', hour12: false }],
				headerToolbar: false,
				allDaySlot: false,
        weekends: true,
				selectable: true,
        events: this.calendarEvents,
				height: '100%',
				titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
				views: {
					dayGridMonth: {
						titleFormat: { year: 'numeric', month: 'short' }
					}
				},
				dateClick: this.handleClickDateCalendar,
				dayCellClassNames: (arg) => {
					const isHoliday = this.holidays.includes(moment(arg.date).format('YYYY-MM-DD'));
					const classHoliday = isHoliday ? 'holiday-cell' : ''
					return classHoliday;
				}
      }
		}
	},
	watch: {
		async g_calendar(newValue) {
			await localStorage.setItem('g_calendar', newValue)
			this.$store.dispatch('schedule/getUserSchedule')
    },
    search(newSearch) {
      if (newSearch) {
        this.sendScheduleSearch(newSearch);
      } else {
        this.items = []; // Limpiar los resultados si no hay búsqueda
      }
    },
		forceAgendaMode(newValue){
			this.selectedView = 'timeGridWeek'
			if(newValue){
				this.selectedView = 'listYear'
			}
			this.$refs.calendar.getApi().changeView(this.selectedView)
		},
		showSearchBar(newValue){
			this.searchInput = null
			if(!newValue){
				this.$store.dispatch('schedule/getUserSchedule')
			}
		},
		currentYear(newValue, oldValue){
			if(newValue != oldValue){
				this.updateHolidays()
			}
		},
		pickerDate(newValue){
			if(newValue){
				this.currentYear = moment(newValue).format('YYYY')
				const formattedDate = moment(newValue).format('YYYY-MM')
				const currentDay = moment(this.focusedDate).date();
				this.focusedDate = moment(formattedDate, 'YYYY-MM').date(currentDay).format('YYYY-MM-DD')
				this.$refs.calendar.getApi().gotoDate(this.focusedDate)
			}
		}
	},
	mounted(){
		this.setCalendar()
		this.updateHolidays()
		this.updateCalendarTitle()
		this.$refs.calendar.getApi().on('datesSet', this.updateCalendarTitle)
		if(this.$vuetify.breakpoint.mobile){
			this.showMapSideView = false
			this.$refs.calendar.getApi().changeView(this.selectedView)
		}
		const g_calendar = localStorage.getItem('g_calendar')
		this.g_calendar = false
		if(g_calendar){
			this.g_calendar = JSON.parse(g_calendar)
		}
  },
  data: function() {
    return {
			g_calendar: true,
			searchResults: [],
      zoomInterval: null,
      isZooming: false,
			showSearchBar: false,
			searchInput: null,
			search: '',
			items: [],
			showMapSideView: true,
			loadingSearch: false,
			menuColorPicker: false,
			selectMonth: false,
			clickTimeout: null,
			fixedYearHolidays: [],
			currentYear: 2024,
			currentYearHolidays: null,
			clickCount: 0,
			mapZoom: 11,
			mapCenter: {lat: -33.4311109, lng: -70.6381756},
      selectedMonth: moment().month() + 1,
			availableColors: [
				'#2C3E50','#FFD700','#DC143C','#228B22','#4169E1','#6A5ACD','#008080','#4B0082','#FF1493','#FF8C00'
			],
      months: [
        { value: 1, text: 'Enero' },
        { value: 2, text: 'Febrero' },
        { value: 3, text: 'Marzo' },
        { value: 4, text: 'Abril' },
        { value: 5, text: 'Mayo' },
        { value: 6, text: 'Junio' },
        { value: 7, text: 'Julio' },
        { value: 8, text: 'Agosto' },
        { value: 9, text: 'Septiembre' },
        { value: 10, text: 'Octubre' },
        { value: 11, text: 'Noviembre' },
        { value: 12, text: 'Diciembre' }
      ],
			selectedView: 'timeGridWeek',
			availableViews: [
				{text: 'Día', value: 'listDay'},
				{text: 'Semana', value: 'timeGridWeek'},
				{text: 'Mes', value: 'dayGridMonth'},
				{text: 'Año', value: 'multiMonthYear'},
				{text: 'Agenda: Semana', value: 'listWeek'},
				{text: 'Agenda: Mes', value: 'listMonth'},
				{text: 'Agenda: Año', value: 'listYear'}
			],
      showPopup: false,
			calendarTitle: '',
			focusedDate: null,
			pickerDate: null,
      popupPosition: { top: '0px', left: '0px' },
      selectedEvent: {}
    }
  },
	methods: {
		setCalendar(){
			if (this.$route.query.sync == 1) {
				localStorage.setItem('g_calendar', true)
				const messageData = {
					message: 'Se ha asociado to Google Calendar correctamente. Se sincronizarán los mensajes ahora, por favor espera unos segundos.',
					title: 'Asociación Completa',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
				
				const otherParams = Object.fromEntries(
					Object.entries(this.$route.query).filter(([key]) => key !== "sync")
				);
				this.$router.replace({
					path: this.$route.path,
					query: otherParams
				})
			}
			this.$store.dispatch('schedule/getUserSchedule')
		},
		async syncCalendarAgenda(){
			const messageData = {
				message: 'Se está sincronizando tu agenda de Calendar, por favor espera unos segundos',
				title: 'Sincronizando Agenda',
				type: 'success',
				icon: 'mdi-check-circle',
				color: '#3EBFA3',
			}
			this.setMessage(messageData)
			const events = await getEvents()
			if(events.code == 200){
				this.$store.dispatch('schedule/getUserSchedule')
				const messageData = {
					message: 'Sincronización realizada correctamente',
					title: 'Sincronización Completa',
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3',
				}
				this.setMessage(messageData)
			}
			else{
				const messageData = {
					message: 'Hubo un error en la sincronización por favor intenta nuevamente',
					title: 'Error',
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
				this.setMessage(messageData)
			}
		},
		async updateHolidays(){
			const holidays = await getHolidays({year: this.currentYear})
			if(holidays.code == 200){
				this.currentYearHolidays = holidays.events
			}
		},
		goToMapsLink(){
			window.open(this.mapsLink, '_blank')
		},
    updateZoom(zoom) {
      this.mapZoom = zoom;
    },
    updateCenter() {
      const map = this.$refs.mapRef.$mapObject;
      const newCenter = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
      };

      if (this.mapCenter.lat !== newCenter.lat || this.mapCenter.lng !== newCenter.lng) {
        this.mapCenter = newCenter;
      }
    },
		onAddressClick() {
			if(this.selectedEvent.coordinates){
				this.startZoomIn()
				// this.mapCenter = this.selectedEvent.coordinates
			}
		},
    startZoomIn() {
      this.isZooming = true;
      this.zoomIn();
    },
    stopZoomIn() {
      this.isZooming = false;
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
        this.zoomInterval = null;
      }
    },
    zoomIn() {
      if (this.zoomInterval) {
        clearInterval(this.zoomInterval);
      }

      this.zoomInterval = setInterval(() => {
        if (this.isZooming && this.mapZoom < 20) {
          this.mapZoom += 1;
					
					const map = this.$refs.mapRef.$mapObject;
					if (map) {
						map.setCenter(this.selectedEvent.coordinates);
					}
        } else {
          clearInterval(this.zoomInterval);
          this.zoomInterval = null;
        }
      }, 100);
    },
		scheduleTypeText(eventSchedule, crop = false){
			if(eventSchedule.simple_agenda && eventSchedule.simple_agenda.mode == 2){
				return crop ? 'Automática':'Agenda Automática'
			}
			else if(eventSchedule){
				return crop ? 'Manual':'Agenda Manual'
			}
			return null
		},
		goToMeet(meeting_link){
			if(meeting_link){
				window.open(meeting_link, '_blank')
			}
		},
    handleClickOutside(event) {
      if (this.showPopup && this.$refs.popup && !this.$refs.popup.$el.contains(event.target) && !this.$refs.mapRef.$el.contains(event.target)) {
				this.closePopup()
      }
    },
		getInvitedUsers(item){
			const user = item.user_info.find((user) => {
				return user.user_id != item.user_id
			})
			if(user){
				return user.user_email
			}
			return null
		},
		change(item){
			this.searchInput = null
			this.search = ""
			this.items = []
			this.focusedDate = moment(item.date_time).format('YYYY-MM-DD');
			this.pickerDate = this.focusedDate
			const to_select_event = this.calendarEvents.find((schedule) => {
				return schedule.id == item.id
			})

			this.$nextTick(() => {
        setTimeout(() => {
					const sent = {extendedProps: to_select_event}
					const position = this.getElementPosition(to_select_event.array_index)
					this.handleDateClick(sent, {
						clientY: position.top + 20,
						clientX: position.left + 20
					})
        }, 0)
      })
		},
    getElementPosition(arrayIndex) {
      const refName = 'event' + arrayIndex;
      const element = this.$refs[refName];
      if (element) {
        const rect = element.getBoundingClientRect();
        return {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        };
      } else {
        console.warn(`Elemento con ref '${refName}' no encontrado.`);
        return null;
      }
    },
		sendScheduleSearch: _debounce(function(search) { this.scheduleSearch(search) }, 800),
		async scheduleSearch(search){
			this.loadingSearch = true
      const schedules = await getUserSchedules({search})
      if (schedules.code === 200) {
				this.items = schedules.schedules
			}
			this.loadingSearch = false
		},
		sendToSearch: _debounce(function() { this.searchForString() }, 800),
		searchForString(){
			this.items = []
			this.$store.dispatch('schedule/getUserSchedule', this.search)
		},
    updateCalendarTitle() {
      const calendarApi = this.$refs.calendar.getApi();
      const currentView = calendarApi.view;

			this.focusedDate = moment(calendarApi.getDate()).format('YYYY-MM-DD');
      this.calendarTitle = currentView.title;
    },
    goToPrev() {
      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.prev();
			this.focusedDate = moment(calendarApi.getDate()).format('YYYY-MM-DD');
			this.pickerDate = this.focusedDate
    },
    goToNext() {
      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.next();
			this.focusedDate = moment(calendarApi.getDate()).format('YYYY-MM-DD');
			this.pickerDate = this.focusedDate
    },
    goToToday() {
      const calendarApi = this.$refs.calendar.getApi();
      calendarApi.today();
			this.focusedDate = moment(calendarApi.getDate()).format('YYYY-MM-DD');
			this.pickerDate = this.focusedDate
    },
    onChangeView(view) {
      this.$refs.calendar.getApi().changeView(view);
    },
		getHour(date){
			return moment(date).format('HH:mm')
		},
		handleEventClick(info){
			console.log('handleEventClick: ', info)
		},
		handleClickDateCalendar(info){
			this.clickCount++;
			if (this.clickTimeout) {
				clearTimeout(this.clickTimeout);
			}
			this.clickTimeout = setTimeout(() => {
				if (this.clickCount === 2) {
					this.handleClickCalendar(info)
				}
				this.clickCount = 0;
			}, 300);
		},
		handleClickCalendar(info){
			const date_time = moment(info.dateStr).format('YYYY-MM-DD HH:mm')
			this.$store.commit('schedule/setNewScheduleEditing', {date_time})
			this.$store.commit('schedule/setNewScheduleModal', true)
		},
		handleDateClick(event, clickEvent){
			const date = event.extendedProps
      this.selectedEvent = {...this.calendarEvents[date.array_index], ...date}
      let top = `${clickEvent.clientY}px`;
      let left = `${clickEvent.clientX + 10}px`;
      this.adjustPopupPosition(top, left);
			this.color = this.selectedEvent.color
			this.mapZoom = 11
			this.showPopup = true
			if(this.selectedEvent.coordinates){
				this.mapCenter = this.selectedEvent.coordinates
			}

			document.removeEventListener('click', this.handleClickOutside);
			this.$nextTick(() => {
        setTimeout(() => {
          document.addEventListener('click', this.handleClickOutside);
        }, 0)
      })
		},
    adjustPopupPosition(top, left) {
      const popupWidth = 300;
      const popupHeight = 210;
      const { innerWidth, innerHeight } = window;
      if (parseInt(left) + popupWidth > innerWidth) {
        left = `${innerWidth - popupWidth - 10}px`;
      }
      if (parseInt(top) + popupHeight > innerHeight) {
        top = `${innerHeight - popupHeight - 10}px`;
      }
      this.popupPosition = { top, left };
    },
		duplicateSchedule(){
			if(this.selectedEventUserInfo){
				this.selectedEvent.user_name = this.selectedEventUserInfo.user_name
				this.selectedEvent.user_email = this.selectedEventUserInfo.user_email
				this.selectedEvent.user_phone = this.selectedEventUserInfo.user_phone
			}
			const duplicate = {...this.selectedEvent, ...{id: null}}
			this.$store.commit('schedule/setNewScheduleEditing', duplicate)
			this.$store.commit('schedule/setNewScheduleModal', true)
			this.closePopup()
		},
		toActionSchedule(){
			if(this.selectedEventUserInfo){
				this.selectedEvent.user_name = this.selectedEventUserInfo.user_name
				this.selectedEvent.user_email = this.selectedEventUserInfo.user_email
				this.selectedEvent.user_phone = this.selectedEventUserInfo.user_phone
			}
			this.$store.commit('schedule/setNewScheduleEditing', this.selectedEvent)
			this.$store.commit('schedule/setNewScheduleModal', true)
			this.closePopup()
		},
		toSimpleAgenda(){
			this.$router.push({ name: 'Conversation', params: { id: this.selectedEvent.simple_agenda.bot_conversation_id } })
		},
    closePopup() {
      this.showPopup = false;
      this.popupPosition = { top: '0px', left: '0px' }
      this.selectedEvent = {}
			document.removeEventListener('click', this.handleClickOutside);
    },
    changeMonth() {
      const calendarApi = this.$refs.calendar.getApi();
      const newDate = new Date(new Date().getFullYear(), this.selectedMonth - 1, 1);
      calendarApi.gotoDate(newDate);
    },
		async onColorSelect(color){
			this.color = color
			this.$refs.menuColors.save(color)
			await updateUserSchedule({schedule_id: this.selectedEvent.id, color})
			this.$store.dispatch('schedule/getUserSchedule')
		},
		async deleteSchedule(){
			await deleteUserSchedule({schedule_id: this.selectedEvent.id})
			this.closePopup()
			this.$store.dispatch('schedule/getUserSchedule')
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
	}
}
</script>

<style>
.holiday-cell {
  background-color: rgba(255, 0, 0, 0.2)!important;
}
.month-select-custom{
	position: absolute;
	top: 40px;
	right: 0;
	z-index: 999;
}
.custom-height-calendar .v-input__control,
.custom-height-calendar .v-field__input,
.custom-height-calendar .v-input__slot,
.custom-height-calendar .v-select__slot {
  height: 30px!important;
	min-height: 30px!important;
}
.custom-height-calendar .v-input__append-inner{
	margin-top: 3px!important;
}
.custom-calendar-sidemini .v-date-picker-table{
	height: auto;
}

.popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	min-width: 300px;
	max-width: 500px;
	min-height: 130px;
}
div.fullcalendar-schedule-custom a{
	color: #959595;
}
.custom-title-schedule{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-height-search label{
	font-size: 12px;
	top: 5px!important;
}
/* .custom-height-search .v-progress-linear .v-progress-linear__background {
  background-color: #FF120B;
  color: #FF120B;
} */
.custom-height-search .v-progress-linear__indeterminate {
  background-color: #FF120B!important; /* Cambia este color */
}
.calendar-mobile{
	zoom: 50%;
}
.calendar-mobile .fc table,
.calendar-mobile .fc .fc-timegrid-body,
.calendar-mobile .fc .fc-daygrid-body{
	width: 100%!important;
}
.google-maps-custom{
	width: 100%;
	height: 100%;
}
.custom-go-to-maps{
	position: absolute;
	top: 55px;
	left: 10px;
	z-index: 99;
}
.fullcalendar-schedule-custom .fc-timegrid-event-harness{
	min-height: 85px;
}
</style>