import {endpoints} from './endpoints'
import request from '../request'

export function confirmSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.confirmSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function closeSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.closeSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function sendReportSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendReportSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getScheduleGeo (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getScheduleGeo,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserSchedule,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getUserSchedules (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getUserSchedules,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function createUserSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createUserSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateUserSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateUserSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function deleteUserSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteUserSchedule,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateScheduleAddress (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateScheduleAddress,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function redirectToGoogle (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.redirectToGoogle,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getEvents (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getEvents,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getHolidays (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getHolidays,
			method: 'get',
			params: data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function sendSingleEmailForSchedule (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendSingleEmailForSchedule,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function removeGoogleCalendarAssociation (data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.removeGoogleCalendarAssociation,
			method: 'delete',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}