<template>
  <div id="google_translate_element" class="my-0" ></div>
</template>

<script>
export default {
  name: "GoogleTranslate",
  mounted() {
    // Carga el script de Google Translate
    const script = document.createElement("script");
    script.src =
      "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "es", // Idioma original de tu página
          includedLanguages: "en,es,fr,de,it", // Idiomas disponibles
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google_translate_element"
      );
    };
  },
	methods:{
	}
};
</script>

<style scoped>
/* Personaliza el estilo del widget, si es necesario */
#google_translate_element {
  margin: 10px 0;
}
</style>
<style>
  body > .skiptranslate > iframe {
    display: none !important;
		/* position: absolute !important;
		z-index: -22 !important; */
  }
  body {
    top: 0 !important;
  }
	#google_translate_element{
		width: 100%;
	}
</style>