<template>
  <v-container fluid>
		<v-data-table
			:headers="headers"
			:search="search"
			item-key="id"
			:loading="$store.state.companies.loadingCompaniesProjectTypes"
			loading-text="Cargando datos..."
			:footer-props="{
				itemsPerPageText: 'Filas por página'
			}"
			:items-per-page="-1"
			hide-default-footer
		>
			<template v-slot:body>
				<draggable
					v-model="$store.state.companies.companiesProjectTypes"
					tag="tbody"
					item-key="id"
					@end="startEndDragEvent"
				>
					<tr
						v-for="item in $store.state.companies.companiesProjectTypes"
						:key="item.id"
						style="cursor: pointer;"
					>
						<td style="cursor: pointer;">{{ item.name }}</td>
						<td style="cursor: pointer;">{{ item.description }}</td>
						<td style="cursor: pointer;">{{ item.code }}</td>
						<td>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										fab
										dark
										x-small
										color="warning"
										class="mx-1"
										v-if="$checkPermissions(['edit_company_project_types'])"
										@click="editProjectType(item)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small dark>mdi-pencil-outline</v-icon>
									</v-btn>
								</template>
								<span>Editar</span>
							</v-tooltip>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										fab
										dark
										x-small
										color="error"
										class="mx-1"
										v-if="$checkPermissions(['delete_company_project_types'])"
										@click="deleteProjectType(item.id)"
										v-bind="attrs"
										v-on="on"
									>
										<v-icon small dark>mdi-delete-outline</v-icon>
									</v-btn>
								</template>
								<span>Eliminar</span>
							</v-tooltip>
						</td>
					</tr>
				</draggable>
			</template>
		</v-data-table>
  </v-container>
</template>

<script>
import {deleteProjectType, updateProjectTypeOrders} from '@/helpers/api/company'
import draggable from "vuedraggable"
import _debounce from 'lodash/debounce'

export default {
  components: {
    draggable,
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Descripción', value: 'description' },
        { text: 'Código', value: 'code' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('companies/getCompanyProjectTypes')
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('companies/getCompanyProjectTypes')
		}
	},
  methods: {
		startEndDragEvent: _debounce(function(ev){ this.endDragEvent(ev) }, 800),
		async endDragEvent(ev){
			if (typeof ev.newIndex === 'number') {
				const project_types = this.$store.state.companies.companiesProjectTypes.map((project_type, index) => {
					return { order: index + 1, id: project_type.id}
				})
				await updateProjectTypeOrders({project_types})
				this.$store.dispatch('companies/getCompanyProjectTypes')
			}
		},
    addProjectType() {
			this.$store.commit('companies/setNewProjectTypeModalEditing', null)
			this.$store.commit('companies/setNewProjectTypeModal', true)
    },
    editProjectType(project_type) {
			const data = {
				project_type_id: project_type.id,
				name: project_type.name,
				description: project_type.description,
				code: project_type.code
			}
			this.$store.commit('companies/setNewProjectTypeModalEditing', data)
			this.$store.commit('companies/setNewProjectTypeModal', true)
    },
    async deleteProjectType(project_type_id) {
      await deleteProjectType({project_type_id})
			this.$store.dispatch('companies/getCompanyProjectTypes')
    }
  },
};
</script>

<style scoped>
</style>