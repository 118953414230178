<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="contracts"
      :search="search"
      item-key="id"
      :loading="loading"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.project="{ item }">
				<p class="mb-0">
					{{ item.morphable?.name }}
				</p>
			</template>
      <template v-slot:item.client="{ item }">
				<p class="mb-0">
					{{ item.morphable?.client?.profile?.name }} {{ item.morphable?.client?.profile?.lastname }}
				</p>
			</template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom v-if="$store.state.user.userInfo">
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							fab
							dark
							x-small
							color="warning"
							class="mx-1"
							@click="toContractDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {getCompanyContracts} from '@/helpers/api/contracts'
export default {
  data() {
    return {
      search: '',
			contracts: [],
			loading: true,
      headers: [
        { text: 'Proyecto', value: 'project' },
        { text: 'Cliente', value: 'client' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		}
  },
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.getCompanyContracts()
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.getCompanyContracts()
		}
	},
  methods: {
		getCompanyProjects(){
			this.$store.dispatch('projects/getProjects')
		},
		toContractDetail(contract){
			this.$router.push({ name: 'ContractDetail', query: { id: contract.id } })
		},
		async getCompanyContracts(){
			this.loading = true
			const contracts = await getCompanyContracts()
			if(contracts.code == 200){
				this.contracts = contracts.contracts
			}
			this.loading = false
		}
  },
};
</script>

<style scoped>
</style>