import { getCompanyContractSections, getCompanyContractSectionCategories } from "@/helpers/api/contracts"

const ChatStore = {
  namespaced: true,
  state: {
		loadingCompanyContracts: false,
		companyContracts: [],
		loadingCompanyContractCategories: false,
		companyContractCategories: [],
		modalCategory: false,
		modalCategoryEditing: null,
		modalSection: false,
		modalSectionEditing: null,
		modalContract: false,
  },
  mutations: {
    setLoadingCompanyContracts(state, status) {
      state.loadingCompanyContracts = status
    },
    setCompanyContracts(state, companyContracts) {
      state.companyContracts = companyContracts
    },
    setLoadingCompanyContractCategories(state, status) {
      state.loadingCompanyContractCategories = status
    },
    setCompanyContractCategories(state, cats) {
      state.companyContractCategories = cats
    },
    setModalCategory(state, status) {
      state.modalCategory = status
    },
    setModalCategoryEditing(state, value) {
      state.modalCategoryEditing = value
    },
    setModalSection(state, status) {
      state.modalSection = status
    },
    setModalSectionEditing(state, value) {
      state.modalSectionEditing = value
    },
    setModalContract(state, status) {
      state.modalContract = status
    },
  },
  actions: {
    async getCompanyContractsConfig({ commit }, data) {
      commit('setLoadingCompanyContracts', true)
      const section = await getCompanyContractSections(data)
      if (section.code === 200) {
        commit('setCompanyContracts', section.contract_sections)
      }
      commit('setLoadingCompanyContracts', false)
    },
    async getCompanyContractCategoriesConfig({ commit }, data) {
      commit('setLoadingCompanyContractCategories', true)
      const categories = await getCompanyContractSectionCategories(data)
      if (categories.code === 200) {
        commit('setCompanyContractCategories', categories.contract_categories)
      }
      commit('setLoadingCompanyContractCategories', false)
    }
  }
}

export default ChatStore
