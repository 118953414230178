<template>
    <v-data-table
      :headers="headers"
      :items="client_crms"
      item-key="id"
      :loading="loadingList"
			height="200px"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.date="{ item }">
				<p class="mb-0 caption">{{ item.created_at | toDateTime }}</p>
      </template>
      <template v-slot:item.company="{ item }">
				<p class="mb-0 caption">{{ item.company.name }}</p>
      </template>
      <template v-slot:item.vt_date="{ item }">
				<p class="mb-0 caption">{{ item.schedule?.vt_date | toDateTime }}</p>
      </template>
      <template v-slot:item.responsible="{ item }">
				<p class="mb-0 caption">{{ item.schedule?.user_schedule?.responsible?.profile?.name }} {{ item.schedule?.user_schedule?.responsible?.profile?.lastname }}</p>
      </template>
      <template v-slot:item.status="{ item }">
				<v-chip
					class="mt-1"
					:color="vtStatus(item).color"
					x-small
					label
					dark
					v-if="vtStatus(item)"
				>
					<v-icon class="mr-1" x-small>mdi-email-outline</v-icon>
					{{vtStatus(item).text}}
				</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openCrmDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
</template>

<script>
import {getClientCRM} from '@/helpers/api/clients'
export default {
	name: 'ClientsCrmList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openCrmDetail: {
      type: Function,
      required: true
    }
	},
	data: () => ({
		loadingList: true,
		client_crms: [],
		headers: [
        { text: '#', value: 'id', align: 'center', sortable: false },
        { text: 'Empresa', value: 'company', align: 'center', sortable: false },
        { text: 'Fecha de Creación', value: 'date', width: '120px', align: 'center', sortable: false },
        { text: 'Visita Técnica', value: 'vt_date', width: '120px', align: 'center', sortable: false },
        { text: 'Profesional Encargado', value: 'responsible', align: 'center', sortable: false },
        { text: 'Estado', value: 'status', align: 'center', sortable: false },
        { text: 'Acciones', value: 'actions', align: 'center', sortable: false },
      ],
	}),
	mounted(){
		this.getClientCRM()
	},
	methods: {
		vtStatus(conversation){
			if(conversation.schedule && conversation.schedule.status == 2 && conversation.schedule.request_email.length > 0){
				return {
					text: 'Informe Enviado',
					color: 'success'
				}
			}
			else if(conversation.schedule){
				return {
					text: 'Informe Pendiente',
					color: 'warning'
				}
			}
			return null
		},
		async getClientCRM(){
			this.loadingList = true
			try {
				const client_crm = await getClientCRM({
					client_id: this.clientId,
					start: 1,
					limit: 100
				})
				if(client_crm.code == 200){
					this.client_crms = client_crm.client_crm
				}
			} catch (error) {
				console.log(error)
			}
			this.loadingList = false
		},
		toPreProjectsStart(crm){
			const preproject_info = {
				crm_id: crm.id,
				client_id: crm.user.user_id,
				name: crm.project_info?.project_detail
			}
			localStorage.setItem('new_preproject_info', JSON.stringify(preproject_info));
			this.$store.commit('preprojects/setNewPreProjectModal', true)
		},
	}
}
</script>

<style>

</style>