import { getAllProjectPlanningCategories, getAllZoneIpts, getCompanyProjects } from "@/helpers/api/projects"

const ProjectsStore = {
  namespaced: true,
  state: {
		loadingProjectPlanningCategories: false,
		projectPlanningCategories: null,
		categoryModalEditInfo: null,
		categoryModal: null,
		loadingZoneIpt: false,
		zoneIpt: null,
		zoneIptModalEditInfo: null,
		zoneIptModal: false,
		toCopyCompanyPlanningCategoryId: null,
		projectModal: false,
		projectModalInfo: null,
		companyProjects: [],
		loadingCompanyProjects: null
  },
  mutations: {
		setLoadingProjectPlanningCategories(state, status){
			state.loadingProjectPlanningCategories = status
		},
		setProjectPlanningCategories(state, categories){
			state.projectPlanningCategories = categories
		},
		setLoadingZoneIpt(state, status){
			state.loadingZoneIpt = status
		},
		setZoneIpt(state, zones){
			state.zoneIpt = zones
		},
		setCategoryModal(state, categoryModal){
			state.categoryModal = categoryModal
		},
		setCategoryModalEditInfo(state, info){
			state.categoryModalEditInfo = info
		},
		setZoneIptModal(state, zoneIptModal){
			state.zoneIptModal = zoneIptModal
		},
		setZoneIptModalEditInfo(state, info){
			state.zoneIptModalEditInfo = info
		},
		setToCopyCompanyPlanningCategoryId(state, info){
			state.toCopyCompanyPlanningCategoryId = info
		},
		setProjectModal(state, info){
			state.projectModal = info
		},
		setProjectModalInfo(state, info){
			state.projectModalInfo = info
		},
		setCompanyProjects(state, info){
			state.companyProjects = info
		},
		setLoadingCompanyProjects(state, info){
			state.loadingCompanyProjects = info
		},
  },
  actions: {
    getProjects({ commit }) {
      getCompanyProjects()
				.then((projects) => {
					commit('setLoadingCompanyProjects', true)
					if (projects.code === 200) {
						commit('setCompanyProjects', projects.projects)
					}
					commit('setLoadingCompanyProjects', false)
				})
				.catch(() => {
					commit('setCompanyProjects', [])
					commit('setLoadingCompanyProjects', false)
				})
    },
    getAllZoneIpts({ commit }) {
      getAllZoneIpts()
				.then((zones) => {
					commit('setLoadingZoneIpt', true)
					if (zones.code === 200) {
						commit('setZoneIpt', zones.zone_ipts)
					}
					commit('setLoadingZoneIpt', false)
				})
				.catch(() => {
					commit('setZoneIpt', null)
					commit('setLoadingZoneIpt', false)
				})
    },
    getAllProjectPlanningCategories({ commit }, { zone_id = 1, project_type_id = 1 } = {}) {
      getAllProjectPlanningCategories({ zone_id, project_type_id})
				.then((categories) => {
					commit('setLoadingProjectPlanningCategories', true)
					if (categories.code === 200) {
						commit('setProjectPlanningCategories', categories.project_planning_categories)
					}
					commit('setLoadingProjectPlanningCategories', false)
				})
				.catch(() => {
					commit('setProjectPlanningCategories', null)
					commit('setLoadingProjectPlanningCategories', false)
				})
    }
  }
}

export default ProjectsStore
