<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.general.publicProfileModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-pub</v-icon>
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Perfil Público</p>
									<p class="mb-0 text-primary caption">Los datos e imágenes que agregues a este perfil se utilizarán cuando desees compartir el proyecto en tus redes sociales</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row class="mb-4">
                <v-col cols="12" md="2" class="py-0">
									<div
										class="profile-avatar mb-3"
										@click="triggerFileInput"
									>
										<img :src="imageUrl" class="profile-image" v-if="imageUrl"/>
										<v-icon v-else class="profile-image icon" color="#524D4D" x-large>mdi-image</v-icon>
										<div class="logo-text">Modificar Imagen</div>
									</div>
                  <v-file-input
										ref="fileInput"
                    v-model="publicProfile.image"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione imagen pública"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
										style="display: none;"
										@change="onFileSelected"
                  ></v-file-input>
								</v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    v-model="publicProfile.title"
                    label="Título"
                    placeholder="Ingrese título Perfil Público"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <v-text-field
                    v-model="publicProfile.short_description"
                    label="Descripción Corta"
                    placeholder="Ingrese descripción corta"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
								<v-col cols="12">
									<div v-if="loadingMce" class="d-flex flex-column justify-center align-center" style="min-height: 200px;">
										<v-skeleton-loader
											style="width: 250px;"
											class="mb-1"
											type="paragraph"
										></v-skeleton-loader>
										<p>Cargando editor...</p>
									</div>
									<vue2-tinymce-editor
										v-else
										:inline="true"
										v-model="publicProfile.description"
										:options="{ menubar: false }" ref="tinymceEditor"></vue2-tinymce-editor>
								</v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Vue2TinymceEditor } from "vue2-tinymce-editor"
import {updatePublicProfile} from '@/helpers/api/general'

export default {
  name: 'SocialProfile',
  components: { Vue2TinymceEditor },
  data: () => ({
    validform: true,
		loadingMce: true,
		imageUrl: null,
    publicProfile: {
			item_type: 'preproject',
			item_id: null,
			image: null,
			title: '',
			description: null,
			short_description: null
    },
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
  },
  mounted(){
		if(this.$store.state.general.publicProfileModalEdditing){
			this.publicProfile = {
				...this.$store.state.general.publicProfileModalEdditing,
				...{
					item_type: this.$store.state.general.publicProfileModalEdditing.morphable_type,
					item_id: this.$store.state.general.publicProfileModalEdditing.morphable_id
				}
			}
		}
		if(this.publicProfile){
			this.imageUrl = this.publicProfile.image_link
		}
		this.$nextTick(() => {
				this.loadingMce = false
		});
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    onFileSelected(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.imageUrl = e.target.result
        };
        reader.readAsDataURL(file)
      }
    },
    closeModal(){
      this.$store.commit('general/setPublicProfileModal', false)
      this.$store.commit('general/setPublicProfileModalEdditing', null)
      this.publicProfile = {
				item_type: 'preproject',
				item_id: null,
				image: null,
				title: '',
				description: null,
				short_description: null
      }
			this.loadingMce = true
      this.validform = true
      this.loadingData = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        let publicProfile = this.publicProfile
        Object.keys(publicProfile).forEach((key) => {
          if(publicProfile[key]) {
            formData.append(key, publicProfile[key])
          }
        })
        const profile = await updatePublicProfile(formData)
        if(profile.code === 200){
          const messageData = {
            message: 'Perfil público actualizado correctamente',
            title: 'Perfil actualizado',
            type: 'success',
            icon: 'mdi-check-circle',
            color: '#3EBFA3',
          }
					this.$store.dispatch('users/getUserFromCompany')
          this.setMessage(messageData)
          this.closeModal()
        }
        else if(profile.data){
          profile.data.forEach(message => {
            const messageData = {
              message,
              title: 'Error',
              type: 'danger',
              icon: 'mdi-close-octagon',
              color: '#F4516C',
            }
            this.setMessage(messageData)
          })
        }
        this.loadingData = false
      }
    },
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
		width: 200px;
	}
	.profile-image{
		width: 100%;
		height: 150px;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding: 5px 10px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>