<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.state.projects.categoryModal"
      max-width="450"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <!-- <v-icon color="white" small class="info-icon mr-2">mdi-pub</v-icon> -->
								<div>
									<p class="mb-0 font-weight-bold text-primary title">Etapa</p>
								</div>
              </div>
              <v-btn class="mx-2" fab dark x-small color="#F4516C" @click="closeModal">
                <v-icon dark small>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
							<div class="d-flex align-center justify-space-between mb-4">
								<div v-if="isGeneralStage">
									<p class="mb-0 font-weight-bold caption">Etapa Para Zona</p>
									<p class="mb-0" style="font-size: 10px">Etapa general para zona de proyecto</p>
								</div>
								<div v-else>
									<p class="mb-0 font-weight-bold caption">Etapa Específica</p>
									<p class="mb-0" style="font-size: 10px">Para la Zona y Tipo de Proyecto seleccionados</p>
								</div>
								<div class="d-flex flex-column align-center justify-center">
									<p class="mb-0 font-weight-bold" style="font-size: 10px">Tipo de Etapa</p>
									<v-switch
										class="mt-0"
										color="#FF120B"
										dense
										hide-details
										v-model="isGeneralStage"
									></v-switch>
								</div>
							</div>
							<v-text-field
								label="Nombre Etapa"
								v-model="newCategory.name"
								outlined
								hide-details
								required
								dense
								class="mb-2"
								:rules="requiredRule"
							></v-text-field>
							<v-text-field
								label="Descripción Etapa (Opcional)"
								v-model="newCategory.description"
								outlined
								hide-details
								required
								dense
								class="mb-2"
							></v-text-field>

              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      color="#524D4D"
                      raised
                      dark
                      :loading="loadingCreateCategory"
                      @click="confirmCategory"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {createProjectPlanningCategory, updateProjectPlanningCategory} from '@/helpers/api/projects'

export default {
  name: 'NewProjectCategory',
	props: {
		reload: {
      type: Function,
      required: true
    }
	},
  data: () => ({
    validform: true,
		loadingCreateCategory: false,
		isGeneralStage: false,
		newCategory: {
			name: null,
			description: null,
			order: 1
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ]
  }),
  computed: {
  },
  mounted(){
		if(this.$store.state.projects.categoryModalEditInfo){
			this.newCategory = {
				...this.newCategory,
				...this.$store.state.projects.categoryModalEditInfo
			}
			console.log(this.newCategory)
			this.isGeneralStage = !!this.newCategory.general_stage
		}
  },
  methods: {
    closeModal(){
      this.$store.commit('projects/setCategoryModal', false)
      this.$store.commit('projects/setCategoryModalEditInfo', null)
			this.newCategory = {
				name: null,
				description: null,
				order: 1
			}
      this.validform = true
      this.loadingCreateCategory = false
    },
		async confirmCategory(){
			this.loadingCreateCategory = true
			this.newCategory.general_stage = this.isGeneralStage
			let category
			if(this.isGeneralStage){
				this.newCategory.project_type_id = null
			}
			console.log(this.newCategory)
			if (this.newCategory.project_planning_category_id) {
				category = await updateProjectPlanningCategory(this.newCategory);
			} else {
				category = await createProjectPlanningCategory(this.newCategory);
			}
			if(category.code == 200){
				this.reload()
				this.closeModal()
			}
			this.loadingCreateCategory = false
		},
    setMessage(messageData){
      const data = {
        message: messageData.message,
        title: messageData.title,
        created: new Date(),
        type: messageData.type,
        icon: messageData.icon,
        color: messageData.color,
      }
      this.$store.dispatch('general/addMessage', data)
    },
  }
};
</script>

<style scoped>
	.profile-avatar{
		cursor: pointer;
		position: relative;
		width: 200px;
	}
	.profile-image{
		width: 100%;
		height: 150px;
	}
	.logo-text{
		position: absolute;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		color: white;
		font-size: 12px;
		padding: 5px 10px;
	}
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #524D4D;
  }
  .info-icon{
    background-color: #524D4D;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #959595;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>