<template>
	<div>
    <v-data-table
      :headers="headers"
      :items="client_preprojects"
      item-key="id"
      :loading="loadingList"
			height="200px"
      loading-text="Cargando datos..."
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
    >
      <template v-slot:item.name="{ item }">
				<p class="caption mb-0">{{ item.name }}</p>
      </template>
      <template v-slot:item.company="{ item }">
				<p class="mb-0 caption">{{ item.company.name }}</p>
      </template>
			<template v-slot:item.entity="{ item }">
				<div>
					<p class="caption mb-0">{{item.info?.address?.district?.name ?? '--'}}</p>
				</div>
			</template>
			<template v-slot:item.responsible="{ item }">
				<div>
					<p class="mb-0 caption">{{item.responsible?.profile?.name ?? '--'}}</p>
				</div>
			</template>
      <template v-slot:item.documents="{ item }">
				<p class="mb-0 caption">{{ item.documents.length == 0 ? '--':getDocumentDate(item) }}</p>
      </template>
      <template v-slot:item.status="{ item }">
				<v-chip
					class="mt-1"
					:color="getDocumentsStatus(item).color"
					x-small
					label
					dark
					v-if="getDocumentsStatus(item)"
				>
					{{getDocumentsStatus(item).label}}
				</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="openPreProjectDetail(item.id)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
      </template>
    </v-data-table>
	</div>
</template>

<script>
import moment from 'moment'
import {getClientPreProjects} from '@/helpers/api/clients'
export default {
	name: 'ClientsPreProjectList',
	props: {
		clientId: {
      type: Number,
      required: true
    },
		openPreProjectDetail: {
      type: Function,
      required: true
    }
	},
	data: () => ({
		loadingList: true,
		client_preprojects: [],
		headers: [
        { text: '#', value: 'id', align: 'center', sortable: false },
        { text: 'Empresa', value: 'company', align: 'center', sortable: false },
        { text: 'Nombre Proyecto', value: 'name', align: 'center', sortable: false },
        { text: 'Municipalidad', value: 'entity', align: 'center', sortable: false },
        { text: 'Fecha Solicitud Desarchivo', value: 'documents', align: 'center', sortable: false },
        { text: 'Profesional Encargado', value: 'responsible', align: 'center', sortable: false },
        { text: 'Estado Antecedentes', value: 'status', align: 'center', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
	}),
	mounted(){
		this.getClientPreProjects()
		const preproject_id = this.$route.query.p
		if(preproject_id){
			this.openPreProjectDetail(preproject_id)
		}
	},
	methods: {
		async getClientPreProjects(){
			this.loadingList = true
			try {
				const client_preproject = await getClientPreProjects({
					client_id: this.clientId,
					start: 1,
					limit: 100
				})
				if(client_preproject.code == 200){
					this.client_preprojects = client_preproject.client_preprojects
				}
			} catch (error) {
				console.log(error)
			}
			this.loadingList = false
		},
		getDocumentDate(item){
			return this.$options.filters.toDateTime( item.documents.reduce((min, obj) => obj.created_at < min ? obj.created_at : min, item.documents[0].created_at) )
		},
		getDocumentsStatus(item){
			const all_delivered = item.documents.every((document) => document.delivered_date)
			const some_delayed = item.documents.some((document) => !document.delivered_date && document.max_date && moment().isAfter(document.max_date))
			if(all_delivered){
				return {label: 'Entregado', color: 'green'}
			}
			else if (some_delayed){
				return {label: 'Atrasados', color: 'red'}
			}
			return {label: 'Solicitados', color: 'orange'}
		},
	}
}
</script>

<style>

</style>