const endpoints = {
	createProjectPlanning: '/projects/planning/createProjectPlanning',
	setProjectPlanningCategory: '/projects/planning/setProjectPlanningCategory',
	removeProjectPlanning: '/projects/planning/removeProjectPlanning',
	createProjectPlanningCategory: '/projects/planning/createProjectPlanningCategory',
	updateProjectPlanningCategory: '/projects/planning/updateProjectPlanningCategory',
	createProjectPlanningStage: '/projects/planning/createProjectPlanningStage',
	updateProjectPlanningStage: '/projects/planning/updateProjectPlanningStage',
	updateProjectPlanningStageOrder: '/projects/planning/updateProjectPlanningStageOrder',
	deleteProjectPlanningCategory: '/projects/planning/deleteProjectPlanningCategory',
	deleteProjectPlanningStage: '/projects/planning/deleteProjectPlanningStage',
	getAllProjectPlanningCategories: '/projects/planning/getAllProjectPlanningCategories',
	getAllProjectPlanningStages: '/projects/planning/getAllProjectPlanningStages',
	createZoneIpt: '/projects/planning/createZoneIpt',
	updateZoneIpt: '/projects/planning/updateZoneIpt',
	deleteZoneIpt: '/projects/planning/deleteZoneIpt',
	getAllZoneIpts: '/projects/planning/getAllZoneIpts',
	copyProjectPlaningStage: '/projects/planning/copyProjectPlaningStage',
	createProject: '/projects/createProject',
	getCompanyProjects: '/projects/getCompanyProjects',
	getUserProjects: '/projects/getUserProjects',
	createProjectPlanningPrj: '/projects/project_planning/createProjectPlanning',
	setProjectPlanningCategoryPrj: '/projects/project_planning/setProjectPlanningCategory',
	updateProjectPlanningCategoryPrj: '/projects/project_planning/updateProjectPlanningCategory',
	createProjectPlanningStagePrj: '/projects/project_planning/createProjectPlanningStage',
	updateProjectPlanningStagePrj: '/projects/project_planning/updateProjectPlanningStage',
	updateProjectPlanningStageOrderPrj: '/projects/project_planning/updateProjectPlanningStageOrder',
	getProjectPlanningPrj: '/projects/project_planning/getProjectPlanning',
	removeProjectPlanningPrj: '/projects/project_planning/removeProjectPlanning',
	removeProjectPlanningCategoryPrj: '/projects/project_planning/removeProjectPlanningCategory',
	removeProjectPlanningStage: '/projects/project_planning/removeProjectPlanningStage',
}
export {endpoints}