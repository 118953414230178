<template>
	<v-container fluid class="crm-index">
		<v-data-table
			ref="table"
			:headers="headers"
			:items="$store.state.preprojects.archivedPreProjects"
			:search="search"
			item-key="id"
			:loading="$store.state.preprojects.loadingPreProjects"
			loading-text="Cargando datos..."
			class="crm-table"
			:class="$vuetify.breakpoint.mobile ? '':'resizable'"
			disable-sort
      :hide-default-header="!$vuetify.breakpoint.mobile"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
		>
			<template v-slot:header="{ props }" v-if="!$vuetify.breakpoint.mobile">
				<thead class="v-data-table-header">
					<tr>
						<th role="columnheader" scope="col" aria-label="#" class="header-cell"
							:style="{
								width: head.width ? head.width + 'px' : 'auto',
								maxHeight: ['unarchive','instalations','cbr','sii'].includes(head.value) ? null : '30px',
								height: ['unarchive','instalations','cbr','sii'].includes(head.value) ? null : '25px',
								padding: ['unarchive','instalations','cbr','sii'].includes(head.value) ? '0' : null
							}"
							:class="head.align == 'center' ? 'text-center':''"
							v-for="(head,index) in props.headers" :key="index"
							@mouseup="saveColumnWidth(index)"
						>
							<div v-if="head.value == 'unarchive'">
								<div class="pa-1 text-center">
									{{ head.text }}
								</div>
								<div class="d-flex">
									<div
										style="flex: 1;"
										v-for="(tooltip, i) in unarchive"
										:key="i"
										class="text-center subcolumn-header"
										:class="{
											'no-border': i === unarchive.length - 1
										}"
									>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">{{ tooltip.text }}</div>
											</template>
											<span>{{ tooltip.label }}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<div v-else-if="head.value == 'instalations'">
								<div class="pa-1 text-center">
									{{ head.text }}
								</div>
								<div class="d-flex">
									<div
										style="flex: 1;"
										v-for="(tooltip, i) in instalations"
										:key="i"
										class="text-center subcolumn-header"
										:class="{
											'no-border': i === instalations.length - 1
										}"
									>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">{{ tooltip.text }}</div>
											</template>
											<span>{{ tooltip.label }}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<div v-else-if="head.value == 'cbr'">
								<div class="pa-1 text-center">
									{{ head.text }}
								</div>
								<div class="d-flex">
									<div
										style="flex: 1;"
										v-for="(tooltip, i) in cbr"
										:key="i"
										class="text-center subcolumn-header"
										:class="{
											'no-border': i === cbr.length - 1
										}"
									>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">{{ tooltip.text }}</div>
											</template>
											<span>{{ tooltip.label }}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<div v-else-if="head.value == 'sii'">
								<div class="pa-1 text-center">
									{{ head.text }}
								</div>
								<div class="d-flex">
									<div
										style="flex: 1;"
										v-for="(tooltip, i) in sii"
										:key="i"
										class="text-center subcolumn-header"
										:class="{
											'no-border': i === sii.length - 1
										}"
									>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">{{ tooltip.text }}</div>
											</template>
											<span>{{ tooltip.label }}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<div v-else-if="head.value == 'sec'">
								<div class="pa-1 text-center">
									{{ head.text }}
								</div>
								<div class="d-flex">
									<div
										style="flex: 1;"
										v-for="(tooltip, i) in sec"
										:key="i"
										class="text-center subcolumn-header"
										:class="{
											'no-border': i === sec.length - 1
										}"
									>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">{{ tooltip.text }}</div>
											</template>
											<span>{{ tooltip.label }}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
							<span v-else>{{ head.text }}</span>
						</th>
					</tr>
				</thead>
			</template>
			<template v-slot:item.client="{ item }">
				<div><b>{{ item.client && item.client.profile?.name ? item.client.profile?.name:'No identificado'}}</b></div>
				<div v-if="item.client.profile">{{ item.client.profile ? item.client.profile?.email:'No identificado'}}</div>
			</template>
			<template v-slot:item.address="{ item }">
				<div><b>{{ item.info ? item.info.address?.address:'Sin registro'}}</b></div>
			</template>
			<template v-slot:item.rol="{ item }">
				<div><b>{{ item.info ? item.info.address?.rol:'Sin registro'}}</b></div>
			</template>
			<template v-slot:item.project_name="{ item }">
				<div><b>{{ item.name }}</b></div>
			</template>
			<template v-slot:item.entity="{ item, index }">
				<div>
					<b>{{item.info?.address?.district?.name ?? '--'}}</b>
					<v-menu :ref="'cityMenu-' + index" offset-y z-index="9999" :close-on-content-click="false" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Municipalidad</p>
							</v-list-item>
							<v-list-item dense class="mb-1 px-1" style="min-height: 25px;">
								<v-text-field
									v-model="searchCity"
									label="Filtro"
									placeholder="Búsqueda"
									outlined
									required
									dense
									hide-details
									class="custom-height-search custom-height-calendar"
									style="max-width: 160px;"
								></v-text-field>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(city, index) in cities"
									:key="index"
									dense
									@click="changePreProjectCity(item, city.id)"
								>
									<v-list-item-title dense>
										{{ city.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.unarchive="{ item }">
				<td class="pa-0">
					<div class="d-flex fill-height align-stretch">
						<div
							style="flex: 1;"
							v-for="(un, i) in unarchive"
							:key="i"
							class="text-center subcolumn"
							:class="{
								'no-border': i === unarchive.length - 1
							}"
						>
							<v-tooltip bottom content-class="tooltip-arc-status">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="status(item, un.id)"
										:color="status(item, un.id)?.color"
										v-bind="attrs"
										v-on="on"
										small>{{ status(item, un.id)?.icon }}</v-icon>
									<div v-else>-</div>
								</template>
								<div v-if="status(item, un.id)">
									<p class="mb-0 caption font-weight-bold">{{ status(item, un.id)?.label }}</p>
									<p class="mb-0 caption">{{status(item, un.id)?.label == 'Entregado' ? 'Entregado':'Solicitado'}}: {{getPreProjectDocument(item, un.id).delivered_date ?? getPreProjectDocument(item, un.id).request_date | toDate}}</p>
								</div>
							</v-tooltip>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:item.cbr="{ item }">
				<td class="pa-0">
					<div class="d-flex fill-height align-stretch">
						<div
							style="flex: 1;"
							v-for="(cbr_i, i) in cbr"
							:key="i"
							class="text-center subcolumn"
							:class="{
								'no-border': i === cbr.length - 1
							}"
						>
							<v-tooltip bottom content-class="tooltip-arc-status">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="status(item, cbr_i.id)"
										:color="status(item, cbr_i.id).color"
										v-bind="attrs"
										v-on="on"
										small>{{ status(item, cbr_i.id).icon }}</v-icon>
									<div v-else>-</div>
								</template>
								<div v-if="status(item, cbr_i.id)">
									<p class="mb-0 caption font-weight-bold">{{ status(item, cbr_i.id).label }}</p>
									<p class="mb-0 caption">{{status(item, cbr_i.id).label == 'Entregado' ? 'Entregado':'Solicitado'}}: {{getPreProjectDocument(item, cbr_i.id).delivered_date ?? getPreProjectDocument(item, cbr_i.id).request_date | toDate}}</p>
								</div>
							</v-tooltip>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:item.instalations="{ item }">
				<td class="pa-0">
					<div class="d-flex fill-height align-stretch">
						<div
							style="flex: 1;"
							v-for="(instalation, i) in instalations"
							:key="i"
							class="text-center subcolumn"
							:class="{
								'no-border': i === instalations.length - 1
							}"
						>
							<v-tooltip bottom content-class="tooltip-arc-status">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="status(item, instalation.id)"
										:color="status(item, instalation.id).color"
										v-bind="attrs"
										v-on="on"
										small>{{ status(item, instalation.id).icon }}</v-icon>
									<div v-else>-</div>
								</template>
								<div v-if="status(item, instalation.id)">
									<p class="mb-0 caption font-weight-bold">{{ status(item, instalation.id).label }}</p>
									<p class="mb-0 caption">{{status(item, instalation.id).label == 'Entregado' ? 'Entregado':'Solicitado'}}: {{getPreProjectDocument(item, instalation.id).delivered_date ?? getPreProjectDocument(item, instalation.id).request_date | toDate}}</p>
								</div>
							</v-tooltip>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:item.sii="{ item }">
				<td class="pa-0">
					<div class="d-flex fill-height align-stretch">
						<div
							style="flex: 1;"
							v-for="(sii_i, i) in sii"
							:key="i"
							class="text-center subcolumn"
							:class="{
								'no-border': i === sii.length - 1
							}"
						>
							<v-tooltip bottom content-class="tooltip-arc-status">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="status(item, sii_i.id)"
										:color="status(item, sii_i.id).color"
										v-bind="attrs"
										v-on="on"
										small>{{ status(item, sii_i.id).icon }}</v-icon>
									<div v-else>-</div>
								</template>
								<div v-if="status(item, sii_i.id)">
									<p class="mb-0 caption font-weight-bold">{{ status(item, sii_i.id).label }}</p>
									<p class="mb-0 caption">{{status(item, sii_i.id).label == 'Entregado' ? 'Entregado':'Solicitado'}}: {{getPreProjectDocument(item, sii_i.id).delivered_date ?? getPreProjectDocument(item, sii_i.id).request_date | toDate}}</p>
								</div>
							</v-tooltip>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:item.sec="{ item }">
				<td class="pa-0">
					<div class="d-flex fill-height align-stretch">
						<div
							style="flex: 1;"
							v-for="(sec_i, i) in sec"
							:key="i"
							class="text-center subcolumn"
							:class="{
								'no-border': i === sec.length - 1
							}"
						>
							<v-tooltip bottom content-class="tooltip-arc-status">
								<template v-slot:activator="{ on, attrs }">
									<v-icon
										v-if="status(item, sec_i.id)"
										:color="status(item, sec_i.id).color"
										v-bind="attrs"
										v-on="on"
										small>{{ status(item, sec_i.id).icon }}</v-icon>
									<div v-else>-</div>
								</template>
								<div v-if="status(item, sec_i.id)">
									<p class="mb-0 caption font-weight-bold">{{ status(item, sec_i.id).label }}</p>
									<p class="mb-0 caption">{{status(item, sec_i.id).label == 'Entregado' ? 'Entregado':'Solicitado'}}: {{getPreProjectDocument(item, sec_i.id).delivered_date ?? getPreProjectDocument(item, sec_i.id).request_date | toDate}}</p>
								</div>
							</v-tooltip>
						</div>
					</div>
				</td>
			</template>
			<template v-slot:item.responsible="{ item }">
				<div>
					<b>{{item.responsible?.profile?.name ?? '--'}}</b>
					<v-menu offset-y z-index="9999" content-class="menu-bg-white" style="z-index: 9999; background-color: white;">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								dark
								small
								icon
								color="#524D4D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon dark small>mdi-swap-horizontal-bold</v-icon>
							</v-btn>
						</template>
						<v-list dense class="py-0" max-height="200" style="z-index: 9999;background-color: white;">
							<v-list-item dense class="mb-1" style="min-height: 25px; background: #959595;">
								<p class="caption mb-0 font-weight-bold white--text">Cambiar Responsable</p>
							</v-list-item>
							<v-list-item-group color="#524D4D">
								<v-list-item
									v-for="(responsible, index) in $store.state.users.usersList"
									:key="index"
									dense
									@click="changePreProjectResponsible(item, responsible.id)"
								>
									<v-list-item-title dense>
										{{ responsible.profile?.name }}
									</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-menu>
				</div>
			</template>
			<template v-slot:item.observation="{ item }">
				<div><b>{{item.description ?? '--'}}</b></div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="primary"
							@click="toPreProjectDetail(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Ver Detalle</span>
				</v-tooltip>
				<!-- <v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							class="mx-1"
							icon
							dark
							x-small
							color="#524D4D"
							@click="openBudgetModal(item)"
							v-bind="attrs"
							v-on="on"
						>
							<v-icon small dark>mdi-table-cog</v-icon>
						</v-btn>
					</template>
					<span>Gestionar Presupuesto</span>
				</v-tooltip> -->
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							icon
							dark
							x-small
							color="#524D4D"
							class="mx-1"
							v-bind="attrs"
							v-on="on"
							@click="restorePreproject(item.id)"
						>
							<v-icon small dark>mdi-archive-check-outline</v-icon>
						</v-btn>
					</template>
					<span>Restaurar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import moment from 'moment'
import {updatePreProjectInfo, setResponsible, restorePreProject} from '@/helpers/api/preprojects'
import {resizableGrid} from '@/helpers/resize-table'

export default {
	data() {
		return {
			search: '',
			searchCity: '',
			cityMenu: false,
			columnWidths: {},
			unarchive: [
				{ id: 1, loading: false, text: 'CIP', label: 'Certificado de Informes Previos' },
				{ id: 2, loading: false, text: 'CVS', label: 'Certificado de Vivienda Social' },
				{ id: 3, loading: false, text: 'CE', label: 'Certificado Expropiación' },
				{ id: 4, loading: false, text: 'CN', label: 'Certificado de Número' },
				{ id: 5, loading: false, text: 'CUS', label: 'Certificado Uso de Suelo' },
				{ id: 6, loading: false, text: 'PE', label: 'Permiso de Edificación' },
				{ id: 7, loading: false, text: 'RD', label: 'Recepción Definitiva' },
				{ id: 8, loading: false, text: 'RC', label: 'Resolución Copropiedad' },
				{ id: 9, loading: false, text: 'CPA', label: 'Copia Plano Arquitectura' },
				{ id: 10, loading: false, text: 'CPL', label: 'Copia Plano Estructura' },
				{ id: 11, loading: false, text: 'CPC', label: 'Copia Plano Copropiedad' },
				{ id: 12, loading: false, text: 'CPLO', label: 'Copia Plano Loteo' },
				{ id: 13, loading: false, text: 'CPSP', label: 'Copia Plano Subdivisión Predial' }
			],
			cbr: [
				{ id: 14, loading: false, text: 'PT', label: 'Copia Plano Terreno' },
				{ id: 15, loading: false, text: 'CPSP', label: 'Copia Plano Subdivisión Predial' },
				{ id: 16, loading: false, text: 'CDV', label: 'Certificado Dominio Vigente' }
			],
			sii: [
				{ id: 17, loading: false, text: 'CAFD', label: 'Certificado Avalúo Fiscal Detallado' }
			],
			instalations: [
				{ id: 18, loading: false, text: 'PA', label: 'Planos Agua Potable y Alcantarillado' }
			],
			sec: [
				{ id: 19, loading: false, text: 'CTPE', label: 'Copia TE1 y Planos Eléctricos' },
				{ id: 20, loading: false, text: 'CTOPG', label: 'Copia TC6 u Otros y Planos de Gas' }
			]
		};
	},
	computed: {
		cities(){
			return this.$store.state.general.countryCities.filter(city => city.name.toLowerCase().includes(this.searchCity.toLowerCase()))
		},
		headers(){
			let headers = [
				{ text: '#', value: 'id', width: '55px', align: 'center', sortable: false },
				{ text: 'Cliente', align: 'start', value: 'client', sortable: false },
				{ text: 'Dirección', align: 'start', value: 'address', sortable: false },
				{ text: 'Rol', align: 'start', value: 'rol', sortable: false },
				{ text: 'Proyecto', align: 'start', value: 'project_name', sortable: false },
				{ text: 'Municipalidad', align: 'start', value: 'entity', sortable: false },
				{ text: 'Desarchivo Municipal', value: 'unarchive', sortable: false },
				{ text: 'CBR', value: 'cbr', sortable: false },
				{ text: 'SII', value: 'sii', sortable: false },
				{ text: 'Sanitario', value: 'instalations', sortable: false },
				{ text: 'SEC', value: 'sec', sortable: false },
				{ text: 'Profesional Encargado', value: 'responsible', sortable: false },
				{ text: 'Observación', value: 'observation', sortable: false },
				{ text: 'Acciones', align: 'start', value: 'actions', sortable: false },
			]
			// return headers
			if(!this.$vuetify.breakpoint.mobile){
				const storedWidths = JSON.parse(localStorage.getItem('preProjectsColumnWidths')) || {};
				headers = headers.map((header) => {
					return {
						...header,
						width: storedWidths[header.value] || 'auto'
					}
				})
			}
			return headers.filter((header) => {
				return this.$store.state.preprojects.listingColumns[header.value]
			})
		},
		filteredUsers() {
			return this.$store.state.users.usersList.filter(user => {
				return (
					user.name.toLowerCase().includes(this.search.toLowerCase()) ||
					user.email.toLowerCase().includes(this.search.toLowerCase())
				);
			});
		},
		selectedCompany(){
			return this.$store.state.companies.selectedCompany
		},
		loadingPreProjects(){
			return this.$store.state.preprojects.loadingPreProjects
		}
	},
	watch: {
		selectedCompany(newValue){
			if(newValue){
				this.$store.dispatch('preprojects/getArchivedPreProjects')
				this.$store.dispatch('users/getUserFromCompany')
			}
		},
		loadingPreProjects(newValue){
			if(!newValue){
				this.$nextTick(() => {
					setTimeout(() => {
						resizableGrid(this.$refs.table.$el)
					}, 300);
				})
			}
		}
	},
	mounted(){
		if(this.selectedCompany){
			this.$store.dispatch('preprojects/getArchivedPreProjects')
			this.$store.dispatch('users/getUserFromCompany')
		}
		const savedWidths = JSON.parse(localStorage.getItem('preProjectsColumnWidths'));
		if (savedWidths) {
			this.columnWidths = savedWidths;
		}
	},
	methods: {
		openBudgetModal(preproject){
			const data = {
				status: true,
				item: {item_type: 'preproject', item_id: preproject.id},
				budget: preproject.budget
			}
			this.$store.commit('general/setNewBudgetModal', data)
		},
		status(preproject, documentTypeId){
			const base = [
				{ icon: 'mdi-check-circle-outline', label: 'Entregado', color: 'green' },
				{ icon: 'mdi-timer-sand', label: 'En Espera', color: 'orange' },
				{ icon: 'mdi-progress-alert', label: 'Atrasado', color: 'red' }
			]
			const found = preproject.documents.find((document) => {
				return document.document_type_id == documentTypeId
			})

			if (found) {
				if (found.delivered_date) {
					return base[0];
				}
				if (!found.max_date || ( found.max_date && moment().isBefore(found.max_date) ) ) {
					return base[1];
				}
				if (found.max_date && moment().isAfter(found.max_date)) {
					return base[2];
				}
			}
			return null;
		},
		getPreProjectDocument(preproject, documentTypeId){
			return preproject.documents.find((document) => {
				return document.document_type_id == documentTypeId
			})
		},
		getRandomArray(length){
			const base = [
				{ icon: 'mdi-check-circle-outline', label: 'Completado', color: 'green' },
				{ icon: 'mdi-timer-sand', label: 'En Espera', color: 'orange' },
				{ icon: 'mdi-progress-alert', label: 'Atrasado', color: 'red' }
			]
			let array = []
			for (let index = 0; index < length; index++) {
				array.push(base[Math.floor(Math.random() * 3)])
			}
			return array
		},
		toPreProjectsStart(crm){
			const preproject_info = {
				crm_id: crm.id,
				client_id: crm.user.user_id
			}
			localStorage.setItem('new_preproject_info', JSON.stringify(preproject_info));
			this.$store.commit('preprojects/setNewPreProjectModal', true)
		},
		saveColumnWidth(index) {
			const header_value = this.headers[index].value
			const headerCell = this.$refs.table.$el.querySelectorAll('.header-cell')[index];
			const newWidth = headerCell.offsetWidth;
			this.$set(this.columnWidths, header_value, newWidth);
			localStorage.setItem('preProjectsColumnWidths', JSON.stringify(this.columnWidths));
		},
		async restorePreproject(preproject_id){
			await restorePreProject({preproject_id})
			this.$store.dispatch('preprojects/getArchivedPreProjects')
		},
		addUser() {
			this.$store.commit('users/setNewUserModal', true)
		},
		toPreProjectDetail(preproject) {
			this.$router.push({ name: 'PreProject', params: { id: preproject.id } })
		},
		closeAllMenus() {
			Object.keys(this.$refs).forEach((refName) => {
				if (refName.startsWith("cityMenu-")) {
					const menu = this.$refs[refName];
					if (menu) {
						menu.isActive = false;
					}
				}
			});
		},
		async changePreProjectCity(item, district_id){
			const data = {
				preproject_id: item.id,
				district_id
			}
			await updatePreProjectInfo(data)
			this.$store.dispatch('preprojects/getArchivedPreProjects')
			this.searchCity = ''
      this.closeAllMenus()
		},
		async changePreProjectResponsible(item, responsible_id){
			const data = {
				preproject_id: item.id,
				responsible_id
			}
			await setResponsible(data)
			this.$store.dispatch('preprojects/getArchivedPreProjects')
		},
	},
};
</script>

<style>
	.crm-index .message-container{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-index .message-container p{
		margin-bottom: 0;
		font-size: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.crm-table table tr td{
		font-size: 11px!important;
	}
	.crm-table.resizable .v-data-table__wrapper th,
	.crm-table.resizable .v-data-table__wrapper td {
		border-right: 1px solid #cacaca;
	}
	.tooltip-arc-status{
    opacity: 1!important;
		background-color: white!important;
		border: 1px solid #524D4D;
		color: #524D4D;
	}
</style>
<style scoped>
	.menu-bg-white{background: white;}
	.subcolumn-header {
		text-align: center;
		border-top: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}
	.subcolumn {
		text-align: center;
		border-right: 1px solid #ccc;
		align-content: center;
	}
	.subcolumn-header.no-border {
		border-right: none;
	}
	.subcolumn.no-border {
		border-right: none;
	}
</style>